import React, { useState } from "react";
import CheckoutDialog from "./DialogBoxCheckout";
import DialogBoxCheckoutBank from "./DialogBoxCheckoutBank";
import { useTranslation } from "react-i18next";
import { Product } from "@/types/ProductType";
import { useReactToPrint } from "react-to-print";
import InvoiceThermalPrint from "@/componentsPos/InvocieThermalPrint";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/provider/authProvider";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

interface CheckoutPanelProps {
  totalAmount: number;
  onCheckout: (
    amountReceived: number,
    cashAccountId: number,
    paymentTermId?: number,
    exchangeRate?: number,
    selectedCurrencyDetails?: {
      id: number;
      name: string;
      symbol: string;
    },
    printInvoice?: () => void
  ) => void;
  onVoidInvoice: () => void;
  orderProducts: {
    product: Product;
    quantity: number;
    discount?: number;
    discountType?: "value" | "percentage";
  }[];
  customers: { id: string; name: string }[];
  selectedCustomer: string;

  calculateSubtotalAndDiscount: () => {
    subtotal: string;
    totalOrderDiscount: string;
    totalProductDiscounts: string;
    totalDiscountSum: string;
  };
  calculateVAT: () => string;
  calculateTotal: () => string;
  getVATRateName: (vatRateId: number) => string;
  getVATRate: (vatRateId: number) => number;
  openDialogOnCheckout: boolean;
  disabled?: boolean;
  employeeID: string | null;
  selectedPaymentMethod: number;
  onTotalDiscountChange: (
    discount: number,
    discountType: "value" | "percentage"
  ) => void;
  orderType: "salesOrder" | "purchaseOrder";
}

const CheckoutPanel: React.FC<CheckoutPanelProps> = ({
  totalAmount,
  onCheckout,
  onVoidInvoice,
  orderProducts,
  customers,
  selectedCustomer,
  calculateSubtotalAndDiscount,
  calculateVAT,
  calculateTotal,
  getVATRateName,
  getVATRate,
  openDialogOnCheckout,
  disabled = false,
  selectedPaymentMethod,
  onTotalDiscountChange,
  orderType,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { employeeId } = useAuth();
  const invoiceRef = React.useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const [isDiscountDialogOpen, setIsDiscountDialogOpen] = useState(false);
  const [discountValue, setDiscountValue] = useState(0);
  const [discountType, setDiscountType] = useState<"value" | "percentage">(
    "value"
  );
  const [exchangeRate, setExchangeRate] = useState<number>(1);

  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
  });

  const handleCheckout = () => {
    if (openDialogOnCheckout) {
      setOpen(true);
    } else {
      onCheckout(
        totalAmount,
        0,
        undefined,
        exchangeRate,
        undefined,
        handlePrint
      );
    }
  };

  const handleProduct = () => {
    navigate(t("/prodlistpath"));
  };

  const handleApplyDiscount = () => {
    onTotalDiscountChange(discountValue, discountType);
    setIsDiscountDialogOpen(false);
    setDiscountValue(0);
  };

  const handleSaveBank = async (
    cashAccountId: number,
    paymentTermId: number
  ) => {
    onCheckout(
      totalAmount,
      cashAccountId,
      paymentTermId,
      undefined,
      undefined,
      handlePrint
    );
  };
  return (
    <div className="checkout-panel">
      <button
        className="btn btn-checkout"
        onClick={handleCheckout}
        disabled={disabled}
      >
        {t("Checkout")}
      </button>
      {openDialogOnCheckout && (
        <>
          {selectedPaymentMethod === 3 ? (
            <DialogBoxCheckoutBank
              open={open}
              onClose={() => setOpen(false)}
              employeeID={employeeId}
              onSave={handleSaveBank}
              onPrint={handlePrint}
            />
          ) : (
            <CheckoutDialog
              open={open}
              onOpenChange={setOpen}
              totalAmount={totalAmount}
              onSubmit={(
                amountReceived,
                exchangeRate,
                selectedCurrencyDetails
              ) => {
                setExchangeRate(exchangeRate); // Set the exchange rate when dialog closes
                onCheckout(
                  amountReceived,
                  0,
                  undefined,
                  exchangeRate,
                  selectedCurrencyDetails,
                  handlePrint
                );
              }}
              orderProducts={orderProducts}
              customers={customers}
              selectedCustomer={selectedCustomer}
              calculateSubtotalAndDiscount={calculateSubtotalAndDiscount}
              calculateVAT={calculateVAT}
              calculateTotal={calculateTotal}
              getVATRateName={getVATRateName}
              getVATRate={getVATRate}
              onTotalDiscountChange={onTotalDiscountChange}
            />
          )}
        </>
      )}
      <button className="btn btn-lookup" onClick={handleProduct}>
        {t("ProductLookup")}
      </button>

      {orderType === "salesOrder" && (
        <button
          className="btn btn-discount"
          onClick={() => setIsDiscountDialogOpen(true)}
        >
          {t("Apply Discount")}
        </button>
      )}

      <Dialog
        open={isDiscountDialogOpen}
        onOpenChange={setIsDiscountDialogOpen}
      >
        <DialogContent>
          <DialogTitle>{t("Add Discount")}</DialogTitle>
          <DialogDescription>
            {t("Select discount type and value")}
          </DialogDescription>
          <div className="mb-4">
            <label className="mr-4">
              <input
                type="radio"
                value="value"
                checked={discountType === "value"}
                onChange={() => setDiscountType("value")}
              />
              {t("Value")}
            </label>
            <label>
              <input
                type="radio"
                value="percentage"
                checked={discountType === "percentage"}
                onChange={() => setDiscountType("percentage")}
              />
              {t("Percentage")}
            </label>
          </div>
          <Input
            type="number"
            placeholder={
              discountType === "value"
                ? t("Enter value")
                : t("Enter percentage")
            }
            value={discountValue}
            onChange={(e) => setDiscountValue(Number(e.target.value))}
            className="mb-4"
            autoFocus
          />
          <Button onClick={handleApplyDiscount} className="mr-2">
            {t("Apply")}
          </Button>
          <Button
            variant="ghost"
            onClick={() => setIsDiscountDialogOpen(false)}
          >
            {t("Cancel")}
          </Button>
        </DialogContent>
      </Dialog>

      <button className="btn btn-void" onClick={onVoidInvoice}>
        {t("VoidInvoice")}
      </button>
      <div style={{ display: "none" }}>
        <InvoiceThermalPrint
          ref={invoiceRef}
          orderProducts={orderProducts}
          customers={customers}
          selectedCustomer={selectedCustomer}
          calculateSubtotalAndDiscount={calculateSubtotalAndDiscount}
          calculateVAT={calculateVAT}
          calculateTotal={calculateTotal}
          getVATRateName={getVATRateName}
          getVATRate={getVATRate}
          orderNo={undefined}
          tableId={""}
          tablename={""}
          employeeName={""}
          selectedCurrencyDetails={null}
          totalInSelectedCurrency={""}
          exchangeRate={0}
          printerType={0}
        />
      </div>
    </div>
  );
};

export default CheckoutPanel;
