import React from "react";
import AuthProvider from "@/provider/authProvider";
import AppRoutes from "./routes";

const App: React.FC = () => {
    return (
        <AuthProvider>
            <AppRoutes /> {/* AuthProvider wraps AppRoutes */}
        </AuthProvider>
    );
};

export default App;
