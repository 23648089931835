import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import "@/css/MobileScreen.css";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { Currency } from "@/types/CurrencyType";
import CreateCurrencyDto from "@/models/CreateCurrencyDto";
import { CurrencyFormProps } from "@/interfaces/CurrencyInterface";
import "@/css/LoadingCreate.css";



const CurrencyForm: React.FC<CurrencyFormProps> = ({
  mode,
  inDialog = false,
  onClose,
}) => {
  const { t } = useTranslation();

  const formSchema = z.object({
    name: z.string().min(1, { message: t("Name is required") }),
    symbol: z.string().min(1, { message: t("Symbol is required") }),
  });
  
  type FormValues = z.infer<typeof formSchema>;
  const [loading, setLoading] = useState(false); // Initialize loading state



  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || { id: localStorage.getItem("currencyId") };
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      symbol: "",
    },
  });


  useEffect(() => {
    if (id) {
      localStorage.setItem("currencyId", id);
    }

    if (mode === "edit" && id) {
      const fetchCurrency = async () => {
        try {
          const response = await axios.get(
            `${API_URLS.BASE_URL}${API_URLS.Currency}/${id}`
          );
          methods.reset(response.data);
        } catch (error) {
          console.error("Error fetching currency:", error);
        }
      };

      fetchCurrency();
    }
  }, [id, mode, methods]);

  const updateLocalStorage = (updatedCurrency: Currency) => {
    const existingCurrency = JSON.parse(
      localStorage.getItem("currencyData") || "[]"
    );
    const updatedCurrencies = existingCurrency.map((currency: Currency) =>
      currency.id === updatedCurrency.id ? updatedCurrency : currency
    );
    localStorage.setItem("currencyData", JSON.stringify(updatedCurrencies));
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true); // Show loading spinner

    const currencyData = new CreateCurrencyDto({
        name: data.name,
        symbol: data.symbol,
    });

    try {
        let successMessage = "";

        if (mode === "create") {
            const response = await axios.post(
                `${API_URLS.BASE_URL}${API_URLS.Currency}`,
                currencyData
            );
            console.log("Currency created successfully", response.data);
            successMessage = t("Currency created successfully");

            const existingCurrency = JSON.parse(
                localStorage.getItem("currencyData") || "[]"
            );
            existingCurrency.push(response.data);
            localStorage.setItem("currencyData", JSON.stringify(existingCurrency));

        } else if (mode === "edit" && id) {
            const response = await axios.put(
                `${API_URLS.BASE_URL}${API_URLS.Currency}/${id}`,
                currencyData
            );
            console.log("Currency updated successfully:", response.data);
            successMessage = t("Currency updated successfully");

            updateLocalStorage(response.data);
        }

        methods.reset();
        localStorage.removeItem("currencyId");
        navigate(t("/currencylistpath"));

        // Show toast after navigation
        setTimeout(() => {
            if (successMessage) {
                toast.success(successMessage);
            }
        }, 250);

    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
  
        if (status === 403) {
          toast.error(t("You do not have permission to perform this action."));
        } else if (status === 500) {
          toast.error(t("A server error occurred. Please try again later."));
        } else if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorCurrency.${err.errorMessage}`) ||
              t("errorCurrency.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          toast.error(t("An error occurred"));
        }
      } else {
        toast.error(t("An unexpected error occurred"));
      }
      console.error("Error submitting currency:", error);
    }
    finally {
      setLoading(false); // Hide loading spinner
    }
  };


  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center">
      {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        <div className="w-full max-w-3xl p-6 rounded shadow-md">
          <div style={{ padding: "20px" }}>
            <h1>
              {mode === "create" ? t("/createcurrency") : t("/editcurrency")}
            </h1>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                }}
              >
                <FormItem>
                  <FormLabel>{t("name")}<span style={{ color: "red" }}>*</span></FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("name")}
                      type="search"
                      placeholder={t("putname")}
                    />
                  </FormControl>
                  {methods.formState.errors.name && (
                    <FormMessage>
                      {methods.formState.errors.name.message}
                    </FormMessage>
                  )}
                </FormItem>
                <FormItem>
                  <FormLabel>{t("symbol")}<span style={{ color: "red" }}>*</span></FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("symbol")}
                      type="search"
                      placeholder={t("putsumbol")}
                    />
                  </FormControl>
                  {methods.formState.errors.symbol && (
                    <FormMessage>
                      {methods.formState.errors.symbol.message}
                    </FormMessage>
                  )}
                </FormItem>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "50px",
                }}
              >
                <Button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                  {mode === "create" ? t("savelbtn") : t("savelbtn")}
                </Button>
                <Button
                  type="button"
                  onClick={() =>
                    inDialog ? onClose?.() : navigate(t("/currencylistpath"))
                  }
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                  {t("canclelbtn")}
                </Button>
              </div>
            </form>
          </div>
          <ToastContainer />
        </div>
      </div>
    </FormProvider>
  );
};

export default CurrencyForm;
