import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import {
  Select as CustomSelect,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"; // Custom select
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import "@/css/MobileScreen.css";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
// import CreateCashAccountDto from "@/models/CreateCashAccountDto";
import { CashAccountFormProps } from "@/interfaces/CashAccountInterfaces";
import { Currency } from "@/interfaces/CurrencyInterface";
import DataFetcher from "@/provider/DataFetcher";
import "@/css/LoadingCreate.css";
import { Employee } from "@/types/EmployeeType";
import Select from "react-select"; // react-select import

const CashAccountForm: React.FC<CashAccountFormProps> = ({ mode }) => {
  const { t } = useTranslation();

  const formSchema = z.object({
    accountType: z.enum(["bank", "cash"]),
    currencyId: z.string().optional().default(""),
    name: z.string().min(1, { message: t("Name is required") }),
    iban: z.string().optional().nullable(),
    swift: z.string().optional().nullable(),
    balance: z.number().optional(),
    employeeId: z
      .array(z.string())
      .min(1, { message: "At least one employee is required" }),
  });

  type FormValues = z.infer<typeof formSchema>;
  const [loading, setLoading] = useState(false);
const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {
    id: localStorage.getItem("cashaccountId"),
  };
  const [currency, setCurrency] = useState<Currency[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [accountType, setAccountType] = useState<"bank" | "cash">("bank");
  const [initialOwnersState, setInitialOwnersState] = useState<number[]>([]);

  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      accountType: "bank",
      currencyId: "",
      name: "",
      iban: "",
      swift: "",
      balance: 0,
      employeeId: [],
    },
  });

  const Currency_STORAGE_KEY = "currencyData";
  const EMPLOYEE_STORAGE_KEY = "employeeData";

  useEffect(() => {
    const fetchCurrency = async () => {
      try {
        const response = await DataFetcher.getData<Currency[]>(
          `${API_URLS.BASE_URL}${API_URLS.Currency}`,
          Currency_STORAGE_KEY
        );
        if (response) {
          setCurrency(response);
        }
      } catch (error) {
        console.error("Error fetching currency:", error);
      }
    };

    fetchCurrency();

    if (id) {
      localStorage.setItem("cashaccountId", id);
    }

    const fetchEmployee = async () => {
      try {
        const employeesResponse = await DataFetcher.getData<Employee[]>(
          `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}`,
          EMPLOYEE_STORAGE_KEY
        );
        if (employeesResponse && Array.isArray(employeesResponse)) {
          setEmployees(employeesResponse);
        }
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };
    fetchEmployee();

    const fetchCashAccount = async () => {
      if (mode === "edit" && id) {
        try {
          const response = await axios.get(
            `${API_URLS.BASE_URL}${API_URLS.CashAccount}/${id}`
          );
          const cashAccount = response.data;
          methods.reset(cashAccount);

          setAccountType(cashAccount.isBank ? "bank" : "cash");

          const initialOwners = cashAccount.owners.map((owner: Employee) =>
            owner.id.toString()
          );
          methods.setValue("employeeId", initialOwners);

          // Store the initial owners as an array of number ids for later comparison
          setInitialOwnersState(
            cashAccount.owners.map((owner: Employee) => owner.id)
          );
        } catch (error) {
          console.error("Error fetching CashAccount:", error);
        }
      }
    };

    fetchCashAccount();
  }, [id, mode, methods]);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true);

    const updatedOwners = data.employeeId.map((id) => parseInt(id)); // Current selected owners

    const iban = accountType === "bank" ? data.iban : null;
    const swift = accountType === "bank" ? data.swift : null;

    const cashAccountData = {
      currencyId: parseInt(data.currencyId),
      name: data.name,
      balance: data.balance,
      isBank: accountType === "bank",
      iban,
      swift,
    };

    try {
      if (mode === "create") {
        const createCashAccountData = {
          ...cashAccountData,
          ownersIds: updatedOwners,
        };

        const response = await axios.post(
          `${API_URLS.BASE_URL}${API_URLS.CashAccount}`,
          createCashAccountData
        );
        console.log(response);
        toast.success(t("CashAccount created successfully"));
      } else if (mode === "edit" && id) {
        // Compare initial owners with the updated owners
        const addOwnersIds = updatedOwners.filter(
          (ownerId) => !initialOwnersState.includes(ownerId)
        );
        const removeOwnerIds = initialOwnersState.filter(
          (ownerId) => !updatedOwners.includes(ownerId)
        );

        const editCashAccountData = {
          ...cashAccountData,
          addOwnersIds,
          removeOwnerIds,
        };

        const response = await axios.put(
          `${API_URLS.BASE_URL}${API_URLS.CashAccount}/${id}`,
          editCashAccountData
        );
        console.log(response);
        toast.success(t("CashAccount updated successfully"));
      }

      methods.reset();
      localStorage.removeItem("cashaccountId");
      navigate(t("/cashaccountlistpath"));
    } catch (error) {
      console.error("Error submitting CashAccount:", error);
      toast.error(t("An error occurred"));
    } finally {
      setLoading(false);
    }
  };
  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center">
        {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        <div className="w-full max-w-3xl p-6 rounded shadow-md">
          <div style={{ padding: "20px" }}>
            <h1>
              {mode === "create"
                ? t("createcashaccount")
                : t("editcashaccount")}
            </h1>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                }}
              >
                <FormItem>
                  <FormLabel>{t("AccountType")}</FormLabel>
                  <FormControl>
                    <Controller
                      name="accountType"
                      control={methods.control}
                      render={({ field }) => (
                        <div>
                          <label>
                            <input
                              type="radio"
                              value="bank"
                              checked={accountType === "bank"}
                              onChange={(e) => {
                                field.onChange(e);
                                setAccountType("bank");
                              }}
                            />
                            {t("bank")}
                          </label>
                          <label style={{ marginLeft: "20px" }}>
                            <input
                              type="radio"
                              value="cash"
                              checked={accountType === "cash"}
                              onChange={(e) => {
                                field.onChange(e);
                                setAccountType("cash");
                              }}
                            />
                            {t("cash")}
                          </label>
                        </div>
                      )}
                    />
                  </FormControl>
                </FormItem>

                <FormItem>
                  <FormLabel>{t("currencyName")}</FormLabel>
                  <FormControl>
                    <Controller
                      name="currencyId"
                      control={methods.control}
                      render={({ field }) => (
                        <CustomSelect
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder={t("selectCurrency")} />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectLabel>{t("currency")}</SelectLabel>
                              {currency.map((currency) => (
                                <SelectItem
                                  key={currency.id}
                                  value={currency.id.toString()}
                                >
                                  {currency.name}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </CustomSelect>
                      )}
                    />
                  </FormControl>
                  {methods.formState.errors.currencyId && (
                    <FormMessage>
                      {methods.formState.errors.currencyId.message}
                    </FormMessage>
                  )}
                </FormItem>
                <FormItem>
                  <FormLabel>{t("name")}<span style={{ color: "red" }}>*</span></FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("name")}
                      type="text"
                      placeholder={t("putname")}
                    />
                  </FormControl>
                  {methods.formState.errors.name && (
                    <FormMessage>
                      {methods.formState.errors.name.message}
                    </FormMessage>
                  )}
                </FormItem>
          <FormItem>
      <FormLabel>{t("employee")}<span style={{ color: "red" }}>*</span></FormLabel>
      <FormControl>
        <Controller
          name="employeeId"
          control={methods.control}
          render={({ field }) => (
            <Select
              isMulti
              options={employees.map((employee) => ({
                value: employee.id.toString(),
                label: employee.name,
              }))}
              onChange={(selectedOptions) =>
                field.onChange(
                  selectedOptions ? selectedOptions.map((option) => option.value) : []
                )
              }
              value={employees
                .filter((employee) =>
                  field.value.includes(employee.id.toString())
                )
                .map((employee) => ({
                  value: employee.id.toString(),
                  label: employee.name,
                }))}
              placeholder={t("selectEmployee")}
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: isDarkMode ? "#333" : "#fff",
                  color: isDarkMode ? "#fff" : "#000",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: isDarkMode ? "#fff" : "#000",
                }),
                multiValue: (provided) => ({
                  ...provided,
                  backgroundColor: isDarkMode ? "#555" : "#e5e5e5",
                  color: isDarkMode ? "#fff" : "#000",
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? isDarkMode
                      ? "#555"
                      : "#e5e5e5"
                    : isDarkMode
                    ? "#333"
                    : "#fff",
                  color: isDarkMode ? "#fff" : "#000",
                }),
                menu: (provided) => ({
                  ...provided,
                  backgroundColor: isDarkMode ? "#333" : "#fff",
                }),
              }}
            />
          )}
        />
      </FormControl>
      {methods.formState.errors.employeeId && (
        <FormMessage>
          {methods.formState.errors.employeeId.message}
        </FormMessage>
      )}
    </FormItem>

                {accountType === "bank" && (
                  <>
                    <FormItem>
                      <FormLabel>{t("IBAN")}</FormLabel>
                      <FormControl>
                        <Input
                          {...methods.register("iban")}
                          type="text"
                          placeholder={t("putiban")}
                        />
                      </FormControl>
                      {methods.formState.errors.iban && (
                        <FormMessage>
                          {methods.formState.errors.iban.message}
                        </FormMessage>
                      )}
                    </FormItem>

                    <FormItem>
                      <FormLabel>{t("SWIFT")}</FormLabel>
                      <FormControl>
                        <Input
                          {...methods.register("swift")}
                          type="text"
                          placeholder={t("putswift")}
                        />
                      </FormControl>
                      {methods.formState.errors.swift && (
                        <FormMessage>
                          {methods.formState.errors.swift.message}
                        </FormMessage>
                      )}
                    </FormItem>
                  </>
                )}

                <FormItem>
                  <FormLabel>{t("Balance")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("balance", {
                        valueAsNumber: true,
                      })}
                      type="number"
                      placeholder={t("putbalance")}
                    />
                  </FormControl>
                  {methods.formState.errors.balance && (
                    <FormMessage>
                      {methods.formState.errors.balance.message}
                    </FormMessage>
                  )}
                </FormItem>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "50px",
                }}
              >
                <Button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded"  >
                  {mode === "create" ? t("savelbtn") : t("savelbtn")}
                </Button>
                <Button
                  type="button"
                  onClick={() => navigate(t("/cashaccountlistpath"))}
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                  {t("canclelbtn")}
                </Button>
              </div>
            </form>
          </div>
          <ToastContainer />
        </div>
      </div>
    </FormProvider>
  );
};

export default CashAccountForm;
