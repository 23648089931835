import React, { useState, useEffect, useRef } from "react";
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import { useAuth } from "../provider/authProvider";
import { useNavigate } from "react-router-dom";
import API_URLS from "@/config";
import { useTranslation } from "react-i18next";
import "@/css/loading.css";
import DataFetcher from "@/provider/DataFetcher";
import axios from "axios"; // Add this if not already imported
import { toast, ToastContainer } from "react-toastify";
import { Button } from "@/components/ui/button";

interface CompanyData {
  id: number;
  name: string;
  tin: string;
  city: string;
  country: string;
  address:string;
  phoneNumber: string;
  email: string;
  fieldOfActivity: string;
  fiscalizationCertificate: string;
  isSeasonal: boolean;
  subscriptionStartDateUtc: string;
  subscriptionEndDateUtc: string;
  createDateUtc:string,
  superUser: {
    name: string;
    username: string;
    email: string;
    phoneNumber: string;

    // Add other fields as needed
  };
}


const COMPANY_DATA_STORAGE_KEY = "businessData";

const decodeJWT = (token: string) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join("")
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Failed to decode token", error);
    return null;
  }
};

const CompanyInfo: React.FC = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const [businessData, setBusinessData] = useState<CompanyData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false); // New state for edit mode
const fileInputRef = useRef<HTMLInputElement>(null);
  const [avatarSrc, setAvatarSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await DataFetcher.getData<CompanyData[]>(
          `${API_URLS.BASE_URL}${API_URLS.BUSINESSREGISTER}`,
          COMPANY_DATA_STORAGE_KEY,
          
        );

        if (response) {
          if (token) {
            const decodedToken = decodeJWT(token);
            if (decodedToken && decodedToken.tenantId) {
              const tenantId = Number(decodedToken.tenantId); // Ensure tenantId is a number
              const matchedCompany = response.find((company) => company.id === tenantId);

              if (matchedCompany) {
                setBusinessData(matchedCompany);
              } else {
                setError("No matching company data found");
              }
            } else {
              setError("Invalid token data");
            }
          } else {
            setError("Token is null or undefined");
          }
        } else {
          setError("Error fetching company data");
        }
      } catch (error) {
        console.error("Error during fetchCompanyData", error);
        setError("Error fetching company data");
      } finally {
        setLoading(false);
      }
    const savedAvatar = getAvatarFromLocalStorage();
      if (savedAvatar) {
        setAvatarSrc(savedAvatar);
      }
    };

    fetchCompanyData();


  }, [token, navigate]);


const getAvatarFromLocalStorage = (): string | null => {
    return localStorage.getItem("avatarSrc");
  };
const handleEdit = () => {
  if (isEditing && businessData) {
    // Save data to the API
    axios.put(`${API_URLS.BASE_URL}${API_URLS.BUSINESSREGISTER}/${businessData.id}`, businessData)
      .then(() => {
        console.log("Data saved successfully");

        // Save to localStorage after successful API call
        localStorage.setItem("businessData", JSON.stringify(businessData));

        const createBusiness = t("Company updated successfully");
        toast.success(createBusiness);
        setIsEditing(false); // Switch back to view mode
      })
      .catch(error => {
        console.error("Error saving data:", error);
      });
  } else {
    setIsEditing(true); // Switch to edit mode
  }
};

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (businessData) {
      setBusinessData({
        ...businessData,
        [e.target.name]: e.target.value,
      });
    }
  };
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      
      reader.onloadend = () => {
        if (reader.result) {
          const avatarDataUrl = reader.result as string;
          setAvatarSrc(avatarDataUrl);
          localStorage.setItem("avatarSrc", avatarDataUrl); // Save to localStorage
        }
      };
      
      reader.readAsDataURL(file);
    }
  };


  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!businessData) {
    return <div className="no-data">{t("No company data available")}</div>;
  }


  return (
<div className="p-4 overflow-x-auto">
  <div className="flex flex-col items-center space-y-4">
   <div className="w-24 h-24">
            <Avatar className="w-full h-full rounded-full" onClick={handleAvatarClick}>
              <AvatarImage src={avatarSrc || "https://github.com/shadcn.png"} alt="Company Logo" />
            </Avatar>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
    <h1 className="text-xl font-bold mb-6">
          {t("companyinfo")}
        </h1>
    <div className="w-full grid grid-cols-1 gap-4 md:grid-cols-2">
      
      <div className="col-span-1">
   <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700">
        {t("Company Name")}
    </label>
    {isEditing ? (
        <input
            type="text"
            name="name"
            value={businessData?.name || ''}
            onChange={handleChange}
           className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm ${
        isEditing ? 'edit-mode' : ''
      }`}
        />
    ) : (
        <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
            {businessData?.name}
        </p>
    )}
</div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            {t("tin")}
          </label>
          <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
            {businessData.tin}
          </p>
        </div>
        <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700">
        {t("city")}
    </label>
    {isEditing ? (
        <input
            type="text"
            name="city"
            value={businessData?.city || ''}
            onChange={handleChange}
className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm ${
        isEditing ? 'edit-mode' : ''
      }`}        />
    ) : (
        <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
            {businessData?.city}
        </p>
    )}
</div>
<div className="mb-4">
  <label className="block text-sm font-medium text-gray-700">
    {t("country")}
  </label>
  {isEditing ? (
    <input
      type="text"
      name="country"
      value={businessData?.country || ""}
      onChange={handleChange}
className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm ${
        isEditing ? 'edit-mode' : ''
      }`}    />
  ) : (
    <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
      {businessData?.country}
    </p>
  )}
</div>

        <div className="mb-4">
  <label className="block text-sm font-medium text-gray-700">
    {t("phoneNumber")}
  </label>
  {isEditing ? (
    <input
      type="text"
      name="phoneNumber"
      value={businessData?.phoneNumber || ""}
      onChange={handleChange}
className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm ${
        isEditing ? 'edit-mode' : ''
      }`}    />
  ) : (
    <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
      {businessData?.phoneNumber}
    </p>
  )}
</div>
        <div className="mb-4">
  <label className="block text-sm font-medium text-gray-700">
    {t("email")}
  </label>
  {isEditing ? (
    <input
      type="email"
      name="email"
      value={businessData?.email || ""}
      onChange={handleChange}
className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm ${
        isEditing ? 'edit-mode' : ''
      }`}    />
  ) : (
    <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
      {businessData?.email}
    </p>
  )}
</div>
      </div>
      <div className="col-span-1">
    <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700">
      {t("fieldOfActivity")}
    </label>
    {isEditing ? (
      <input
        type="text"
        name="fieldOfActivity"
        value={businessData?.fieldOfActivity || ""}
        onChange={handleChange}
        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
      />
    ) : (
      <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
        {businessData?.fieldOfActivity}
      </p>
    )}
  </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            {t("fiscalizationCertificate")}
          </label>
          <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
            {businessData.fiscalizationCertificate}
          </p>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            {t("isSeasonal")}
          </label>
          <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
            {businessData.isSeasonal ? "Yes" : "No"}
          </p>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            {t("subscriptionStartDateUtc")}
          </label>
          <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
            {new Date(businessData.subscriptionStartDateUtc).toLocaleString()}
          </p>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            {t("subscriptionEndDateUtc")}
          </label>
          <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
            {new Date(businessData.subscriptionEndDateUtc).toLocaleString()}
          </p>
        </div>
        <div className="mb-4">
  <label className="block text-sm font-medium text-gray-700">
    {t("address")}
  </label>
  {isEditing ? (
    <input
      type="text"
      name="address"
      value={businessData?.address || ""}
      onChange={handleChange}
className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm ${
        isEditing ? 'edit-mode' : ''
      }`}    />
  ) : (
    <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
      {businessData?.address}
    </p>
  )}
</div>
        
      </div>
      <div className="col-span-1 md:col-span-2">
        <h1 className="text-xl font-bold mb-6">
          {t("Admin Info")}
        </h1>
      </div>
      <div className="col-span-1">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            {t("Admin Name")}
          </label>
         <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
  {businessData?.superUser?.name || t("No data available")}
</p>

        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            {t("Admin Username")}
          </label>
          <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
            {businessData?.superUser?.username || t("No data available")} 
          </p>
        </div>
      </div>
      <div className="col-span-1">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            {t("Admin Email")}
          </label>
          <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
            {businessData?.superUser?.email|| t("No data available")}
          </p>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            {t("Admin Number")}
          </label>
          <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
            {businessData?.superUser?.phoneNumber || t("No data available")}
          </p>
        </div>


        
      </div>
      
    </div>
    
        <div className="flex justify-center">
  <Button onClick={handleEdit}
  className="bg-blue-500 text-white px-4 py-2 rounded">
    {isEditing ? t("savelbtn") : t("edit")}
  </Button>
</div>
  </div>
        <ToastContainer />

</div>



  );
};

export default CompanyInfo;
