import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import "@/css/MobileScreen.css";
import CreateUomDto from "@/models/CreateUomDto";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { Uom } from "@/types/UomType";
import { UomFormProps } from "@/interfaces/UomInterfaces";
import "@/css/LoadingCreate.css";

const UomForm: React.FC<UomFormProps> = ({
  mode,
  inDialog = false,
  onClose,
}) => {
  const { t } = useTranslation();

  const formSchema = z.object({
    name: z.string().min(1, { message: t("Name is required") }),
    description: z.string().optional(),
    code: z.string().optional(),
  });

  type FormValues = z.infer<typeof formSchema>;
  const [loading, setLoading] = useState(false); // Initialize loading state

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || { id: localStorage.getItem("uomId") };
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      description: "",
      code: "",
    },
  });

  useEffect(() => {
    if (id) {
      localStorage.setItem("uomId", id);
    }

    if (mode === "edit" && id) {
      const fetchUom = async () => {
        try {
          const response = await axios.get(
            `${API_URLS.BASE_URL}${API_URLS.UOM}/${id}`
          );
          methods.reset(response.data);
        } catch (error) {
          console.error("Error fetching UOM:", error);
        }
      };

      fetchUom();
    }
  }, [id, mode, methods]);

  const updateLocalStorage = (updatedUom: Uom) => {
    const existingUoms = JSON.parse(localStorage.getItem("uomData") || "[]");
    const updatedUoms = existingUoms.map((uom: Uom) =>
      uom.id === updatedUom.id ? updatedUom : uom
    );
    localStorage.setItem("uomData", JSON.stringify(updatedUoms));
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true); // Show loading spinner

    const uomData = new CreateUomDto({
      name: data.name,
      description: data.description,
      code: data.code,
    });
  
    try {
      let successMessage = "";
  
      if (mode === "create") {
        // Create a new UOM
        const response = await axios.post(
          `${API_URLS.BASE_URL}${API_URLS.UOM}`,
          uomData
        );
        console.log("UOM created successfully:", response.data);
        successMessage = t("UOM created successfully");
  
     // Retrieve existing UOMs from localStorage, ensure it's an array
      let existingUoms = JSON.parse(localStorage.getItem("uomData") || "[]");
      
      // Check if `existingUoms` is not an array (in case localStorage contains invalid data)
      if (!Array.isArray(existingUoms)) {
        existingUoms = [];
      }

      // Push the newly created UOM into the array and update localStorage
      existingUoms.push(response.data);
      localStorage.setItem("uomData", JSON.stringify(existingUoms));
  
        if (onClose) {
          onClose(response.data);
        }
      } else if (mode === "edit" && id) {
        // Update the existing UOM
        const response = await axios.put(
          `${API_URLS.BASE_URL}${API_URLS.UOM}/${id}`,
          uomData
        );
        console.log("UOM updated successfully:", response.data);
        successMessage = t("UOM updated successfully");
  
        updateLocalStorage(response.data);
  
        if (onClose) {
          onClose();
        }
      }
  
      // Reset the form and clear local storage
      methods.reset();
      localStorage.removeItem("uomId");
  
      if (!inDialog) {
        navigate(t("/uomlistpath"));
      }
  
      // Show toast after navigation
      setTimeout(() => {
        if (successMessage) {
          toast.success(successMessage);
        }
      }, 250);
  
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
  
        if (status === 403) {
          toast.error(t("You do not have permission to perform this action."));
        } else if (status === 500) {
          toast.error(t("A server error occurred. Please try again later."));
        } else if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorUom.${err.errorMessage}`) ||
              t("errorUom.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          toast.error(t("An error occurred"));
        }
      } else {
        toast.error(t("An unexpected error occurred"));
      }
      console.error("Error submitting UOM:", error);
    } 
    finally {
      setLoading(false); // Hide loading spinner
    }
  };
  

  const handleDialogSubmit = () => {
    methods.handleSubmit(onSubmit)();
  };

  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center">
      {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
                <div className="w-full max-w-3xl p-6 rounded shadow-md">
          <div style={{ padding: "20px" }}>
            <h1>{mode === "create" ? t("createuom") : t("edituom")}</h1>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleDialogSubmit();
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                }}
              >
                <FormItem>
                  <FormLabel>{t("name")}<span style={{ color: "red" }}>*</span></FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("name")}
                      type="search"
                      placeholder={t("putname")}
                    />
                  </FormControl>
                  {methods.formState.errors.name && (
                    <FormMessage>
                      {methods.formState.errors.name.message}
                    </FormMessage>
                  )}
                </FormItem>
                <FormItem>
                  <FormLabel>{t("code")}</FormLabel>
                  <div className="flex ">
                    <FormControl>
                      <Input
                        {...methods.register("code")}
                        type="search"
                        placeholder={t("putcode")}
                      />
                    </FormControl>
                    <a
                      href="https://financa.gov.al"
                      title="kodet"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <InformationCircleIcon className="h-9 w-9 pl-1" />
                    </a>
                  </div>
                </FormItem>
                <FormItem>
                  <FormLabel>{t("description")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("description")}
                      type="search"
                      placeholder={t("putdescription")}
                    />
                  </FormControl>
                </FormItem>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "50px",
                }}
              >
                <Button
                  type="button"
                  onClick={handleDialogSubmit}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  {mode === "create" ? t("savelbtn") : t("savelbtn")}
                </Button>
                <Button
                  type="button"
                  onClick={() =>
                    inDialog ? onClose?.() : navigate(t("/uomlistpath"))
                  }
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                  {t("canclelbtn")}
                </Button>
              </div>
            </form>
          </div>
          <ToastContainer />
        </div>
      </div>
    </FormProvider>
  );
};

export default UomForm;
