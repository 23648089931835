import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import "@/css/MobileScreen.css";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { Paymentmethods } from "@/types/PaymentMethdosType";
import CreatePaymentmethodsDto from "@/models/CreatePaymentmethodsDto";
import { Switch } from "@/components/ui/switch";
import { PaymentMethodsFormProps } from "@/interfaces/PaymentMethodsInterfaces";
import "@/css/LoadingCreate.css";



const PaymentMethodsForm: React.FC<PaymentMethodsFormProps> = ({
  mode,
  inDialog = false,
  onClose,
}) => {
  const { t } = useTranslation();

  const formSchema = z.object({
    name: z.string().min(1, { message: t("Name is required" )}),
    isCash: z.boolean(),
    code: z.string().optional(),
  });
  
  type FormValues = z.infer<typeof formSchema>;

  const [loading, setLoading] = useState(false); // Initialize loading state




  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {
    id: localStorage.getItem("paymentmethodsId"),
  };
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      code: "",
      isCash: false, // Set default to false
    },
  });


  useEffect(() => {
    if (id) {
      localStorage.setItem("paymentmethodsId", id);
    }

    if (mode === "edit" && id) {
      const fetchPaymentMethods = async () => {
        try {
          const response = await axios.get(
            `${API_URLS.BASE_URL}${API_URLS.PaymentMethod}/${id}`
          );
          methods.reset(response.data);
        } catch (error) {
          console.error("Error fetching payment methods:", error);
        }
      };

      fetchPaymentMethods();
    }
  }, [id, mode, methods]);

  const updateLocalStorage = (updatedpaymentmethods: Paymentmethods) => {
    const existingPaymentmethods = JSON.parse(
      localStorage.getItem("paymentmethodsData") || "[]"
    );
    const updatedPaymentmethods = existingPaymentmethods.map(
      (paymentmethods: Paymentmethods) =>
        paymentmethods.id === updatedpaymentmethods.id
          ? updatedpaymentmethods
          : paymentmethods
    );
    localStorage.setItem(
      "paymentmethodsData",
      JSON.stringify(updatedPaymentmethods)
    );
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true); // Show loading spinner

    data.isCash = data.isCash ?? false;
  
    const PaymentMethodData = new CreatePaymentmethodsDto({
      name: data.name,
      code: data.code,
      isCash: data.isCash,
    });
  
    try {
      let successMessage = "";
  
      if (mode === "create") {
        const response = await axios.post(
          `${API_URLS.BASE_URL}${API_URLS.PaymentMethod}`,
          PaymentMethodData
        );
        console.log("Payment method created successfully:", response.data);
        successMessage = t("Payment method created successfully");
  
        const existingPaymentmethods = JSON.parse(
          localStorage.getItem("paymentmethodsData") || "[]"
        );
        existingPaymentmethods.push(response.data);
        localStorage.setItem(
          "paymentmethodsData",
          JSON.stringify(existingPaymentmethods)
        );
  
        if (onClose) {
          onClose(response.data);
        }
      } else if (mode === "edit" && id) {
        const response = await axios.put(
          `${API_URLS.BASE_URL}${API_URLS.PaymentMethod}/${id}`,
          PaymentMethodData
        );
        console.log("Payment method updated successfully:", response.data);
        successMessage = t("Payment method updated successfully");
  
        updateLocalStorage(response.data);
      }
  
      methods.reset();
      localStorage.removeItem("paymentmethodsId");
  
      if (onClose) {
        onClose();
      }
  
      if (!inDialog) {
        navigate(t("/paymentlistpath"));
      }
  
      // Show toast after navigation
      setTimeout(() => {
        if (successMessage) {
          toast.success(successMessage);
        }
      }, 250);
  
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
  
        if (status === 403) {
          toast.error(t("You do not have permission to perform this action."));
        } else if (status === 500) {
          toast.error(t("A server error occurred. Please try again later."));
        } else if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorPaymentMethod.${err.errorMessage}`) ||
              t("errorPaymentMethod.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          toast.error(t("An error occurred"));
        }
      } else {
        toast.error(t("An unexpected error occurred"));
      }
      console.error("Error submitting payment methods:", error);
    } 
    finally {
      setLoading(false); // Hide loading spinner
    }
  };
  


  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center">
      {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        <div className="w-full max-w-3xl p-6 rounded shadow-md">
          <div style={{ padding: "20px" }}>
            <h1>
              {mode === "create"
                ? t("/createpaymentmethod")
                : t("/editpaymentmethod")}
            </h1>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                }}
              >
                <FormItem>
                  <FormLabel>{t("name")}<span style={{ color: "red" }}>*</span></FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("name")}
                      type="search"
                      placeholder={t("putname")}
                    />
                  </FormControl>
                  {methods.formState.errors.name && (
                    <FormMessage>
                      {methods.formState.errors.name.message}
                    </FormMessage>
                  )}
                </FormItem>
                <FormItem>
                  <FormLabel>{t("code")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("code")}
                      type="search"
                      placeholder={t("putcode")}
                    />
                  </FormControl>
                  {methods.formState.errors.code && (
                    <FormMessage>
                      {methods.formState.errors.code.message}
                    </FormMessage>
                  )}
                </FormItem>
                <FormItem>
                  <FormLabel>{t("isCash")}</FormLabel>
                  <FormControl>
                    <Controller
                      name="isCash"
                      control={methods.control}
                      render={({ field }) => (
                        <Switch
                          checked={field.value}
                          onCheckedChange={(value) => field.onChange(value)}
                        />
                      )}
                    />
                  </FormControl>
                </FormItem>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "50px",
                }}
              >
                <Button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                  {mode === "create" ? t("savelbtn") : t("savelbtn")}
                </Button>
                <Button
                  type="button"
                  onClick={() =>
                    inDialog ? onClose?.() : navigate(t("/paymentlistpath"))
                  }
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                  {t("canclelbtn")}
                </Button>
              </div>
            </form>
          </div>
          <ToastContainer />
        </div>
      </div>
    </FormProvider>
  );
};

export default PaymentMethodsForm;
