import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import CreateProductCategoryDto from "@/models/CreateCategoryProductDto";
import { useNavigate, useLocation } from "react-router-dom";
import "@/css/MobileScreen.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { ProductCategory } from "@/types/ProductCategoryType";
import { ProductCategoryFormProps } from "@/interfaces/ProductCategoryInterfaces";
import DataFetcher from "@/provider/DataFetcher";
import "@/css/LoadingCreate.css";


const ProductCategoryForm: React.FC<ProductCategoryFormProps> = ({
  mode,
  inDialog = false,
  onClose,
}) => {

  const { t } = useTranslation();

  const formSchema = z.object({
    categoryName: z.string().min(1, { message: t("Category Name is required") }),
    parentCategory: z.string().optional(),
    description: z.string().optional(),
  });
  
  type FormValues = {
    categoryName: string;
    parentCategory: string;
    description?: string;
    image?: string;
  };

  
  const location = useLocation();
  const [loading, setLoading] = useState(false); // Initialize loading state

  const { id } = location.state || {
    id: localStorage.getItem("productCategoryId"),
  };
  const navigate = useNavigate();
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      categoryName: "",
      parentCategory: "",
      description: "",
      image: "",
    },
  });
  const PRODUCTCAT_STORAGE_KEY = "categoryData";

  const [parentCategories, setParentCategories] = useState<
    { id: number; name: string }[]
  >([]);

  useEffect(() => {
    const fetchParentCategories = async () => {
      try {
        const response = await DataFetcher.getData<
          { id: number; name: string }[]
        >(
          `${API_URLS.BASE_URL}${API_URLS.PRODUCT_CATEGORY}`,
          PRODUCTCAT_STORAGE_KEY
        );
        if (response) {
          setParentCategories([{ id: 0, name: "---" }, ...response]);
        }
      } catch (error) {
        console.error("Error fetching parent categories:", error);
      }
    };

    fetchParentCategories();

    if (id) {
      localStorage.setItem("productCategoryId", id);
    }

    if (mode === "edit" && id) {
      const fetchCategory = async () => {
        try {
          const response = await DataFetcher.getData<ProductCategory | null>(
            `${API_URLS.BASE_URL}${API_URLS.PRODUCT_CATEGORY}/${id}`,
            PRODUCTCAT_STORAGE_KEY
          );
          if (response) {
            methods.reset({
              categoryName: response.name,
              parentCategory: response.parentCategoryId
                ? response.parentCategoryId.toString()
                : "",
              description: response.description,
              image: response.imageUrl,
            });
          } else {
            console.error("Category data is null.");
            toast.error("Failed to fetch category details");
          }
        } catch (error) {
          console.error("Error fetching category details:", error);
          toast.error("Failed to fetch category details");
        }
      };

      fetchCategory();
    }
  }, [id, mode, methods]);

  const updateLocalStorage = (updatedCategory: ProductCategory) => {
    const existingCategories = JSON.parse(
      localStorage.getItem("productCatData") || "[]"
    );
    const updatedCategories = existingCategories.map(
      (category: ProductCategory) =>
        category.id === updatedCategory.id ? updatedCategory : category
    );
    localStorage.setItem("productCatData", JSON.stringify(updatedCategories));
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true); // Show loading spinner

    try {
      // Determine the parent category ID
      const selectedCategory = parentCategories.find(
        (category) => category.name === data.parentCategory
      );
      const parentCategoryId =
        selectedCategory?.name === "---" ? null : selectedCategory?.id;
  
      // Create the category data object
      const categoryData = new CreateProductCategoryDto({
        parentCategoryId: parentCategoryId,
        name: data.categoryName,
        description: data.description,
        image: data.image,
      });
  
      let successMessage = "";
  
      if (mode === "create") {
        // Create a new product category
        const response = await axios.post(
          `${API_URLS.BASE_URL}${API_URLS.PRODUCT_CATEGORY}`,
          categoryData
        );
        console.log("ProductCategory created successfully:", response.data);
        successMessage = t("Category created successfully");
  
        // Update local storage with the new category
        const existingProductCategory = JSON.parse(
          localStorage.getItem("productCatData") || "[]"
        );
        existingProductCategory.push(response.data);
        localStorage.setItem(
          "productCatData",
          JSON.stringify(existingProductCategory)
        );
  
        if (onClose) {
          onClose(response.data);
        }
      } else if (mode === "edit" && id) {
        // Update the existing product category
        const response = await axios.put(
          `${API_URLS.BASE_URL}${API_URLS.PRODUCT_CATEGORY}/${id}`,
          categoryData
        );
        console.log("ProductCategory updated successfully:", response.data);
        successMessage = t("Category updated successfully");
  
        updateLocalStorage(response.data);
  
        if (onClose) {
          onClose();
        }
      }
  
      // Reset the form and clear local storage
      methods.reset();
      localStorage.removeItem("productCategoryId");
  
      if (!inDialog) {
        navigate(t("/categorylsitproduct"));
      }
  
      // Show toast after navigation
      setTimeout(() => {
        if (successMessage) {
          toast.success(successMessage);
        }
      }, 250);
  
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
  
        if (status === 403) {
          toast.error(t("You do not have permission to perform this action."));
        } else if (status === 500) {
          toast.error(t("A server error occurred. Please try again later."));
        } else if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorProductCategory.${err.errorMessage}`) ||
              t("errorProductCategory.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          toast.error(t("An error occurred"));
        }
      } else {
        toast.error(t("An unexpected error occurred"));
      }
      console.error("Error submitting Product Category:", error);
    } 
    finally {
      setLoading(false); // Hide loading spinner
    }
  };
  

  const handleDialogSubmit = () => {
    methods.handleSubmit(onSubmit)();
  };

  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center">
      {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        <div className="w-full max-w-lg p-6 rounded shadow-md">
          <h1 className="text-xl font-bold mb-6">
            {mode === "create"
              ? t("createproductcategory")
              : t("editproductcategory")}
          </h1>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleDialogSubmit();
            }}
          >
            <div className="space-y-4">
              <FormItem>
                <FormLabel>{t("categoryname")}<span style={{ color: "red" }}>*</span></FormLabel>
                <FormControl>
                  <Input
                    {...methods.register("categoryName")}
                    type="search"
                    placeholder={t("putcategoryname")}
                    className="w-full"
                  />
                </FormControl>
                {methods.formState.errors.categoryName && (
                  <FormMessage>
                    {methods.formState.errors.categoryName.message}
                  </FormMessage>
                )}
              </FormItem>
              <FormItem>
                <FormLabel>{t("parentcategory")}</FormLabel>
                <FormControl>
                  <Controller
                    name="parentCategory"
                    control={methods.control}
                    render={({ field }) => (
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder={t("putparentcategory")} />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>{t("parentcategory")}</SelectLabel>
                            {parentCategories.map((category) => (
                              <SelectItem
                                key={category.id}
                                value={category.name}
                              >
                                {category.name}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    )}
                  />
                </FormControl>
                {methods.formState.errors.parentCategory && (
                  <FormMessage>
                    {methods.formState.errors.parentCategory.message}
                  </FormMessage>
                )}
              </FormItem>
              <FormItem>
                <FormLabel>{t("description")}</FormLabel>
                <FormControl>
                  <Input
                    {...methods.register("description")}
                    type="search"
                    placeholder={t("putdescription")}
                    className="w-full"
                  />
                </FormControl>
              </FormItem>
              <FormItem>
                <FormLabel> {t("image")}</FormLabel>
                <FormControl>
                  <Input
                    {...methods.register("image")}
                    type="file"
                    accept="image/*"
                    className="w-full"
                  />
                </FormControl>
              </FormItem>
            </div>
            <div className="flex gap-4 mt-6">
              <Button
                type="button"
                onClick={handleDialogSubmit}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                {t("savelbtn")}
              </Button>
              <Button
                type="button"
                onClick={() =>
                  inDialog ? onClose?.() : navigate(t("/categorylsitproduct"))
                }
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                {t("canclelbtn")}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </FormProvider>
  );
};

export default ProductCategoryForm;
