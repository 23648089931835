import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider, SubmitHandler, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
    FormControl,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import "@/css/MobileScreen.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import CreateBusinessDto from "@/models/CreateBusinessRegisterDto";
import Logo from "/assets/logo.png"
const baseFormSchema = z.object({
    name: z.string().min(1, { message: "Name is required" }),
    tin: z.string().min(1, { message: "TIN is required" }),
    city: z.string().min(1, { message: "City is required" }),
    country: z.string().min(1, { message: "Country is required" }),
    phoneNumber: z.string().min(1, { message: "Phone number is required" }),
    email: z.string().email({ message: "Invalid email address" }),
    fieldOfActivity: z.string().min(1, { message: "Field of activity is required" }),
    fiscalizationCertificate: z.string().min(1, { message: "Fiscalization certificate is required" }),
    isSeasonal: z.boolean().optional(),
    subscriptionStartDateUtc: z.string().min(1, { message: "Subscription start date is required" }),
    subscriptionEndDateUtc: z.string().min(1, { message: "Subscription end date is required" }),
    user_Name: z.string().min(1, { message: "Name is required" }),
    user_Username: z.string().min(1, { message: "Username is required" }),
    user_Email: z.string().min(1, { message: "Email is required" }),
    user_PhoneNumber: z.string().min(1, { message: "PhoneNumber is required" }),
    user_Password: z.string().min(1, { message: "Password is required" }),
    image: z.string().min(1, { message: "Name is required" }),
    address: z.string().min(1, { message: "Address is required" }),
    baseCurrency: z.number().min(1, { message: "Currency is required" }),
    printerType: z.number().min(1, { message: "Print  is required" }),
    allowNegativeInventory: z.boolean().optional(),



});

const formSchema = baseFormSchema;

type FormValues = z.infer<typeof formSchema>;

interface BusinessRegisterFormProps {
    mode: "create" | "edit";
}

const BusinessRegisterForm: React.FC<BusinessRegisterFormProps> = ({ mode }) => {
    const location = useLocation();
    const { id } = location.state || { id: localStorage.getItem("businessId") };

    const methods = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: "",
            tin: "",
            address: "",
            city: "",
            country: "",
            phoneNumber: "",
            email: "",
            fieldOfActivity: "",
            fiscalizationCertificate: "",
            isSeasonal: false,
            allowNegativeInventory: false,
            printerType: 0,
            baseCurrency: 0,

            subscriptionStartDateUtc: "",
            subscriptionEndDateUtc: "",
            user_Name: "",
            user_Username: "",
            user_PhoneNumber: "",
            user_Email: "",
            user_Password: "",
            image: ""
        },
    });
    const { t } = useTranslation();

    // State to manage form steps
    const [step, setStep] = useState(1);

    useEffect(() => {
        if (id) {
            localStorage.setItem("businessId", id);
        }


    }, [id, mode, methods]);

    // const updateLocalStorage = (updatedBusiness: CompanyData) => {
    //     // Retrieve existing business data from localStorage
    //     const existingBusinesses = JSON.parse(localStorage.getItem("businessData") || "[]");

    //     // Ensure existingBusinesses is an array
    //     const businessesArray = Array.isArray(existingBusinesses)
    //         ? existingBusinesses
    //         : [];

    //     // Update the specific business or keep the existing ones
    //     const updatedBusinesses = businessesArray.map((business: CompanyData) =>
    //         business.id === updatedBusiness.id ? updatedBusiness : business
    //     );

    //     // Save the updated array back to localStorage
    //     localStorage.setItem("businessData", JSON.stringify(updatedBusinesses));
    // };

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        console.log("Form data submitted:", data); // Log the submitted data

        try {
            const businessData = new CreateBusinessDto(data);
            const response = await axios.post(`${API_URLS.BASE_URL}${API_URLS.BUSINESSREGISTER}`, businessData);
            toast.success(t("Business created successfully"));

            // Update local storage with the new business
            const existingBusinesses = JSON.parse(localStorage.getItem("businessData") || "[]").data;
            existingBusinesses.push(response.data);
            localStorage.setItem("businessData", JSON.stringify(existingBusinesses));
            // updateLocalStorage(response.data);

            // Reset form and remove businessId from localStorage
            console.log("Resetting form");
            methods.reset(); // Explicitly log if this line is reached
            localStorage.removeItem("businessId");
        } catch (error) {
            console.error("Error submitting business:", error); // Log error details

            if (axios.isAxiosError(error) && error.response) {
                const { data } = error.response;
                if (data && Array.isArray(data)) {
                    data.forEach((err: { errorMessage: string }) => {
                        toast.error(t(`errorBusiness.${err.errorMessage}`) || t("errorBusiness.An error occurred"));
                    });
                } else {
                    toast.error(t("An error occurred"));
                }
            } else {
                toast.error(t("An unexpected error occurred"));
            }
        }
    };



    // Function to move to the next step
    const handleNext = () => {
        setStep((prevStep) => prevStep + 1);
    };

    // Function to move to the previous step
    const handlePrevious = () => {
        setStep((prevStep) => prevStep - 1);
    };

    return (
        <FormProvider {...methods}>
            <div className="relative"> {/* Wrapper to position the image */}
                <img
                    src={Logo}
                    alt="Logo"
                    className="absolute top-0 left-0 w-26 h-26" // Adjust size and positioning as needed
                />
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <h1 className="text-xl font-bold mb-6 text-center text-white pt-2.5">
                        {t("createbusiness")}
                    </h1>


                    <div className="grid grid-cols">
                        {step === 1 && (
                            <fieldset className="w-full grid grid-cols-1 gap-4 md:grid-cols-2 pt-10">
                                {/* Step 1 Fields */}
                                <FormItem>
                                    <FormLabel className="text-white">{t("name")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...methods.register("name")}
                                            type="search"
                                            placeholder={t("putname")}
                                            className="w-full"
                                        />
                                    </FormControl>
                                    {methods.formState.errors.name && (
                                        <FormMessage>{methods.formState.errors.name.message}</FormMessage>
                                    )}
                                </FormItem>
                                <FormItem>
                                    <FormLabel className="text-white">{t("tin")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...methods.register("tin")}
                                            type="search"
                                            placeholder={t("puttin")}
                                            className="w-full"
                                        />
                                    </FormControl>
                                    {methods.formState.errors.tin && (
                                        <FormMessage>{methods.formState.errors.tin.message}</FormMessage>
                                    )}
                                </FormItem>
                                <FormItem>
                                    <FormLabel className="text-white">{t("city")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...methods.register("city")}
                                            type="search"
                                            placeholder={t("putcity")}
                                            className="w-full"
                                        />
                                    </FormControl>
                                    {methods.formState.errors.city && (
                                        <FormMessage>{methods.formState.errors.city.message}</FormMessage>
                                    )}
                                </FormItem>
                                <FormItem>
                                    <FormLabel className="text-white">{t("country")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...methods.register("country")}
                                            type="search"
                                            placeholder={t("putcountry")}
                                            className="w-full"
                                        />
                                    </FormControl>
                                    {methods.formState.errors.country && (
                                        <FormMessage>{methods.formState.errors.country.message}</FormMessage>
                                    )}
                                </FormItem>
                                <FormItem>
                                    <FormLabel className="text-white">{t("phoneNumber")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...methods.register("phoneNumber")}
                                            type="search"
                                            placeholder={t("putphoneNumber")}
                                            className="w-full"
                                        />
                                    </FormControl>
                                    {methods.formState.errors.phoneNumber && (
                                        <FormMessage>{methods.formState.errors.phoneNumber.message}</FormMessage>
                                    )}
                                </FormItem>
                                <FormItem>
                                    <FormLabel className="text-white">{t("address")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...methods.register("address")}
                                            type="search"
                                            placeholder={t("address")}
                                            className="w-full"
                                        />
                                    </FormControl>
                                    {methods.formState.errors.phoneNumber && (
                                        <FormMessage>{methods.formState.errors.phoneNumber.message}</FormMessage>
                                    )}
                                </FormItem>
                                <FormItem>
                                    <FormLabel className="text-white">{t("email")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...methods.register("email")}
                                            type="search"
                                            placeholder={t("putemail")}
                                            className="w-full"
                                        />
                                    </FormControl>
                                    {methods.formState.errors.email && (
                                        <FormMessage>{methods.formState.errors.email.message}</FormMessage>
                                    )}
                                </FormItem>
                                <FormItem>
                                    <FormLabel className="text-white">{t("fieldOfActivity")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...methods.register("fieldOfActivity")}
                                            type="search"
                                            placeholder={t("fieldOfActivity")}
                                            className="w-full"
                                        />
                                    </FormControl>
                                    {methods.formState.errors.fieldOfActivity && (
                                        <FormMessage>{methods.formState.errors.fieldOfActivity.message}</FormMessage>
                                    )}
                                </FormItem>
                                <FormItem>
                                    <FormLabel className="text-white">{t("fiscalizationCertificate")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...methods.register("fiscalizationCertificate")}
                                            type="search"
                                            placeholder={t("fiscalizationCertificate")}
                                            className="w-full"
                                        />
                                    </FormControl>
                                    {methods.formState.errors.fiscalizationCertificate && (
                                        <FormMessage>{methods.formState.errors.fiscalizationCertificate.message}</FormMessage>
                                    )}
                                </FormItem>

                                <FormItem>
                                    <FormLabel className="text-white">{t("subscriptionStartDateUtc")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...methods.register("subscriptionStartDateUtc")}
                                            type="date"
                                            placeholder={t("putSubscriptionStartDateUtc")}
                                            className="w-full"
                                        />
                                    </FormControl>
                                    {methods.formState.errors.subscriptionStartDateUtc && (
                                        <FormMessage>{methods.formState.errors.subscriptionStartDateUtc.message}</FormMessage>
                                    )}
                                </FormItem>
                                <FormItem>
                                    <FormLabel className="text-white">{t("subscriptionEndDateUtc")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...methods.register("subscriptionEndDateUtc")}
                                            type="date"
                                            placeholder={t("putSubscriptionEndDateUtc")}
                                            className="w-full"
                                        />
                                    </FormControl>
                                    {methods.formState.errors.subscriptionEndDateUtc && (
                                        <FormMessage>{methods.formState.errors.subscriptionEndDateUtc.message}</FormMessage>
                                    )}
                                </FormItem>
                                <FormItem>
                                    <FormLabel className="text-white">{t("image")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...methods.register("image")}
                                            type="search"
                                            placeholder={t("putemail")}
                                            className="w-full"
                                        />
                                    </FormControl>
                                    {methods.formState.errors.image && (
                                        <FormMessage>{methods.formState.errors.image.message}</FormMessage>
                                    )}
                                </FormItem>
                                <FormItem>
                                    <FormLabel className="text-white">{t("isSeasonal")}</FormLabel>
                                    <FormControl>
                                        <input
                                            {...methods.register("isSeasonal")}
                                            type="checkbox"
                                            className="w-6 h-6 transform scale-150 ml-4" // ml-2 for left margin
                                        />
                                    </FormControl>
                                    {methods.formState.errors.isSeasonal && (
                                        <FormMessage>{methods.formState.errors.isSeasonal.message}</FormMessage>
                                    )}
                                </FormItem>


                            </fieldset>
                        )}


                        {step === 2 && (
                            <fieldset className="w-full grid grid-cols-1 gap-4 md:grid-cols-2 pt-10">

                                <FormItem>
                                    <FormLabel className="text-white">{t("Admin Name")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...methods.register("user_Name")}
                                            type="search"
                                            placeholder={t("putname")}
                                            className="w-full"
                                        />
                                    </FormControl>
                                    {methods.formState.errors.name && (
                                        <FormMessage>{methods.formState.errors.name.message}</FormMessage>
                                    )}
                                </FormItem>
                                <FormItem>
                                    <FormLabel className="text-white">{t("Admin Username")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...methods.register("user_Username")}
                                            type="search"
                                            placeholder={t("putname")}
                                            className="w-full"
                                        />
                                    </FormControl>
                                    {methods.formState.errors.name && (
                                        <FormMessage>{methods.formState.errors.name.message}</FormMessage>
                                    )}
                                </FormItem>
                                <FormItem>
                                    <FormLabel className="text-white">{t("Admin Email")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...methods.register("user_Email")}
                                            type="search"
                                            placeholder={t("putemail")}
                                            className="w-full"
                                        />
                                    </FormControl>
                                    {methods.formState.errors.name && (
                                        <FormMessage>{methods.formState.errors.name.message}</FormMessage>
                                    )}
                                </FormItem>
                                <FormItem>
                                    <FormLabel className="text-white">{t("Admin Number")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...methods.register("user_PhoneNumber")}
                                            type="search"
                                            placeholder={t("putphoneNumber")}
                                            className="w-full"
                                        />
                                    </FormControl>
                                    {methods.formState.errors.name && (
                                        <FormMessage>{methods.formState.errors.name.message}</FormMessage>
                                    )}
                                </FormItem>
                                <FormItem>
                                    <FormLabel className="text-white">{t("Admin Password")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...methods.register("user_Password")}
                                            type="search"
                                            placeholder={t("putpassword")}
                                            className="w-full"
                                        />
                                    </FormControl>
                                    {methods.formState.errors.name && (
                                        <FormMessage>{methods.formState.errors.name.message}</FormMessage>
                                    )}
                                </FormItem>
                                <FormItem>
                                    <FormLabel className="text-white">{t("allowNegativeInventory")}</FormLabel>
                                    <FormControl>
                                        <input
                                            {...methods.register("allowNegativeInventory")}
                                            type="checkbox"
                                            className="w-6 h-6 transform scale-150 ml-4" // ml-2 for left margin
                                        />
                                    </FormControl>
                                    {methods.formState.errors.allowNegativeInventory && (
                                        <FormMessage>{methods.formState.errors.allowNegativeInventory.message}</FormMessage>
                                    )}
                                </FormItem>
                                <FormItem>
                                    <FormLabel className="text-white">
                                        {t("printerType")}
                                    </FormLabel>
                                    <FormControl>
                                        <Controller
                                            name="printerType"
                                            control={methods.control}
                                            render={({ field }) => (
                                                <Select
                                                    onValueChange={(value) => field.onChange(Number(value))} // Convert the value to a number
                                                    value={field.value?.toString() || ""} // Convert the value to a string, defaulting to an empty string
                                                >
                                                    <SelectTrigger>
                                                        <SelectValue placeholder={t("Select printer type")} />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectItem value="1">{t("A4")}</SelectItem>
                                                        <SelectItem value="2">{t("80MM")}</SelectItem>
                                                    </SelectContent>
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                    {methods.formState.errors.printerType && (
                                        <FormMessage>{methods.formState.errors.printerType.message}</FormMessage>
                                    )}
                                </FormItem>

                                <FormItem>
                                    <FormLabel className="text-white">
                                        {t("baseCurrency")}
                                    </FormLabel>
                                    <FormControl>
                                        <Controller
                                            name="baseCurrency"
                                            control={methods.control}
                                            render={({ field }) => (
                                                <Select
                                                    onValueChange={(value) => field.onChange(Number(value))} // Convert the value to a number
                                                    value={field.value?.toString() || ""} // Convert the value to a string, defaulting to an empty string
                                                >
                                                    <SelectTrigger>
                                                        <SelectValue placeholder={t("Select currency")} />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectItem value="1">{t("Lekë")}</SelectItem>
                                                        <SelectItem value="2">{t("Euro")}</SelectItem>
                                                    </SelectContent>
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                    {methods.formState.errors.baseCurrency && (
                                        <FormMessage>{methods.formState.errors.baseCurrency.message}</FormMessage>
                                    )}
                                </FormItem>

                            </fieldset>
                        )}

                    </div>

                    <div className="flex justify-center mt-4 space-x-4">
                        {step > 1 && (
                            <Button type="button" onClick={handlePrevious}>
                                {t("Previous")}
                            </Button>
                        )}
                        {step < 2 && (
                            <Button type="button" onClick={handleNext}>
                                {t("Next")}
                            </Button>
                        )}
                        {step === 2 && (
                            <Button type="submit" className="ml-auto">
                                {t("savelbtn")}
                            </Button>
                        )}
                    </div>
                </form>




                <ToastContainer />
            </div>
        </FormProvider>

    );
};

export default BusinessRegisterForm;
