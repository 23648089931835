import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import "@/css/MobileScreen.css";
import CreateSupplierDto from "@/models/CreateSupplierDto";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import Select, { SingleValue } from "react-select";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import "@/css/dialog.css";
import { Supplier } from "@/types/SupplierType";
import VatForm from "../VAT/VatForm";
import { VaT } from "@/interfaces/VatInterfaces";
import { SupplierFormProps } from "@/interfaces/SupplierInterfaces";
import { SelectOption } from "@/interfaces/SupplierInterfaces";
import DataFetcher from "@/provider/DataFetcher";
import "@/css/LoadingCreate.css";


const SupplierForm: React.FC<SupplierFormProps> = ({
  mode,
  inDialog = false,
  onClose,
}) => {
  const { t } = useTranslation();

  const formSchema = z.object({
    name: z.string().min(1, { message: t("Name is required") }),
    phoneNumber: z.string().nullable().optional(),
    email: z.string().nullable().optional(),
    contact: z.string().nullable().optional(),
    vat:  z.string().nullable().optional(),
    address: z.string().nullable().optional(),
    city: z.string().nullable().optional(),
    country: z.string().nullable().optional(),
  });
  
  type FormValues = z.infer<typeof formSchema>;

  const [loading, setLoading] = useState(false); // Initialize loading state
const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;


  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || { id: localStorage.getItem("supplierId") };
  const [vats, setVaTs] = useState<SelectOption[]>([]);
  const [isVaTDialogOpen, setisVaTDialogOpen] = useState(false);

  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      phoneNumber: null,
      email: null,
      contact: null,
      vat: null,
      address: null,
      city: null,
      country: null,
    },
  });

  const SUPPLIER_STORAGE_KEY = "supplierData";
  const VAT_STORAGE_KEY = "vatData";

  const handleCloseDialogVat = (newVat?: { id: number; name: string }) => {
    setisVaTDialogOpen(false);
    if (newVat) {
      const newVatOption = { value: newVat.id.toString(), label: newVat.name };
      setVaTs((prevVats) => [...prevVats, newVatOption]);
      methods.setValue("vat", newVat.id.toString());
    }
  };

  useEffect(() => {
    const fetchVaT = async () => {
      const response = await DataFetcher.getData<VaT[]>(
        `${API_URLS.BASE_URL}${API_URLS.VAT}`,
        VAT_STORAGE_KEY,
        true
      );
      if (response) {
        const vatOptions = response.map((vat) => ({
          value: vat.id.toString(),
          label: vat.name,
        }));
        setVaTs(vatOptions);
      }
    };

    fetchVaT();

    if (id) {
      localStorage.setItem("supplierId", id);
    }

    if (mode === "edit" && id) {
      const fetchSupplier = async () => {
        try {
          const response = await DataFetcher.getData<Supplier>(
            `${API_URLS.BASE_URL}${API_URLS.SUPPLIER}/${id}`,
            SUPPLIER_STORAGE_KEY,
            true
          );
          if (response) {
            methods.reset({
              ...response,
              vat: response.vatNumber ? response.vatNumber.toString() : "",
            });
          }
        } catch (error) {
          console.error("Error fetching supplier:", error);
        }
      };
      fetchSupplier();
    }
  }, [id, mode, methods]);

  const updateLocalStorage = (updatedSupplier: Supplier) => {
    // Ensure that existingSuppliers is an array, or use an empty array if not
    const existingSuppliers = JSON.parse(localStorage.getItem("supplierData") || "[]").data;
  
    // Check if the parsed result is an array, if not, default to an empty array
    const suppliersArray = Array.isArray(existingSuppliers) ? existingSuppliers : [];
  
    const updatedSuppliers = suppliersArray.map((supplier: Supplier) =>
      supplier.id === updatedSupplier.id ? updatedSupplier : supplier
    );
  
    localStorage.setItem("supplierData", JSON.stringify(updatedSuppliers));
  };
  
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true); // Show loading spinner

    const dto = new CreateSupplierDto({
      name: data.name,
      phoneNumber: data.phoneNumber,
      email: data.email,
      contact: data.contact,
      vatNumber: data.vat,
      address: data.address,
      city: data.city,
      country: data.country,
    });
  
    try {
      let successMessage = "";
  
      if (mode === "create") {
        const response = await axios.post(
          `${API_URLS.BASE_URL}${API_URLS.SUPPLIER}`,
          dto
        );
        console.log("Supplier created successfully:", response.data);
        successMessage = t("Supplier created successfully");
  
        // Ensure the existing data in localStorage is always an array
        const existingSuppliers = JSON.parse(
          localStorage.getItem("supplierData") || "[]"
        );
        const suppliersArray = Array.isArray(existingSuppliers) ? existingSuppliers : [];
  
        // Add the new supplier to the array
        suppliersArray.push(response.data);
        localStorage.setItem("supplierData", JSON.stringify(suppliersArray));
  
        if (inDialog && onClose) {
          onClose({ id: response.data.id, name: response.data.name });
        }
      } else if (mode === "edit" && id) {
        const response = await axios.put(
          `${API_URLS.BASE_URL}${API_URLS.SUPPLIER}/${id}`,
          dto
        );
        console.log("Supplier updated successfully:", response.data);
        successMessage = t("Supplier updated successfully");
  
        // Update local storage with the updated supplier
        updateLocalStorage(response.data);
      }
  
      methods.reset();
      localStorage.removeItem("supplierId");
  
      // Navigate first, then show toast message after a short delay
      if (inDialog && onClose) {
        onClose();
      } else {
        navigate(t("/supplierlistpath"));
      }
  
      // Show toast after navigation
      setTimeout(() => {
        if (successMessage) {
          toast.success(successMessage);
        }
      }, 250);
  
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
  
        if (status === 403) {
          toast.error(t("You do not have permission to perform this action."));
        } else if (status === 500) {
          toast.error(t("A server error occurred. Please try again later."));
        } else if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorSupplier.${err.errorMessage}`) ||
              t("errorSupplier.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          toast.error(t("An error occurred"));
        }
      } else {
        toast.error(t("An unexpected error occurred"));
      }
      console.error("Error submitting supplier:", error);
    } 
    finally {
      setLoading(false); // Hide loading spinner
    }
  };
  

  

  const handleDialogSubmit = () => {
    methods.handleSubmit(onSubmit)();
  };

  return (
    <FormProvider {...methods}>
      <div
        className={`flex justify-center items-center ${
          inDialog ? "dialog-grid" : ""
        }`}
      >
        {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        <div className="w-full max-w-3xl p-6 rounded shadow-md">
          <h1 className="text-xl font-bold mb-6">
            {mode === "create" ? t("createsupplier") : t("editsupplier")}
          </h1>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleDialogSubmit();
            }}
          >
            <div className={inDialog ? "dialog-fieldsets" : ""}>
              <fieldset className="border p-4 rounded mb-4">
                <legend className="text-lg font-semibold">
                  {t("infosupplier")}
                </legend>
                <FormItem>
                  <FormLabel>{t("name")}<span style={{ color: "red" }}>*</span></FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("name")}
                      type="search"
                      placeholder={t("putname")}
                      className="w-full"
                    />
                  </FormControl>
                  {methods.formState.errors.name && (
                    <FormMessage>
                      {methods.formState.errors.name.message}
                    </FormMessage>
                  )}
                </FormItem>
                <FormItem>
                  <FormLabel>{t("telnumber")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("phoneNumber")}
                      type="search"
                      placeholder={t("puttelnumber")}
                      className="w-full"
                    />
                  </FormControl>
                  {methods.formState.errors.phoneNumber && (
                    <FormMessage>
                      {methods.formState.errors.phoneNumber.message}
                    </FormMessage>
                  )}
                </FormItem>
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("email")}
                      type="email"
                      placeholder={t("putemail")}
                      className="w-full"
                    />
                  </FormControl>
                  {methods.formState.errors.email && (
                    <FormMessage>
                      {methods.formState.errors.email.message}
                    </FormMessage>
                  )}
                </FormItem>
                <FormItem>
                  <FormLabel>{t("contact")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("contact")}
                      type="search"
                      placeholder={t("putcontact")}
                      className="w-full"
                    />
                  </FormControl>
                  {methods.formState.errors.contact && (
                    <FormMessage>
                      {methods.formState.errors.contact.message}
                    </FormMessage>
                  )}
                </FormItem>
              </fieldset>
              <fieldset className="border p-4 rounded mb-4">
                <div className="flex items-center gap-2">
                  <FormItem className="flex-1">
                    <FormLabel>{t("vat")}</FormLabel>
                    <FormControl>
                      <Controller
                        name="vat"
                        control={methods.control}
                        render={({ field }) => {
                          const selectedOption = vats.find(
                            (option) => option.value === field.value
                          );
                          return (
                         <Select
  {...field}
  value={selectedOption}
  onChange={(option: SingleValue<SelectOption>) => field.onChange(option?.value)}
  options={vats}
  placeholder={t('putvatnumber')}
  styles={{
    control: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? '#333' : '#fff',
      color: isDarkMode ? '#fff' : '#000',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDarkMode ? '#fff' : '#000',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? (isDarkMode ? '#555' : '#e5e5e5') : (isDarkMode ? '#333' : '#fff'),
      color: isDarkMode ? '#000' : '#000', // Set the text color to black in dark mode
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? '#333' : '#fff',
    }),
  }}
/>
                          );
                        }}
                      />
                    </FormControl>
                    {methods.formState.errors.vat && (
                      <FormMessage>
                        {methods.formState.errors.vat.message}
                      </FormMessage>
                    )}
                  </FormItem>
                  <Dialog open={isVaTDialogOpen} onOpenChange={setisVaTDialogOpen}>
  <DialogTrigger asChild>
<Button
  type="button"
  className="bg-blue-500 text-white  rounded"
  style={{ marginTop: '31px', height: '35px',width:'35px' }}  // Adds space above the button
>
  +
</Button>
  </DialogTrigger>
<DialogContent
  style={
    window.innerWidth <= 768
      ? {
          maxWidth: '300px',   // Style for screens 768px or less
          maxHeight: '80vh',
          overflowY: 'auto',
        }
      : window.innerWidth <= 999
      ? {
          maxWidth: '600px',   // Style for screens between 769px and 999px
          maxHeight: '80vh',
          overflowY: 'auto',
        }
      : {}
  }
>
  <VatForm
    mode="create"
    inDialog={true}
    onClose={handleCloseDialogVat}
  />
</DialogContent>


</Dialog>

                </div>
                <FormItem>
                  <FormLabel>{t("adresss")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("address")}
                      type="search"
                      placeholder={t("putadresss")}
                      className="w-full"
                    />
                  </FormControl>
                  {methods.formState.errors.address && (
                    <FormMessage>
                      {methods.formState.errors.address.message}
                    </FormMessage>
                  )}
                </FormItem>
                <FormItem>
                  <FormLabel>{t("city")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("city")}
                      type="search"
                      placeholder={t("putcity")}
                      className="w-full"
                    />
                  </FormControl>
                  {methods.formState.errors.city && (
                    <FormMessage>
                      {methods.formState.errors.city.message}
                    </FormMessage>
                  )}
                </FormItem>
                <FormItem>
                  <FormLabel>{t("country")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("country")}
                      type="search"
                      placeholder={t("putcountry")}
                      className="w-full"
                    />
                  </FormControl>
                  {methods.formState.errors.country && (
                    <FormMessage>
                      {methods.formState.errors.country.message}
                    </FormMessage>
                  )}
                </FormItem>
              </fieldset>
            </div>
            <div className="flex justify-center gap-4 mt-6">
              <Button
                type="button"
                onClick={handleDialogSubmit}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                {t("savelbtn")}
              </Button>
              <Button
                type="button"
                onClick={() =>
                  inDialog ? onClose?.() : navigate(t("/supplierlistpath"))
                }
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                {t("canclelbtn")}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </FormProvider>
  );
};

export default SupplierForm;
