import React, { useEffect, useState } from "react";
import { useForm, FormProvider, Controller, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { FormControl, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/provider/authProvider";
import DataFetcher from "@/provider/DataFetcher";
import { useTranslation } from "react-i18next";
import CreateTransactionDto from "@/models/CreateCashLedgerDTO";

// Form schema using zod
const formSchema = z.object({
  transactionType: z.number().min(3).max(6),  // Assuming it accepts numbers 3 to 6
  amount: z.number().positive(),
  cashAccountId: z.string(),
  note: z.string().optional(),
});
type FormValues = z.infer<typeof formSchema>;

interface CashAccount {
  id: number;
  balance: number;
  currencyId: string;
  name: string;
  iban?: string | null;
  swift?: string | null;
  owners: { id: number }[];
}

const CashLedger: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { employeeId } = useAuth();
  
   const CashAccount_STORAGE_KEY = "cashaccountData";
  const [employeeCashAccounts, setEmployeeCashAccounts] = useState<CashAccount[]>([]); // State for employee-specific accounts
  const [parsedEmployeeId, setParsedEmployeeId] = useState<number | null>(null);

  const methods = useForm<FormValues>({
  resolver: zodResolver(formSchema),
  defaultValues: {
    transactionType: undefined,  // Set to undefined instead of an empty string
    amount: 0,  // Default amount can stay as 0
    cashAccountId: undefined,  // Set to undefined instead of an empty string
    note: "",  // Optional field, can remain as an empty string
  },
});

 useEffect(() => {
    setParsedEmployeeId(employeeId ? parseInt(employeeId, 10) : null);
  }, [employeeId]);
  // Fetch available cash accounts
  // Fetch employee-specific cash accounts for "To Account"
  useEffect(() => {
    if (parsedEmployeeId !== null) {
      const fetchEmployeeCashAccounts = async () => {
        try {
          const response = await DataFetcher.getData<CashAccount[]>(
            `${API_URLS.BASE_URL}${API_URLS.CashAccount}`,
            CashAccount_STORAGE_KEY,
            true
            
          );
          if (response) {
            const employeeAccounts = response.filter((account) =>
              account.owners.some((owner) => owner.id === parsedEmployeeId)
            );
            setEmployeeCashAccounts(employeeAccounts); // Set employee-specific accounts for "To Account"
          }
        } catch (error) {
          console.error("Error fetching employee-specific cash accounts:", error);
        }
      };

      fetchEmployeeCashAccounts();
    }
  }, [parsedEmployeeId]);

const onSubmit: SubmitHandler<FormValues> = async (data) => {
  const { transactionType, amount, cashAccountId, note } = data;

  try {
    const transactionDto = new CreateTransactionDto({
      transactionType,  // Already a number
      amount,
      cashAccountId: parseInt(cashAccountId, 10), // Assuming cashAccountId is still a string
      note: note || null,
    });

    // Proceed with the API call
    await axios.post(`${API_URLS.BASE_URL}${API_URLS.CASHLEGDER}`, transactionDto);

    toast.success("Transaction completed successfully!");
    methods.reset();
  } catch (error) {
    toast.error("Error processing the transaction");
  }
};




  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center">
        <div className="w-full max-w-lg p-6 rounded shadow-md">
          <div className="cash-ledger-editor">
            <ToastContainer />
            <h2>{t("Record Cash Transaction")}</h2>

            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {/* Transaction Type */}
              <FormItem>
                <FormLabel>{t("Transaction Type")} <span style={{ color: "red" }}>*</span></FormLabel>
                <FormControl>
                  <Controller
                    name="transactionType"
                    control={methods.control}
                    render={({ field }) => (
                     <Select
  onValueChange={(value) => field.onChange(Number(value))} // Convert the value back to a number
  value={field.value?.toString() || ""} // Convert the field value to a string, defaulting to an empty string
>
  <SelectTrigger>
    <SelectValue placeholder={t("Select transaction type")} />
  </SelectTrigger>
  <SelectContent>
    <SelectItem value="3">{t("Receivable")}</SelectItem>
    <SelectItem value="4">{t("Payable")}</SelectItem>
    <SelectItem value="5">{t("Open Balance")}</SelectItem>
    <SelectItem value="6">{t("Other")}</SelectItem>
  </SelectContent>
</Select>

                    )}
                  />
                </FormControl>
                {methods.formState.errors.transactionType && (
                  <FormMessage>
                    {methods.formState.errors.transactionType.message}
                  </FormMessage>
                )}
              </FormItem>

              {/* Amount */}
              <FormItem>
                <FormLabel>{t("Transfer Amount")} <span style={{ color: "red" }}>*</span></FormLabel>
                <FormControl>
                  <Input
                    {...methods.register("amount", { valueAsNumber: true })}
                    type="number"
                    placeholder={t("Enter amount")}
                  />
                </FormControl>
                {methods.formState.errors.amount && (
                  <FormMessage>
                    {methods.formState.errors.amount.message}
                  </FormMessage>
                )}
              </FormItem>

              {/* Cash Account */}
              <FormItem>
                <FormLabel>{t("Cash Account")} <span style={{ color: "red" }}>*</span></FormLabel>
                <FormControl>
                  <Controller
                    name="cashAccountId"
                    control={methods.control}
                    render={({ field }) => (
                   <Select
                        onValueChange={(value) => field.onChange(value)}
                        value={field.value}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder={t("Select to account")} />
                        </SelectTrigger>
                        <SelectContent>
                          {employeeCashAccounts.map((account) => (
                            <SelectItem
                              key={account.id}
                              value={account.id.toString()}
                            >
                              {account.name} - {account.balance}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                  />
                </FormControl>
                {methods.formState.errors.cashAccountId && (
                  <FormMessage>
                    {methods.formState.errors.cashAccountId.message}
                  </FormMessage>
                )}
              </FormItem>

              {/* Note */}
              <FormItem>
                <FormLabel>{t("Note")}</FormLabel>
                <FormControl>
                  <Input
                    {...methods.register("note")}
                    type="text"
                    placeholder={t("Enter note")}
                  />
                </FormControl>
              </FormItem>

              {/* Submit button */}
              <div className="flex gap-4 mt-6">
                <Button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  {t("Submit")}
                </Button>
                <Button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                  {t("Cancel")}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default CashLedger;
