import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import API_URLS from "@/config";
import { useNavigate } from "react-router-dom";
import { Accordion } from "@/components/ui/accordion";
import {
  HomeIcon,
  UserIcon,
  ChevronRightIcon,
  XMarkIcon,
  CurrencyDollarIcon,
  TruckIcon,
  CogIcon,
  PencilSquareIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import AccordionMenuItem from "./MenuItem";
import SearchBar from "./SearchBar";
import { useAuth } from "@/provider/authProvider";
import DataFetcher from "@/provider/DataFetcher";
import useIsMobile from "./Mobile";
import { Role } from "@/types/RoleType";
interface SidebarProps {
  isOpen: boolean;
  activeItem: string[];
  onIconClick: (item: string[]) => void;
  onToggle: () => void;
}

interface CompanyData {
  id: number;
  name: string;
  tin: string;
  employees: number;
  additionalInfo: string;
  city: string;
  country: string;
  phoneNumber: string;
  email: string;
  fieldOfActivity: string;
  fiscalizationCertificate: string;
  isSeasonal: boolean;
  subscriptionStartDateUtc: string;
  subscriptionEndDateUtc: string;
}

interface MenuItem {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  label: string;
  permissionId?: number;
  subItems?: SubItem[];
}

interface SubItem {
  label: string;
  route?: string;
  permissionId?: number;
  subItems?: SubItem[];
}

const ROLE_STORAGE_KEY = "roleDataEmp";
const decodeJWT = (token: string) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join("")
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Failed to decode token", error);
    return null;
  }
};
const Sidebar: React.FC<SidebarProps> = ({
  isOpen,
  activeItem,
  onIconClick,
  onToggle,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sidebarRef = useRef<HTMLDivElement>(null); // Create a ref for the sidebar
  const [avatarSrc, setAvatarSrc] = useState<string | undefined>(undefined);
  const [rolePermissions, setRolePermissions] = useState<number[]>([]);
  const { token, roleId, isSuperUser } = useAuth();
  const getAvatarFromLocalStorage = (): string | null => {
    return localStorage.getItem("avatarSrc");
  };
  console.log(isSuperUser);

  useEffect(() => {
    const savedAvatar = getAvatarFromLocalStorage();
    if (savedAvatar) {
      setAvatarSrc(savedAvatar);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        onToggle(); // Close the sidebar if the click is outside
      }
    };

    if (isOpen && isMobile) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);
  const isMobile = useIsMobile();

  const shouldShowSalesOrder = !isMobile; // Show if not mobile
  const shouldShowPurchaseOrder = !isMobile; // Show if not mobile
  const shouldShowPurchaseOrderList = !isMobile; // Show if not mobile

  const handleIconClick = (itemLabel: string) => {
    if (!isOpen) {
      onToggle();
    }
    setActiveItems([itemLabel.toLowerCase()]);
    onIconClick([itemLabel.toLowerCase()]);
  };
  const handleIconClick1 = () => {
    if (!isOpen) {
      onToggle();
    }
  };
  // const handleItemClick = (route: string) => {
  //   navigate(route);
  //   if (isMobile) {
  //     onToggle(); // Close the sidebar for mobile
  //   }
  // };
  useEffect(() => {
    if (isSuperUser) {
      return; // Skip fetching permissions if the user is super user
    }
    const fetchRolePermissions = async () => {
      if (!roleId) {
        console.error("No roleId found");
        return;
      }

      try {
        const response = await DataFetcher.getData<Role>(
          `${API_URLS.BASE_URL}${API_URLS.ROLE}/${roleId}`,
          ROLE_STORAGE_KEY
        );
        if (response && response.permissions) {
          setRolePermissions(response.permissions.map((perm: any) => perm.id));
          console.log(response.permissions.map((perm: any) => perm.id));
        }
      } catch (error) {
        console.error("Error fetching role permissions:", error);
      }
    };

    fetchRolePermissions(); // Call the function here
  }, [roleId, isSuperUser]); // Dependency on roleId

  const getMenuItems = () => [
    // Reports Section (commented out)
    // {
    //     icon: ChartBarIcon,
    //     label: t('reports'),
    //     subItems: [
    //         { label: t('sales'), route: "/reports/sales" },
    //         { label: t('stockreports'), route: "/reports/stock" },
    //         {
    //             label: t('financialreports'),
    //             subItems: [
    //                 { label: t('employeewages') },
    //                 { label: t('revenue') },
    //                 { label: t('cashflow') },
    //                 { label: t('expenses') },
    //                 { label: t('debt') },
    //                 { label: t('uncompletedpayments') },
    //                 { label: t('profit') },
    //                 { label: t('Completefinancialreport') },
    //                 { label: t('invoicesreport') },
    //                 { label: t('banktransactions') },
    //                 { label: t('taxfillingreport') },
    //                 { label: t('cashregistersreport') },
    //             ],
    //         },
    //         { label: t('customerreports') },
    //         { label: t('employeeperformance') },
    //         { label: t('shift') },
    //         { label: t('discounts') },
    //         { label: t('orderreport') },
    //         { label: t('receiptreport') },
    //         { label: t('productperformance') },
    //         { label: t('expireddamagedproducts') },
    //         { label: t('documentrecord') },
    //         { label: t('transactionhistoryreport') },
    //     ],
    // },

    // User Management Section
    {
      icon: UserIcon,
      label: t("usermanagment"),
      subItems: [
        {
          label: t("employee"),
          subItems: [
            {
              label: t("employee"),
              route: t("/emplistpath"),
              permissionId: 11, // Permission to view employee list
            },
            {
              label: t("role"),
              route: t("/rolelistpath"),
              permissionId: 27, // Permission to view role list
            },
          ],
        },
        // Account management (commented out)
        // { label: t('accountmanagement') },

        {
          label: t("customermanagement"),
          permissionId: 9,
          subItems: [
            {
              label: t("customers"),
              route: t("/listcustomerpath"),
              permissionId: 9, // Permission to view customer list
            },
            {
              label: t("customerscategory"),
              route: t("/categorylsitcustomer"),
              permissionId: 1,
            },
            // { label: t("loyaltycards") }, // Commented out
          ],
        },
      ],
    },

    // Products Section
    {
      icon: HomeIcon,
      label: t("products"),
      subItems: [
        {
          label: t("productlist"),
          route: t("/prodlistpath"),
          permissionId: 23, // Permission to view product list
        },
        {
          label: t("productcomposite"),
          route: t("/productcompositelistpath"),
          permissionId: 5, // Permission to view composite products
        },
        {
          label: t("categorymanagement"),
          route: t("/categorylsitproduct"),
          permissionId: 21, // Permission to view product categories
        },
        {
          label: t("uom"),
          route: t("/uomlistpath"),
          permissionId: 37, // Permission to view units of measure (UoM)
        },
      ],
    },

    // Sales Order Section
    {
      icon: PencilSquareIcon,
      label: t(`salesorder`),
      subItems: [
        ...(shouldShowSalesOrder
          ? [
              {
                label: t("salesorder"),
                route: t("/salesorderpath"),
                permissionId: 30, // Permission to view sales orders
              },
            ]
          : []),
        {
          label: t("salesOrderList"),
          route: t("/salesorderlistpath"),
          permissionId: 29, // Permission to view sales order list
        },
        {
          label: t("FAST ORDER"),
          route: t("/fastsale"),
          permissionId: 30, // Permission to create fast orders
        },
        {
          label: t("POSBAR"),
          route: t("/posbarpath"),
          permissionId: 29, // Permission to access POS bar
        },
      ],
    },

    // Inventory Section
    {
      icon: TruckIcon,
      label: t("inventory"),
      subItems: [
        {
          label: t("inventory"),
          route: t("/inventorylistpath"),
          permissionId: 13, // Permission to view inventory items
        },
        {
          label: t("suppliers"),
          permissionId: 33,
          subItems: [
            {
              label: t("suppliers"),
              route: t("/supplierlistpath"),
              permissionId: 33, // Permission to view suppliers
            },
            {
              label: t("lot"),
              route: t("/lotlistpath"),
              permissionId: 15, // Permission to view lots
            },
          ],
        },
        {
          label: t("shop"),
          route: t("/shoplistpath"),
          permissionId: 31, // Permission to view shops
        },
        {
          label: t("warehouse"),
          route: t("/warehouselistpath"),
          permissionId: 41, // Permission to view warehouses
        },
        ...(shouldShowPurchaseOrder
          ? [
              {
                label: t("purchaseorder"),
                route: t("/purchaseorderpath"),
                permissionId: 26, // Permission to view purchase orders
              },
            ]
          : []),
        ...(shouldShowPurchaseOrderList
          ? [
              {
                label: t("purchaseOrderList"),
                route: t("/purchaseorderlistpath"),
                permissionId: 25, // Permission to view purchase order list
              },
            ]
          : []),
      ],
    },

    // Financial Management Section
    {
      icon: CurrencyDollarIcon,
      label: t("finacialmanagment"),
      subItems: [
        {
          label: t("payment methods"),
          route: t("/paymentlistpath"),
          permissionId: 17, // Permission to view payment methods
        },
        {
          label: t("payment terms"),
          route: t("/paymentTermslistpath"),
          permissionId: 19, // Permission to view payment terms
        },
        {
          label: t("Currency"),
          route: t("/currencylistpath"),
          permissionId: 7, // Permission to view currencies
        },
        {
          label: t("/cashaccountlist"),
          route: t("/cashaccountlistpath"),
          permissionId: 1, // Permission to view cash accounts
        },
        {
          label: t("exchangerate"),
          route: t("/exchangeratelistpath"),
          permissionId: 43,
        },
        {
          label: t("Transfer Funds"),
          route: t("/accountTransfer"),
        },
        {
          label: t("Cash Ledger"),
          route: t("/cashledger"),
        },

        //         {
        //             label: t('managetransactions'),
        //         },
        //         {
        //             label: t('taxcalculator'),
        //         },
        //         {
        //             label:  t('preparetaxforms'),
        //         },
      ],
    },

    // Settings Section
    {
      icon: CogIcon,
      label: t("settings"),
      subItems: [
        {
          label: t("Account"),
          route: t("/account"),
          permissionId: 1,
        },
        {
          label: t("vat"),
          route: t("/vatlistpath"),
          permissionId: 39, // Permission to view VAT rates
        },
        {
          label: t("Cash Balance"),
          route: t("/balance"),
          permissionId: 1, // Permission to view cash balance
        },
        {
          label: t("business"),
          route: t("/companyinfo"),
          permissionId: 1,
        },
        {
          label: t("Company Config"),
          route: t("/companyconfig"),
          permissionId: 1,
        },
      ],
    },
  ];

  const [activeItems, setActiveItems] = useState<string[]>(activeItem);
  const [companyData, setCompanyData] = useState<CompanyData | null>(null);
  const [, setSearchQuery] = useState<string>("");
  const [filteredItems, setFilteredItems] = useState<MenuItem[]>(
    getMenuItems()
  );

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    if (query) {
      const lowerCaseQuery = query.toLowerCase();
      const filtered = getMenuItems().filter(
        (item) =>
          item.label.toLowerCase().includes(lowerCaseQuery) ||
          item.subItems.some((subItem) =>
            subItem.label.toLowerCase().includes(lowerCaseQuery)
          )
      );

      const activeSections = filtered.map((item) => item.label.toLowerCase());
      setActiveItems(activeSections);
      setFilteredItems(filtered);
    } else {
      setActiveItems([]);
      setFilteredItems(getMenuItems());
    }
  };

  const handlePlusClick = (subItem: string, parentLabel: string) => {
    if (parentLabel === t("employee") && subItem === t("employee")) {
      navigate(t("/createemppath"));
    } else if (
      parentLabel === t("customermanagement") &&
      subItem === t("customers")
    ) {
      navigate(t("/createcustomerpath"));
    } else if (
      parentLabel === t("customermanagement") &&
      subItem === t("customerscategory")
    ) {
      navigate(t("/createcustomercategorypath"));
    } else if (
      parentLabel === t("products") &&
      subItem === t("categorymanagement")
    ) {
      navigate(t("/createcproductcategorypath"));
    } else if (parentLabel === t("products") && subItem === t("productlist")) {
      navigate(t("/createprodpath"));
    } else if (parentLabel === t("suppliers") && subItem === t("suppliers")) {
      navigate(t("/createsupplierpath"));
    } else if (parentLabel === t("suppliers") && subItem === t("lot")) {
      navigate(t("/createlotpath"));
    } else if (parentLabel === t("employee") && subItem === t("role")) {
      navigate(t("/createrolepath"));
    } else if (parentLabel === t("products") && subItem === t("uom")) {
      navigate(t("/createruompath"));
    } else if (
      parentLabel === t("products") &&
      subItem === t("productcomposite")
    ) {
      navigate(t("/createproductcompositepath"));
    } else if (parentLabel === t("settings") && subItem === t("vat")) {
      navigate(t("/createvatlistpath"));
    } else if (parentLabel === t("inventory") && subItem === t("inventory")) {
      navigate(t("/createinventorypath"));
    } else if (parentLabel === t("inventory") && subItem === t("shop")) {
      navigate(t("/createshoppath"));
    } else if (parentLabel === t("inventory") && subItem === t("warehouse")) {
      navigate(t("/createwarehousepath"));
    } else if (
      parentLabel === t("finacialmanagment") &&
      subItem === t("payment methods")
    ) {
      navigate(t("/createpaymentmethodpath"));
    } else if (
      parentLabel === t("finacialmanagment") &&
      subItem === t("payment terms")
    ) {
      navigate(t("/createpaymentTerms"));
    } else if (
      parentLabel === t("finacialmanagment") &&
      subItem === t("Currency")
    ) {
      navigate(t("/createcurrencypath"));
    } else if (
      parentLabel === t("finacialmanagment") &&
      subItem === t("/cashaccountlist")
    ) {
      navigate(t("/createcashaccount"));
    } else if (
      parentLabel === t("finacialmanagment") &&
      subItem === t("/cashaccountownerlist")
    ) {
      navigate(t("/createcashaccountowner"));
    } else if (
      parentLabel === t("finacialmanagment") &&
      subItem === t("exchangerate")
    ) {
      navigate(t("/createexchangeratepath"));
    }
  };
  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        // Fetch the list of companies from the API
        const response = await DataFetcher.getData<CompanyData[]>(
          `${API_URLS.BASE_URL}${API_URLS.BUSINESSREGISTER}`,
          "businessData"
        );

        if (response && response.length > 0) {
          // Decode the token to get the tenantId
          if (token) {
            const decodedToken = decodeJWT(token);
            if (decodedToken && decodedToken.tenantId) {
              const tenantId = Number(decodedToken.tenantId); // Ensure tenantId is a number

              // Find the company data that matches the tenantId
              const matchedCompany = response.find(
                (company) => company.id === tenantId
              );

              if (matchedCompany) {
                setCompanyData(matchedCompany);
              } else {
                console.error(
                  "No matching company data found for tenantId:",
                  tenantId
                );
                // Handle the case where no matching company is found
                setCompanyData(null); // Or any other default value or error handling
              }
            } else {
              console.error("Invalid token data");
              // Handle invalid token data
              setCompanyData(null); // Or any other default value or error handling
            }
          } else {
            console.error("Token is null or undefined");
            // Handle the case where the token is not available
            setCompanyData(null); // Or any other default value or error handling
          }
        } else {
          console.error("No company data found");
          // Handle the case where no company data is returned
          setCompanyData(null); // Or any other default value or error handling
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
        // Handle any errors during the fetch
        setCompanyData(null); // Or any other default value or error handling
      }
    };

    fetchCompanyData();
  }, [token, navigate]);

  const hasPermission = (permissionId?: number): boolean => {
    if (isSuperUser) return true; // SuperUser skips permission checks
    return permissionId ? rolePermissions.includes(permissionId) : true;
  };

  const filterMenuItems = (items: MenuItem[]): MenuItem[] => {
    return items
      .map((item) => {
        // Filter subItems if they exist and have valid permissions
        const filteredSubItems = item.subItems
          ?.filter((subItem) => hasPermission(subItem.permissionId))
          .map((subItem) => ({
            ...subItem,
            subItems: subItem.subItems
              ? subItem.subItems.filter((nestedItem) =>
                  hasPermission(nestedItem.permissionId)
                )
              : undefined, // Keep subItems optional if no valid nested items exist
          }));

        // If filteredSubItems exist, return the item with those subItems, or return item as is if no subItems
        if (filteredSubItems && filteredSubItems.length > 0) {
          return { ...item, subItems: filteredSubItems };
        } else if (!item.subItems || item.subItems.length === 0) {
          return item; // Return the item as is if it has no subItems
        }

        return null; // If there are no valid subItems, return null
      })
      .filter((item): item is MenuItem => item !== null); // Ensure we filter out any null items
  };

  useEffect(() => {
    setFilteredItems(filterMenuItems(getMenuItems()));
  }, [rolePermissions, t, isSuperUser]);

  const handleValueChange = (items: string[]) => {
    setActiveItems(items);
    onIconClick(items);
  };

  return (
    <div ref={sidebarRef} className={`sidebar ${isOpen ? "open" : "closed"}`}>
      <div
        className={`fixed inset-y-0 left-0 transition-transform transform ${
          isOpen ? "w-65" : "w-10"
        } bg-gray-800 text-white flex flex-col`}
      >
        <div className="flex items-center justify-between h-16 bg-gray-900 pr-4">
          <Avatar className="h-10 w-10 sm:h-10 sm:w-10 md:h-8 md:w-8 lg:h-8 lg:w-8 xl:h-8 xl:w-8">
            <AvatarImage
              src={avatarSrc || "https://github.com/shadcn.png"}
              alt="Company Logo"
              className="rounded-full object-cover h-full w-full"
            />
          </Avatar>
          {isOpen && (
            <h1 className="text-xl font-bold">
              {companyData ? companyData.name : t("Loading...")}
            </h1>
          )}
          <button
            onClick={onToggle}
            className="text-white focus:outline-none flex items-center"
          >
            {isOpen ? (
              <XMarkIcon className="h-6 w-6" />
            ) : (
              <ChevronRightIcon className="h-6 w-6" />
            )}
          </button>
        </div>

        <div className={`${isOpen ? "mb-4" : ""}`}>
          {isOpen ? (
            <SearchBar onSearch={handleSearch} />
          ) : (
            <div className="flex justify-center pt-4">
              {/* Render only a search icon when closed */}
              <MagnifyingGlassIcon
                className="h-6 w-6"
                onClick={handleIconClick1} // Open sidebar on icon click
              />
            </div>
          )}
        </div>
        <div className="flex-1 overflow-y-auto">
          {isOpen ? (
            <Accordion
              type="multiple"
              value={activeItems}
              onValueChange={handleValueChange}
            >
              {filteredItems.map((item, index) => (
                <AccordionMenuItem
                  key={index}
                  icon={item.icon}
                  label={item.label}
                  subItems={item.subItems || []}
                  onPlusClick={handlePlusClick}
                  onSubItemClick={() => {
                    if (isMobile) {
                      onToggle();
                    }
                  }}
                />
              ))}
            </Accordion>
          ) : (
            <div className="flex flex-col space-y-4 py-4">
              {filteredItems.map((item, index) => (
                <div
                  key={index}
                  className="hover:bg-gray-700 p-2 rounded cursor-pointer"
                  onClick={() => handleIconClick(item.label)}
                >
                  <item.icon className="h-6 w-6" />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
