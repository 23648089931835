import { useTheme } from "./ThemeContext";
import { useTranslation } from 'react-i18next';
import { FaSun, FaMoon } from 'react-icons/fa';
import { useEffect, useRef, useState } from 'react';

const ThemeSwitcher = () => {
    const { theme, toggleTheme } = useTheme();
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

    const handleToggle = () => {
        toggleTheme();
        setIsOpen(false);
    };
     const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };
   useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


    return (
        <div className="relative" ref={dropdownRef}>
            <button
                className="flex items-center p-2 dark:bg-gray-800 rounded"
                onClick={() => setIsOpen(!isOpen)}
            >
                {theme === "dark" ? <FaMoon className="text-yellow-500" /> : <FaSun className="text-yellow-500" />}
                <span className="ml-2">{theme === "dark" ? t('dark') : t('light')}</span>
            </button>
            {isOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-gray-600 dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded shadow-lg z-10">
                    {theme === "dark" ? (
                        <button
                            className="flex items-center w-full px-4 py-2 text-sm text-left hover:bg-gray-700 dark:hover:bg-gray-600"
                            onClick={handleToggle}
                        >
                            <FaSun className="mr-2" />
                            {t('light')}
                        </button>
                    ) : (
                        <button
                            className="flex items-center w-full px-4 py-2 text-sm text-left hover:bg-gray-700 dark:hover:bg-gray-600"
                            onClick={handleToggle}
                        >
                            <FaMoon className="mr-2" />
                            {t('dark')}
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default ThemeSwitcher;
