import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import "@/css/MobileScreen.css";
import "@/css/permissions.css";
import CreateRoleDto from "@/models/CreateRoleDto";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { Role } from "@/types/RoleType";
import { RoleFormProps } from "@/interfaces/RoleInterfaces";
import axios from "axios";
import "@/css/LoadingCreate.css";

const ROLE_STORAGE_KEY = "roleData";

const RoleForm: React.FC<RoleFormProps> = ({
  mode,
  inDialog = false,
  onClose,
}) => {
  const { t } = useTranslation();

  const formSchema = z.object({
    name: z.string().min(1, { message: t("Name is required") }),
    description: z.string().optional(),
    permissions: z.array(z.number()).optional(),
  });

  type FormValues = z.infer<typeof formSchema>;
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || { id: localStorage.getItem("roleId") };
  const token = localStorage.getItem("token");
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      description: "",
      permissions: [],
    },
  });

  const [initialPermissions, setInitialPermissions] = useState<number[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [permissions, setPermissions] = useState<Record<string, any>>({});

  const fetchPermissions = async () => {
    try {
      const response = await axios.get(
        `${API_URLS.BASE_URL}${API_URLS.PERMISSION}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const fetchedPermissions = response.data;
      const groupedPermissions = fetchedPermissions.reduce(
        (acc: any, perm: any) => {
          const baseName = perm.name.replace(/(Read|Write)/, "");
          const readOrWrite = perm.name.startsWith("Read") ? "read" : "write";

          if (!acc[baseName]) {
            acc[baseName] = {};
          }

          acc[baseName][readOrWrite] = { id: perm.id, name: perm.name };
          return acc;
        },
        {}
      );
      setPermissions(groupedPermissions);
    } catch (error) {
      console.error("Error fetching permissions:", error);
      return [];
    }
  };

  useEffect(() => {
    const loadPermissions = async () => {
      await fetchPermissions();
    };

    loadPermissions();
  }, []);

  useEffect(() => {
    if (id) {
      localStorage.setItem("roleId", id);
    }

    if (mode === "edit" && id) {
      const fetchRole = async () => {
        try {
          const response = await axios.get(
            `${API_URLS.BASE_URL}${API_URLS.ROLE}/${id}`
          );
          if (response.data) {
            const initialPerms =
              response.data.permissions?.map((p: any) => p.id) || [];
            setInitialPermissions(initialPerms);
            methods.reset({
              name: response.data.name,
              description: response.data.description || "",
              permissions: initialPerms,
            });
          } else {
            console.error("No role data found");
          }
        } catch (error) {
          console.error("Error fetching role:", error);
        }
      };

      fetchRole();
    }
  }, [id, mode, methods]);

  const updateLocalStorage = (updatedRole: Role) => {
    const existingRoles = JSON.parse(
      localStorage.getItem(ROLE_STORAGE_KEY) || "[]"
    );
    if (Array.isArray(existingRoles)) {
      const updatedRoles = existingRoles.map((role: Role) =>
        role.id === updatedRole.id ? updatedRole : role
      );
      localStorage.setItem(ROLE_STORAGE_KEY, JSON.stringify(updatedRoles));
    } else {
      console.error("existingRoles is not an array:", existingRoles);
      localStorage.setItem(ROLE_STORAGE_KEY, JSON.stringify([updatedRole]));
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true);

    const roleData = new CreateRoleDto({
      name: data.name,
      description: data.description,
      permissionIds: data.permissions,
    });

    try {
      let successMessage = "";

      if (mode === "create") {
        const response = await axios.post(
          `${API_URLS.BASE_URL}${API_URLS.ROLE}`,
          roleData
        );
        successMessage = t("Role created successfully");

        const existingRoles = JSON.parse(
          localStorage.getItem(ROLE_STORAGE_KEY) || "[]"
        );
        existingRoles.push(response.data);
        localStorage.setItem(ROLE_STORAGE_KEY, JSON.stringify(existingRoles));

        if (inDialog && onClose) {
          onClose();
        }
      } else if (mode === "edit" && id) {
        // Compare initialPermissions with updatedPermissions to get add/remove permissions
        const updatedPermissions = data.permissions || [];

        const permissionAdditionIds = updatedPermissions.filter(
          (permId) => !initialPermissions.includes(permId)
        );
        console.log("add", permissionAdditionIds);
        const permissionRemovalIds = initialPermissions.filter(
          (permId) => !updatedPermissions.includes(permId)
        );
        console.log("remove", permissionRemovalIds);
        const editRoleData = {
          ...roleData,
          permissionAdditionIds,
          permissionRemovalIds,
        };

        const response = await axios.put(
          `${API_URLS.BASE_URL}${API_URLS.ROLE}/${id}`,
          editRoleData
        );
        successMessage = t("Role updated successfully");

        updateLocalStorage(response.data);

        if (inDialog && onClose) {
          onClose();
        }
      }

      methods.reset();
      localStorage.removeItem("roleId");

      if (!inDialog) {
        navigate(t("/rolelistpath"));
      }

      setTimeout(() => {
        if (successMessage) {
          toast.success(successMessage);
        }
      }, 250);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;

        if (status === 403) {
          toast.error(t("You do not have permission to perform this action."));
        } else if (status === 500) {
          toast.error(t("A server error occurred. Please try again later."));
        } else if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorRole.${err.errorMessage}`) ||
              t("errorRole.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          toast.error(t("An error occurred"));
        }
      } else {
        toast.error(t("An unexpected error occurred"));
      }
      console.error("Error submitting role:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDialogSubmit = () => {
    methods.handleSubmit(onSubmit)();
  };

  const handlePermissionChange = (permissionId: number) => {
    const currentPermissions = methods.getValues("permissions") || [];

    if (currentPermissions.includes(permissionId)) {
      methods.setValue(
        "permissions",
        currentPermissions.filter((id) => id !== permissionId)
      );
    } else {
      methods.setValue("permissions", [...currentPermissions, permissionId]);
    }

    methods.trigger("permissions");
  };
  return (
    <FormProvider {...methods}>
      <div className="form-container">
        {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        <h1>{mode === "create" ? t("createtole") : t("editrole")}</h1>

        <div className="form-content">
          <div className="form-grid-left">
            <FormItem className="form-item">
              <FormLabel className="form-label">{t("name")}<span style={{ color: "red" }}>*</span></FormLabel>
              <FormControl>
                <Input
                  {...methods.register("name")}
                  type="search"
                  className="form-input"
                  placeholder={t("putname")}
                />
              </FormControl>
              {methods.formState.errors.name && (
                <FormMessage className="form-message">
                  {methods.formState.errors.name?.message}
                </FormMessage>
              )}
            </FormItem>
            <FormItem className="form-item">
              <FormLabel className="form-label">{t("description")}</FormLabel>
              <FormControl>
                <Input
                  {...methods.register("description")}
                  type="search"
                  className="form-input"
                  placeholder={t("putdescription")}
                />
              </FormControl>
            </FormItem>
          </div>

          <div className="form-grid-right permissions-section">
            <input
              type="search"
              placeholder={t("Search permissions")}
              className="permission-search"
              onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
            />
            <table className="permissions-table">
              <thead>
                <tr>
                  <th>{t("Permissions")}</th>
                  <th>{t("read")}</th>
                  <th>{t("write")}</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(permissions)
                  .filter(([baseName]) =>
                    baseName.toLowerCase().includes(searchQuery)
                  )
                  .map(([baseName, perms]) => (
                    <tr key={baseName}>
                      <td>{t(baseName)}</td>
                      <td>
                        {perms.read && (
                          <input
                            type="checkbox"
                            checked={methods
                              .getValues("permissions")
                              ?.includes(perms.read.id)}
                            onChange={() =>
                              handlePermissionChange(perms.read.id)
                            }
                          />
                        )}
                      </td>
                      <td>
                        {"write" in perms && (
                          <input
                            type="checkbox"
                            checked={methods
                              .getValues("permissions")
                              ?.includes(perms.write.id)}
                            onChange={() =>
                              handlePermissionChange(perms.write.id)
                            }
                          />
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        <div style={{ display: "flex", gap: "10px", marginTop: "50px" }}>
          <Button
            type="button"
            onClick={handleDialogSubmit}
            className="button button-save"
          >
            {mode === "create" ? t("savelbtn") : t("savelbtn")}
          </Button>
          <Button
            type="button"
            onClick={() =>
              inDialog ? onClose?.() : navigate(t("/rolelistpath"))
            }
            className="button button-cancel"
          >
            {t("canclelbtn")}
          </Button>
        </div>

        <ToastContainer className="toast-container" />
      </div>
    </FormProvider>
  );
};

export default RoleForm;
