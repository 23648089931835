import BusinessRegisterForm from "./BusinessRegisterForm";

const App = () => {
    return (
       <div style={{ backgroundColor: '#4169E1', minHeight: '100vh' }}>
    {/* Your content goes here */}
    <BusinessRegisterForm mode="create" />
</div>

    );
};

export default App;
