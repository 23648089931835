import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import "@/css/MobileScreen.css";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import CreateVatDto from "@/models/CreateVatDto";
import { Switch } from "@/components/ui/switch";
import { Vat } from "@/types/VatType";
import { VatFormProps } from "@/interfaces/VatInterfaces";

import "@/css/LoadingCreate.css";


const VatForm: React.FC<VatFormProps> = ({
  mode,
  inDialog = false,
  onClose,
}) => {

  const { t } = useTranslation();



  const formSchema = z.object({
    name: z.string().min(1, { message: t("Name is required") }),
    description: z.string().optional(),
    rate: z.preprocess(
      (val) => parseFloat(val as string),
      z
        .number({
          required_error: t("Rate is required"), // Message for required field
        })
        .positive({ message: t("Vat Rate must be a positive number") }) // Message for positive number validation
    ),
      isExempt: z.boolean().optional(),
    exemptReason: z
      .object({
        code: z.string().optional(),
        reason: z.string().optional(),
      })
      .optional(),
  });
  
  type FormValues = z.infer<typeof formSchema>;
  const [loading, setLoading] = useState(false); // Initialize loading state


  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || { id: localStorage.getItem("vatId") };
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      description: "",
      rate: 0.0,
      isExempt: false,
      exemptReason: {
        code: "",
        reason: "",
      },
    },
  });


  useEffect(() => {
    if (id) {
      localStorage.setItem("vatId", id);
    }

    if (mode === "edit" && id) {
      const fetchVat = async () => {
        try {
          const response = await axios.get(
            `${API_URLS.BASE_URL}${API_URLS.VAT}/${id}`
          );
          const vatData = response.data;

          // Ensure default values for optional fields
          if (vatData.isExempt === null || vatData.isExempt === undefined) {
            vatData.isExempt = false;
          }
          if (!vatData.exemptReason) {
            vatData.exemptReason = { code: "", reason: "" };
          }

          methods.reset(vatData);
        } catch (error) {
          console.error("Error fetching VAT:", error);
        }
      };

      fetchVat();
    }
  }, [id, mode, methods]);

  const updateLocalStorage = (updatedVat: Vat) => {
    // Retrieve existing VAT data from localStorage, default to an empty array if not found
    const existingVats = JSON.parse(localStorage.getItem("vatData") || "[]");
  
    // Ensure that existingVats is an array, default to an empty array if not
    const vatsArray = Array.isArray(existingVats) ? existingVats : [];
  
    // Update the VAT data
    const updatedVats = vatsArray.map((vat: Vat) =>
      vat.id === updatedVat.id ? updatedVat : vat
    );
  
    // Save the updated VAT data back to localStorage
    localStorage.setItem("vatData", JSON.stringify(updatedVats));
  };
  

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true); // Show loading spinner

    const vatData: CreateVatDto = {
        name: data.name,
        description: data.description,
        rate: data.rate,
        isExempt: data.isExempt || false,
        exemptReason: data.isExempt
            ? {
                code: data.exemptReason?.code || "",
                reason: data.exemptReason?.reason || "",
              }
            : null,
    };

    try {
        let successMessage = "";

        if (mode === "create") {
            const response = await axios.post(
                `${API_URLS.BASE_URL}${API_URLS.VAT}`,
                vatData
            );
            successMessage = t("Vat created successfully");

            // Retrieve existing VAT data or default to an empty array
            const existingVat = JSON.parse(localStorage.getItem("vatData") || "[]");
            const vatsArray = Array.isArray(existingVat) ? existingVat : [];

            // Add the new VAT to the array
            vatsArray.push(response.data);
            localStorage.setItem("vatData", JSON.stringify(vatsArray));

            if (onClose) {
                onClose(response.data);
            }
        } else if (mode === "edit" && id) {
            const response = await axios.put(
                `${API_URLS.BASE_URL}${API_URLS.VAT}/${id}`,
                vatData
            );
            successMessage = t("Vat updated successfully");

            // Update local storage with the updated VAT
            updateLocalStorage(response.data);

            if (onClose) {
                onClose();
            }
        }

        methods.reset();
        localStorage.removeItem("vatId");

        if (!inDialog) {
            // Navigate after the form reset and localStorage cleanup
            navigate(t("/vatlistpath"));
        }

        // Show toast after navigation
        setTimeout(() => {
            if (successMessage) {
                toast.success(successMessage);
            }
        }, 250);

    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
  
        if (status === 403) {
          toast.error(t("You do not have permission to perform this action."));
        } else if (status === 500) {
          toast.error(t("A server error occurred. Please try again later."));
        } else if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorVat.${err.errorMessage}`) ||
              t("errorVat.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          toast.error(t("An error occurred"));
        }
      } else {
        toast.error(t("An unexpected error occurred"));
      }
      console.error("Error submitting VAT:", error);
    }
    finally {
      setLoading(false); // Hide loading spinner
    }
  };

  const handleDialogSubmit = () => {
    methods.handleSubmit(onSubmit)();
  };

  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center">
      {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        <div className="w-full max-w-3xl p-6 rounded shadow-md">
          <div style={{ padding: "20px" }}>
            <h1>{mode === "create" ? t("createtvat") : t("editvat")}</h1>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleDialogSubmit();
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                }}
              >
                <FormItem>
                  <FormLabel>{t("name")}<span style={{ color: "red" }}>*</span></FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("name")}
                      type="text"
                      placeholder={t("putname")}
                    />
                  </FormControl>
                  {methods.formState.errors.name && (
                    <FormMessage>
                      {methods.formState.errors.name.message}
                    </FormMessage>
                  )}
                </FormItem>

                <FormItem>
                  <FormLabel>{t("description")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("description")}
                      type="text"
                      placeholder={t("putdescription")}
                    />
                  </FormControl>
                </FormItem>

                <FormItem>
                  <FormLabel>{t("vatrate")}<span style={{ color: "red" }}>*</span></FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("rate")}
                      type="number"
                      placeholder={t("putvatnumber")}
                      step="any"
                    />
                  </FormControl>
                  {methods.formState.errors.rate && (
                    <FormMessage>
                      {methods.formState.errors.rate.message}
                    </FormMessage>
                  )}
                </FormItem>

                <FormItem>
                  <FormLabel>{t("isExempt")}</FormLabel>
                  <FormControl>
                    <Controller
                      name="isExempt"
                      control={methods.control}
                      render={({ field }) => (
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      )}
                    />
                  </FormControl>
                </FormItem>

                {methods.watch("isExempt") && (
                  <>
                    <FormItem>
                      <FormLabel>{t("code")}</FormLabel>
                      <FormControl>
                        <Input
                          {...methods.register("exemptReason.code")}
                          type="text"
                          placeholder={t("putcode")}
                        />
                      </FormControl>
                      {methods.formState.errors.exemptReason?.code && (
                        <FormMessage>
                          {methods.formState.errors.exemptReason.code.message}
                        </FormMessage>
                      )}
                    </FormItem>

                    <FormItem>
                      <FormLabel>{t("reason")}</FormLabel>
                      <FormControl>
                        <Input
                          {...methods.register("exemptReason.reason")}
                          type="text"
                          placeholder={t("putreason")}
                        />
                      </FormControl>
                      {methods.formState.errors.exemptReason?.reason && (
                        <FormMessage>
                          {methods.formState.errors.exemptReason.reason.message}
                        </FormMessage>
                      )}
                    </FormItem>
                  </>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "50px",
                }}
              >
                <Button
                  type="button"
                  onClick={handleDialogSubmit}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  {mode === "create" ? t("savelbtn") : t("savelbtn")}
                </Button>
                <Button
                  type="button"
                  onClick={() =>
                    inDialog ? onClose?.() : navigate(t("/vatlistpath"))
                  }
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                  {t("canclelbtn")}
                </Button>
              </div>
            </form>
          </div>
          <ToastContainer />
        </div>
      </div>
    </FormProvider>
  );
};

export default VatForm;
