import axios from "axios";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  ReactNode,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

interface AuthContextType {
  token: string | null;
  setToken: (token: string | null) => void;
  employeeId: string | null;
  setEmployeeId: (id: string | null) => void;
  roleId: string | null;
  setRoleId: (roleId: string | null) => void;
  isSuperUser: boolean | null;
  setIsSuperUser: (isSuperUser: boolean | null) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken_] = useState<string | null>(
    localStorage.getItem("token")
  );
  const [employeeId, setEmployeeId_] = useState<string | null>(
    localStorage.getItem("employeeId")
  );
  const [roleId, setRoleId_] = useState<string | null>(
    localStorage.getItem("roleId")
  );
  const [isSuperUser, setIsSuperUser_] = useState<boolean | null>(
    localStorage.getItem("isSuperUser") === "true" // Convert from local storage to boolean
  );
  console.log("isSuperUser:", isSuperUser);

  const navigate = useNavigate();
  const location = useLocation();

  const setToken = (newToken: string | null) => {
    setToken_(newToken);
  };

  const setEmployeeId = (newId: string | null) => {
    setEmployeeId_(newId);
  };

  const setRoleId = (newRoleId: string | null) => {
    setRoleId_(newRoleId);
  };

  const setIsSuperUser = (newIsSuperUser: boolean | null) => {
    setIsSuperUser_(newIsSuperUser);
    console.log("Updated isSuperUser:", newIsSuperUser);
  };

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      localStorage.setItem("token", token);
    } else {
      delete axios.defaults.headers.common["Authorization"];
      localStorage.removeItem("token");
    }
  }, [token]);

  useEffect(() => {
    if (employeeId) {
      localStorage.setItem("employeeId", employeeId);
    } else {
      localStorage.removeItem("employeeId");
    }
  }, [employeeId]);

  useEffect(() => {
    if (roleId) {
      localStorage.setItem("roleId", roleId);
    } else {
      localStorage.removeItem("roleId");
    }
  }, [roleId]);

  useEffect(() => {
    if (isSuperUser !== null) {
      localStorage.setItem("isSuperUser", String(isSuperUser)); // Store as string
    } else {
      localStorage.removeItem("isSuperUser");
    }
  }, [isSuperUser]);

  const decodeJWT = (token: string) => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join("")
      );
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error("Failed to decode token", error);
      return null;
    }
  };

  useEffect(() => {
    if (token) {
      const decodedToken = decodeJWT(token);
      if (decodedToken && decodedToken.exp) {
        const expirationTime = decodedToken.exp * 1000;
        const currentTime = Date.now();
        const timeLeft = expirationTime - currentTime;

        if (timeLeft <= 0) {
          setToken(null);
          setEmployeeId(null);
          setRoleId(null);
          setIsSuperUser(null);
          navigate("/login", { replace: true });
          return;
        }

        const employeeIdFromToken = decodedToken.employeeId;
        const roleIdFromToken = decodedToken.roleId;
        const isSuperUserFromToken = decodedToken.isSuperUser === "True"; // Ensure conversion to boolean

        if (employeeIdFromToken) {
          setEmployeeId(employeeIdFromToken);
        }
        if (roleIdFromToken) {
          setRoleId(roleIdFromToken);
        }
        if (typeof isSuperUserFromToken === "boolean") {
          setIsSuperUser(isSuperUserFromToken);
        }

        if (
          location.pathname === "/login" ||
          location.pathname === "/RegisterBusiness"
        ) {
          navigate("/", { replace: true });
        }
      } else {
        setToken(null);
        setEmployeeId(null);
        setRoleId(null);
        setIsSuperUser(null);
        if (location.pathname !== "/login") {
          navigate("/login", { replace: true });
        }
      }
    } else {
      if (
        location.pathname === "/login" ||
        location.pathname === "/RegisterBusiness"
      ) {
        return;
      }
      navigate("/login", { replace: true });
    }
  }, [token, navigate, location.pathname]);

  const contextValue = useMemo(
    () => ({
      token,
      setToken,
      employeeId,
      setEmployeeId,
      roleId,
      setRoleId,
      isSuperUser,
      setIsSuperUser,
    }),
    [token, employeeId, roleId, isSuperUser]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default AuthProvider;
