import React from "react";
import { BackspaceIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

interface NumericKeypadProps {
  amountReceived: string;
  onInput: (value: string) => void;
  onClear: () => void;
  onBackspace: () => void;
  onEnter: () => void;
  showDisplay?: boolean;
  showPay?: boolean;
  showPoint?: boolean;
  isPayDisabled?: boolean;
}

const NumericKeypad: React.FC<NumericKeypadProps> = ({
  amountReceived,
  onInput,
  onClear,
  onBackspace,
  onEnter,
  showDisplay = true,
  showPay = true,
  showPoint = true,
  isPayDisabled = false,
}) => {
  const { t } = useTranslation();

  const handleButtonClick = (value: string) => {
    onInput(value);
  };

  const handleBackspaceClick = () => {
    onBackspace();
  };

  const handleEnterClick = () => {
    onEnter();
  };
  const storedCurrencyDetails = localStorage.getItem("selectedCurrencyDetails");
  const currencyDetails = storedCurrencyDetails
    ? JSON.parse(storedCurrencyDetails)
    : null;
  return (
    <>
      {showDisplay && (
        <div className="display">
          {amountReceived}
          {currencyDetails?.symbol}
        </div>
      )}
      <div className="numeric-keypad">
        {Array.from({ length: 9 }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => handleButtonClick((i + 1).toString())}
          >
            {i + 1}
          </button>
        ))}
        {showPoint ? (
          <>
            <button onClick={() => handleButtonClick(".")}>.</button>
            <button onClick={() => handleButtonClick("0")}>0</button>
            <button className="btn-back" onClick={handleBackspaceClick}>
              <BackspaceIcon />
            </button>
            {showPay && (
              <button className="btn-enter" onClick={handleEnterClick}>
                {t("Pay")}
              </button>
            )}
            <button className="btn-clear" onClick={onClear}>
              {t("Clear")}
            </button>
          </>
        ) : (
          <>
            <button className="btn-clear" onClick={onClear}>
              {t("Clear")}
            </button>
            <button onClick={() => handleButtonClick("0")}>0</button>
            <button className="btn-back" onClick={handleBackspaceClick}>
              <BackspaceIcon />
            </button>
            {showPay && (
              <button
                className="btn-enter"
                onClick={handleEnterClick}
                disabled={isPayDisabled}
              >
                {t("Pay")}
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default NumericKeypad;
