import React from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@/css/LoginPage.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/provider/authProvider";
import API_URLS from "@/config";
import { jwtDecode } from "jwt-decode";

const formSchema = z.object({
  username: z.string().min(1, { message: "Username is required" }),
  password: z.string().min(1, { message: "Password is required" }),
  businessId: z.number().min(1, { message: "BusinessID is required" }),
});

type FormValues = z.infer<typeof formSchema>;

interface DecodedToken {
  employeeId: string;
}

const LoginPage: React.FC = () => {
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
      password: "",
      businessId: 1,
    },
  });

  const { t } = useTranslation();
  const { setToken, setEmployeeId } = useAuth();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    console.log("Submitting form with data:", data);

    const requestData = {
      ...data,
      userType: "employee", // Set userType directly in the request data
    };
    try {
      const response = await axios.post(
        `${API_URLS.BASE_URL}${API_URLS.LOGIN}`,
        requestData
      );
      console.log("Response from server:", response);
      if (response.status === 200) {
        const token = response.data.token;
        setToken(token);

        const decodedToken: DecodedToken = jwtDecode<DecodedToken>(token);
        const employeeId = decodedToken.employeeId;
        console.log("Decoded token:", decodedToken);
        console.log("Employee ID:", employeeId);

        setEmployeeId(employeeId);

        // Save businessConfigs to local storage
        const businessConfigs = response.data.businessConfigs; // Assuming the API returns this
        localStorage.setItem('businessConfigs', JSON.stringify(businessConfigs));

        toast.success(t("Login successful"));
        navigate("/", { replace: true });
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { data } = error.response;
        if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage = t(`errorLogin.${err.errorMessage}`);
            toast.error(translatedMessage);
          });
        } else {
          const defaultMessage = t("Invalid username or password");
          toast.error(defaultMessage);
        }
      } else {
        const unexpectedMessage = t("An unexpected error occurred");
        toast.error(unexpectedMessage);
      }
    }

  };


  return (
    <FormProvider {...methods}>
      <div className="login-container">
        <div className="login-form">
          <div className="avatar">👤</div>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormItem>
              <FormLabel className="text-black dark:text-black">
                {t("username")}
              </FormLabel>
              <FormControl>
                <Input
                  {...methods.register("username")}
                  type="text"
                  className="input"
                  placeholder={t("enterUsername")}
                />
              </FormControl>
              {methods.formState.errors.username && (
                <FormMessage>
                  {methods.formState.errors.username.message}
                </FormMessage>
              )}
            </FormItem>

            <FormItem>
              <FormLabel className="text-black dark:text-black">
                {t("password")}
              </FormLabel>
              <FormControl>
                <Input
                  {...methods.register("password")}
                  type="password"
                  className="input"
                  placeholder={t("enterPassword")}
                />
              </FormControl>
              {methods.formState.errors.password && (
                <FormMessage>
                  {methods.formState.errors.password.message}
                </FormMessage>
              )}
            </FormItem>

            <FormItem>
              <FormLabel className="text-black dark:text-black">
                Bussiness ID
              </FormLabel>
              <FormControl>
               <Input
  {...methods.register("businessId", { valueAsNumber: true })} // Automatically converts to a number
  type="number"
  className="input"
  placeholder="Business ID"
/>
              </FormControl>
              {methods.formState.errors.businessId && (
                <FormMessage>
                  {methods.formState.errors.businessId.message}
                </FormMessage>
              )}
            </FormItem>

            <Button type="submit" className="button">
              {t("login")}
            </Button>
          </form>
          <ToastContainer />
        </div>
      </div>
    </FormProvider>
  );
};

export default LoginPage;
