import PurchaseStatus from "./PurchaseStatus";

class CreatePurchaseOrderDto {
  deliveryDateUtc: string;
  purchaseStatus?: PurchaseStatus;
  notes?: string;
  supplierId?: number;
  vatAmount?: number;
  customCharges?: number;
  shippingCharges?: number;
  adjustedCharges?: number;
  totalCost: number;
  amountDue?: number;
  warehouseId?: number;
  shopId?: number;
  purchaseOrderLines: {
    productId: number;
    price: number;
    quantity: number;
    uoMId: number;
    lotId: number;
    lineVatAmount?: number;
    lineVatRateId?: number;
    lineTotal: number;
    lineNote?: string;
  }[];

  constructor(order: {
    deliveryDateUtc: string;
    purchaseStatus?: PurchaseStatus;
    notes?: string;
    supplierId?: number;
    vatAmount?: number;
    customCharges?: number;
    shippingCharges?: number;
    adjustedCharges?: number;
    totalCost: number;
    amountDue?: number;
    warehouseId?: number;
    shopId?: number;
    purchaseOrderLines: {
      productId: number;
      price: number;
      quantity: number;
      uoMId: number;
      lotId: number;
      lineVatAmount?: number;
      lineVatRateId?: number;
      lineTotal: number;
      lineNote?: string;
    }[];
  }) {
    this.deliveryDateUtc = order.deliveryDateUtc;
    this.purchaseStatus = order.purchaseStatus;
    this.notes = order.notes;
    this.supplierId = order.supplierId;
    this.vatAmount = order.vatAmount;
    this.customCharges = order.customCharges;
    this.shippingCharges = order.shippingCharges;
    this.adjustedCharges = order.adjustedCharges;
    this.totalCost = order.totalCost;
    this.amountDue = order.amountDue;
    this.warehouseId = order.warehouseId;
    this.shopId = order.shopId;
    this.purchaseOrderLines = order.purchaseOrderLines;
  }
}

export default CreatePurchaseOrderDto;
