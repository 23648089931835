import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import "@/css/loading.css";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ArrowUpDown, ChevronDown, Filter, MoreHorizontal } from "lucide-react";
import { Square3Stack3DIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { Paymentmethods } from "@/types/PaymentMethdosType";
import * as ExcelJS from "exceljs";
import DataFetcher from "@/provider/DataFetcher";
import { EmployeeType } from "@/types/EmployeeType";

const PaymentMethod_STORAGE_KEY = "paymentmethodsData";
const EMPLOYEE_STORAGE_KEY = "employeeData";

const PaymentmethodsList = () => {
  const [data, setData] = useState<Paymentmethods[]>([]);
  const [loading, setLoading] = useState(true);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "id", desc: true },
  ]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    id: false,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  type RowSelection = Record<string, boolean>;

  const [rowSelection, setRowSelection] = useState<RowSelection>({});
  const { t } = useTranslation();
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await DataFetcher.getData<Paymentmethods[]>(
          `${API_URLS.BASE_URL}${API_URLS.PaymentMethod}`,
          PaymentMethod_STORAGE_KEY,
          true
        );
        const paymentMethods = response || [];
        const fetchEmployees = async (): Promise<EmployeeType[]> => {
          try {
            return (
              (await DataFetcher.getData<EmployeeType[]>(
                `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}`,
                EMPLOYEE_STORAGE_KEY
              )) || []
            );
          } catch (error) {
            console.error("Error fetching employees:", error);
            return [];
          }
        };

        // Fetch employees for mapping createdBy and updatedBy
        const employees = await fetchEmployees();
        const employeeMap = employees.reduce<Record<number, string>>(
          (acc, employee) => {
            acc[employee.id] = employee.name;
            return acc;
          },
          {}
        );

        // Map createdBy and updatedBy to employee names
        const updatedLpaymentMethods = paymentMethods.map((paymentMethod) => ({
          ...paymentMethod,
          createdByName: paymentMethod.createdBy
            ? employeeMap[paymentMethod.createdBy] || ""
            : "",
          updatedByName: paymentMethod.updatedBy
            ? employeeMap[paymentMethod.updatedBy] || ""
            : "",
        }));
        setData(updatedLpaymentMethods);
        localStorage.setItem(
          PaymentMethod_STORAGE_KEY,
          JSON.stringify(response)
        );
      } catch (error) {
        console.error("Error fetching payment methods:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleEdit = (id: string) => {
    navigate(t("/editpaymentmethodpath"), { state: { id: id } });
  };

  const handleDelete = async (id: string) => {
    try {
      await axios.delete(`${API_URLS.BASE_URL}${API_URLS.PaymentMethod}/${id}`);
      const updatedData = data.filter(
        (paymentmethods) => paymentmethods.id !== id
      );
      setData(updatedData);
      localStorage.setItem(
        PaymentMethod_STORAGE_KEY,
        JSON.stringify(updatedData)
      );
      toast.success(t("Payment method deleted successfully"));
    } catch (error) {
      console.error("Error deleting payment method:", error);
      toast.error(t("Error deleting payment method"));
    }
  };

  const handleDeleteSelected = async () => {
    // Get selected row IDs from rowSelection
    const selectedRowIds = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    // Extract employee IDs to delete based on selected rows
    const paymentmethodToDelete = data
      .filter((_item, index) => selectedRowIds.includes(index.toString())) // Adjust this if rowSelection keys are different
      .map((item) => item.id); // Assuming `item.id` is the employee ID

    if (paymentmethodToDelete.length === 0) {
      toast.error(t("Please select records to delete."));
      return;
    }

    try {
      // Delete selected employees from the API
      await Promise.all(
        paymentmethodToDelete.map((id) =>
          axios.delete(`${API_URLS.BASE_URL}${API_URLS.PaymentMethod}/${id}`)
        )
      );

      // Remove deleted employees from the state
      const updatedData = data.filter(
        (item) => !paymentmethodToDelete.includes(item.id)
      );
      setData(updatedData);
      setRowSelection({});

      // Update local storage with the updated data
      localStorage.setItem(
        PaymentMethod_STORAGE_KEY,
        JSON.stringify(updatedData)
      );

      toast.success(t("Selected records deleted successfully."));
    } catch (error) {
      console.error("Failed to delete records:", error);
      toast.error(t("Failed to delete selected records."));
    }
  };

  const handleADD = () => {
    navigate(t("/createpaymentmethodpath"));
  };

  useEffect(() => {
    if (!loading && location.state?.showToast) {
      toast.success(location.state.message);
    }
  }, [loading, location.state]);

  const exportToExcel = async () => {
    // Get the selected rows or use the full data if no rows are selected
    const selectedRows = table
      .getFilteredSelectedRowModel()
      .rows.map((row) => row.original);
    const rowsToExport = selectedRows.length > 0 ? selectedRows : data;

    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("PaymentMethods");

    // Define columns based on the data structure
    worksheet.columns = [
      // { header: 'ID', key: 'id', width: 10 },
      { header: "Name", key: "name", width: 30 },
      { header: "IsCash", key: "isCash", width: 30 },
      { header: "Code", key: "code", width: 30 },
    ];

    // Map rowsToExport to match the worksheet columns
    const worksheetData = rowsToExport.map((paymentmethods) => ({
      name: paymentmethods.name,
      isCash: paymentmethods.isCash ? "Yes" : "No",
      code: paymentmethods.code,
    }));

    // Add rows to worksheet
    worksheet.addRows(worksheetData);

    // Generate Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(
      new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "paymentmethods.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns: ColumnDef<Paymentmethods>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "id",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("ID")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("id")}</div>,
    },
    {
      accessorKey: "name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("name")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("name")}</div>,
    },
    {
      accessorKey: "isCash",
      header: t("isCash"),
      cell: ({ row }) => <div>{row.getValue("isCash") ? "Yes" : "No"}</div>,
    },
    {
      accessorKey: "code",
      header: t("code"),
      cell: ({ row }) => <div>{row.getValue("code")}</div>,
    },
    {
      accessorKey: "createdByName",
      header: t("createdBy"),
      cell: ({ row }) => <div>{row.getValue("createdByName")}</div>,
    },
    {
      accessorKey: "updatedByName",
      header: t("updatedBy"),
      cell: ({ row }) => <div>{row.getValue("updatedByName")}</div>,
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4 icon" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>{t("action")}</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => handleEdit(row.original.id)}>
              {t("edit")}
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => handleDelete(row.original.id)}>
              {t("delete")}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }
  return (
    <div className="w-full">
      <div className="flex flex-wrap items-center py-4">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
               <Button variant="outline" className="mr-10 flex items-center">
  <Square3Stack3DIcon className="h-5 w-5" />
  <span className="hidden sm:flex sm:items-center">
    {t("action")} <ChevronDown className="ml-2 h-4 w-4" />
  </span>
</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={exportToExcel}>
              {t("export")}
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleDeleteSelected}>
              {t("delete")}
            </DropdownMenuItem>{" "}
          </DropdownMenuContent>
        </DropdownMenu>
        <Input
          placeholder={t("filtername")}
          value={(table.getColumn("name")?.getFilterValue() as string) ?? ""}
          onChange={(event) =>
            table.getColumn("name")?.setFilterValue(event.target.value)
          }
          style={{
            width: window.innerWidth <= 999 ? "45%" : "100%",
          }}
          className="max-w-sm"
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
             <Button variant="outline" className="ml-auto flex items-center justify-center">
  <span className="hidden md:block ">{t("columns")}</span>
  <Filter className="md:ml-2 h-4 w-4 mx-auto" />
</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => (
                <DropdownMenuCheckboxItem
                  key={column.id}
                  className="capitalize"
                  checked={column.getIsVisible()}
                  onCheckedChange={(value) => column.toggleVisibility(!!value)}
                >
                  {column.id}
                </DropdownMenuCheckboxItem>
              ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onDoubleClick={() => handleEdit(row.original.id)}
                  style={{ cursor: "pointer" }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {t("noresult")}{" "}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{" "}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {t("previous")}
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {t("next")}
          </Button>
        </div>
      </div>
      <div className="fixed bottom-4 right-4 md:bottom-4 md:right-4">
        <Button
          type="button"
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={handleADD}
        >
          +
        </Button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PaymentmethodsList;
