import React, { useState, useRef, useEffect } from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import NumericKeypad from "./numericKeyboard";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import InvoiceThermalPrint from "./InvocieThermalPrint";
import "@/css/dialogBoxCheckout.css";
import { Product } from "@/types/ProductType";
import DataFetcher from "@/provider/DataFetcher";
import API_URLS from "@/config";
import { ExchangeRate } from "@/types/ExchangeRateType";

interface CheckoutDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  totalAmount: number;
  onSubmit: (
    amountReceived: number,
    exchangeRate: number,
    selectedCurrencyDetails?: {
      id: number;
      name: string;
      symbol: string;
    },
    printInvoice?: () => void
  ) => void;

  orderProducts: {
    product: Product;
    quantity: number;
    discount?: number;
    discountType?: "value" | "percentage";
  }[];
  customers: { id: string; name: string }[];
  selectedCustomer: string;
  calculateSubtotalAndDiscount: () => {
    subtotal: string;
    totalOrderDiscount: string;
    totalProductDiscounts: string;
    totalDiscountSum: string;
  };
  calculateVAT: () => string;
  calculateTotal: () => string;
  getVATRateName: (vatRateId: number) => string;
  getVATRate: (vatRateId: number) => number;
  onTotalDiscountChange: (
    discount: number,
    discountType: "value" | "percentage"
  ) => void;
}
const CheckoutDialog: React.FC<CheckoutDialogProps> = ({
  open,
  onOpenChange,
  totalAmount,
  onSubmit,
  orderProducts,
  customers,
  selectedCustomer,
  calculateSubtotalAndDiscount,
  calculateVAT,
  calculateTotal,
  getVATRateName,
  getVATRate,
}) => {
  const { t } = useTranslation();
  const [amountReceived, setAmountReceived] = useState<string>("");
  const [currencyList, setCurrencyList] = useState<any[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<string>("1");
  const [exchangeRate, setExchangeRate] = useState<number>(1);
  const [selectedCurrencyDetails, setSelectedCurrencyDetails] = useState<{
    id: number;
    name: string;
    symbol: string;
  }>({ id: 1, name: "Lekë", symbol: "L" });
  const invoiceRef = useRef<HTMLDivElement>(null);
  const currencySymbol = "L";

  const ExchangeRate_STORAGE_KEY = "exchangerateData";

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const response = await DataFetcher.getData<any[]>(
          `${API_URLS.BASE_URL}${API_URLS.Currency}`,
          "currencyData"
        );
        setCurrencyList(response ?? []);
      } catch (error) {
        console.error("Error fetching currencies:", error);
      }
    };

    const initializeSelectedCurrency = () => {
      const storedCurrencyDetails = localStorage.getItem(
        "selectedCurrencyDetails"
      );
      if (!storedCurrencyDetails) {
        const defaultCurrencyDetails = { id: 1, name: "Lekë", symbol: "L" };
        setSelectedCurrencyDetails(defaultCurrencyDetails);
      } else {
        setSelectedCurrencyDetails(JSON.parse(storedCurrencyDetails));
      }
    };

    fetchCurrencies();
    initializeSelectedCurrency();
  }, []);

  useEffect(() => {
    if (selectedCurrency) {
      fetchExchangeRate(selectedCurrency);
    }
  }, [selectedCurrency]);

  const fetchExchangeRate = async (currencyId: string) => {
    if (currencyId === "1") {
      setExchangeRate(1);
      const currencyDetails = { id: 1, name: "Lekë", symbol: "L" };
      setSelectedCurrencyDetails(currencyDetails);
      localStorage.setItem(
        "selectedCurrencyDetails",
        JSON.stringify(currencyDetails)
      ); // Update localStorage for Leke
      return;
    }

    try {
      const exchangeRates = await DataFetcher.getData<ExchangeRate[]>(
        `${API_URLS.BASE_URL}${API_URLS.ExchangeRates}`,
        ExchangeRate_STORAGE_KEY
      );
      const foundCurrency = currencyList.find(
        (currency) => String(currency.id) === currencyId
      );

      if (foundCurrency) {
        const currencyDetails = {
          id: foundCurrency.id,
          name: foundCurrency.name,
          symbol: foundCurrency.symbol,
        };
        setSelectedCurrencyDetails(currencyDetails);
        localStorage.setItem(
          "selectedCurrencyDetails",
          JSON.stringify(currencyDetails)
        );
      }
      if (exchangeRates && exchangeRates.length > 0) {
        const exchangeRate = exchangeRates.find(
          (rate) =>
            rate.currency2Id === 1 && rate.currency1Id === Number(currencyId)
        );

        if (exchangeRate) {
          setExchangeRate(exchangeRate.rate);
          localStorage.setItem(
            "exchangeRate",
            JSON.stringify(exchangeRate.rate)
          );
        }
      }
    } catch (error) {
      console.error("Error fetching exchange rates:", error);
    }
  };

  const calculateTotalInSelectedCurrency = () => {
    if (!exchangeRate) return "N/A";
    return (totalAmount / exchangeRate).toFixed(2);
  };

  const handlePay = () => {
    const amount = parseFloat(amountReceivedNewCurr);
    const amountReceived1 = parseFloat(amountReceived);
    if (!isNaN(amount) && amount >= totalAmount) {
      console.log("Exchange Rate before submit:", exchangeRate); // Log to check exchangeRate before submission
      onSubmit(
        amountReceived1,
        exchangeRate,
        selectedCurrencyDetails,
        handlePrint
      );
      // Ensure the right props are passed here
      setAmountReceived("");
      onOpenChange(false); // This may trigger a re-render, so ensure state is preserved
    }
  };

  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
  });

  const handleCurrencyChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedValue = event.target.value;
    setSelectedCurrency(selectedValue);

    // Fetch exchange rate and set currency details
    fetchExchangeRate(selectedValue);
  };
  const amountReceivedNewCurr = (
    parseFloat(amountReceived) * exchangeRate
  ).toFixed(2);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="custom-dialog-content">
        <div className="table">
          <h2>
            {t("TotalAmount Lek")}: {totalAmount?.toFixed(2) || "0.00"}
          </h2>
          {selectedCurrency !== "1" && (
            <h2>
              {t("Total in")} {selectedCurrencyDetails?.name || "Unknown"}:{" "}
              {calculateTotalInSelectedCurrency()}{" "}
              {selectedCurrencyDetails?.symbol || ""}
            </h2>
          )}
        </div>

        <div>
          <label>{t("SelectCurrency")}</label>
          <select value={selectedCurrency} onChange={handleCurrencyChange}>
            <option value="">{t("Select")}</option>
            {currencyList.map((currency) => (
              <option key={currency.id} value={currency.id}>
                {currency.name}
              </option>
            ))}
          </select>
          {exchangeRate !== 1 && (
            <p>
              {t("ExchangeRate")}: {exchangeRate}
            </p>
          )}
        </div>

        <div className="text-xl">
          <h3>
            {t("AmountReceived")}: {amountReceivedNewCurr} {currencySymbol}
          </h3>
          <h3>
            {t("ChangeAmount")}:{" "}
            {(parseFloat(amountReceivedNewCurr) - totalAmount).toFixed(2)}{" "}
            {currencySymbol}
          </h3>
        </div>

        <NumericKeypad
          amountReceived={amountReceived}
          onInput={(value) => setAmountReceived((prev) => prev + value)}
          onClear={() => setAmountReceived("")}
          onBackspace={() => setAmountReceived((prev) => prev.slice(0, -1))}
          onEnter={handlePay}
        />
      </DialogContent>

      <div style={{ display: "none" }}>
        <InvoiceThermalPrint
          ref={invoiceRef}
          orderProducts={orderProducts}
          customers={customers}
          selectedCustomer={selectedCustomer}
          calculateSubtotalAndDiscount={calculateSubtotalAndDiscount}
          calculateVAT={calculateVAT}
          calculateTotal={calculateTotal}
          getVATRateName={getVATRateName}
          getVATRate={getVATRate}
          selectedCurrencyDetails={selectedCurrencyDetails}
          totalInSelectedCurrency={calculateTotalInSelectedCurrency()}
          exchangeRate={exchangeRate}
          employeeName={""}
          orderNo={undefined}
          tableId={undefined}
          tablename={undefined}
          printerType={0}
        />
      </div>
    </Dialog>
  );
};

export default CheckoutDialog;
