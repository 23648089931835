import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import axios from "axios";
import API_URLS from "@/config";
import { useTranslation } from "react-i18next";
import DataFetcher from "@/provider/DataFetcher";
import { CashAccount } from "@/types/CashAccountType";
import { toast, ToastContainer } from "react-toastify";

interface DialogBoxCheckoutBankProps {
  employeeID: string | null;
  onSave: (cashAccountId: number, paymentTermId: number) => void;
  open: boolean;
  onClose: () => void;
  onPrint: () => void;
}

const DialogBoxCheckoutBank: React.FC<DialogBoxCheckoutBankProps> = ({
  employeeID,
  onSave,
  open,
  onClose,
  onPrint,
}) => {
  const [cashAccounts, setCashAccounts] = useState<
    { id: number; name: string; balance: number }[]
  >([]);
  const [selectedCashAccount, setSelectedCashAccount] = useState<number | null>(
    null
  );
  const [paymentTerms, setPaymentTerms] = useState<
    { id: number; name: string }[]
  >([]);
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState<number | null>(
    null
  );
  const { t } = useTranslation();

  const CashAccount_STORAGE_KEY = "cashAccountData";

  useEffect(() => {
    console.log(employeeID);
    if (employeeID !== null) {
      const parsedEmployeeId = parseInt(employeeID, 10);
      if (!isNaN(parsedEmployeeId)) {
        const fetchCashAccounts = async () => {
          try {
            const response = await DataFetcher.getData<CashAccount[] | null>(
              `${API_URLS.BASE_URL}${API_URLS.CashAccount}`,
              CashAccount_STORAGE_KEY,
              true
            );

            if (response && Array.isArray(response)) {
              const ownedCashAccounts = response
                .filter(
                  (account) =>
                    account.owners.some(
                      (owner: { id: number }) => owner.id === parsedEmployeeId
                    ) && account.isBank === true
                )
                .map((account) => ({
                  id: parseInt(account.id),
                  name: account.name,
                  balance: account.balance || 0,
                }));

              console.log(ownedCashAccounts);
              setCashAccounts(ownedCashAccounts);
            }
          } catch (error) {
            toast.error("Failed to fetch cash accounts");
            console.error(error);
          }
        };

        fetchCashAccounts();
      }
    }
  }, [employeeID]);

  useEffect(() => {
    const fetchPaymentTerms = async () => {
      try {
        const response = await axios.get<{ id: number; name: string }[]>(
          `${API_URLS.BASE_URL}${API_URLS.PaymentTerms}`
        );
        setPaymentTerms(response.data);
      } catch (error) {
        console.error("Error fetching payment terms:", error);
      }
    };

    fetchPaymentTerms();
  }, []);

  const handleSave = () => {
    if (selectedCashAccount !== null && selectedPaymentTerm !== null) {
      onSave(selectedCashAccount, selectedPaymentTerm);
      onPrint(); // Trigger print operation
      onClose();
    } else {
      console.log("Please select both cash account and payment term");
    }
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <h2>{t("Select Cash Account and Payment Term")}</h2>
        <div>
          <label>{t("Cash Account")}</label>
          <select
            value={selectedCashAccount || ""}
            onChange={(e) => setSelectedCashAccount(parseInt(e.target.value))}
          >
            <option value="" disabled>
              {t("Select Cash Account")}
            </option>
            {cashAccounts.map((account) => (
              <option key={account.id} value={account.id}>
                {account.name} - {account.balance.toFixed(2)} {t("Currency")}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>{t("Payment Term")}</label>
          <select
            value={selectedPaymentTerm || ""}
            onChange={(e) => setSelectedPaymentTerm(parseInt(e.target.value))}
          >
            <option value="" disabled>
              {t("Select Payment Term")}
            </option>
            {paymentTerms.map((term) => (
              <option key={term.id} value={term.id}>
                {term.name}
              </option>
            ))}
          </select>
        </div>
        <Button onClick={handleSave}>{t("Save and Print")}</Button>
      </DialogContent>
      <ToastContainer />
    </Dialog>
  );
};

export default DialogBoxCheckoutBank;
