import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import "@/css/MobileScreen.css";
import CreateWarehouseDto from "@/models/CreateWarehouseDto";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { Warehouse } from "@/types/WarehouseType";
import { Shop } from "@/interfaces/ShopInterfaces";
import { WarehouseFormProps } from "@/interfaces/WarehouseInterface";
import DataFetcher from "@/provider/DataFetcher";

import "@/css/LoadingCreate.css";


const WarehouseForm: React.FC<WarehouseFormProps> = ({ mode }) => {

  const { t } = useTranslation();

  const formSchema = z.object({
    shopId: z.string().min(1, { message: t("Shop is required") }),
    name: z.string().min(1, { message: t("Name is required") }),
    address: z.string().optional(),
  });
  
  type FormValues = z.infer<typeof formSchema>;
  const [loading, setLoading] = useState(false); // Initialize loading state


  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || { id: localStorage.getItem("warehouseId") };
  const [shops, setShops] = useState<Shop[]>([]);
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      shopId: "",
      name: "",
      address: "",
    },
  });

  const SHOP_STORAGE_KEY = "shopData";

  useEffect(() => {
    const fetchShops = async () => {
      try {
        const response = await DataFetcher.getData<Shop[]>(
          `${API_URLS.BASE_URL}${API_URLS.SHOP}`,
          SHOP_STORAGE_KEY
        );
        if (response) {
          setShops(response);
        }
      } catch (error) {
        console.error("Error fetching shops:", error);
      }
    };

    fetchShops();

    if (id) {
      localStorage.setItem("warehouseId", id);
    }

    if (mode === "edit" && id) {
      const fetchWarehouse = async () => {
        try {
          const response = await axios.get(
            `${API_URLS.BASE_URL}${API_URLS.WAREHOUSE}/${id}`
          );
          methods.reset(response.data);
        } catch (error) {
          console.error("Error fetching Warehouse:", error);
        }
      };

      fetchWarehouse();
    }
  }, [id, mode, methods]);

  const updateLocalStorage = (updatedWarehouse: Warehouse) => {
    const existingWarehouses = JSON.parse(
      localStorage.getItem("warehouseData") || "[]"
    );
    const updatedWarehouses = existingWarehouses.map((warehouse: Warehouse) =>
      warehouse.id === updatedWarehouse.id ? updatedWarehouse : warehouse
    );
    localStorage.setItem("warehouseData", JSON.stringify(updatedWarehouses));
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true); // Show loading spinner

    const warehouseData = new CreateWarehouseDto({
      shopId: parseInt(data.shopId),
      name: data.name,
      address: data.address,
    });
  
    try {
      let successMessage = "";
  
      if (mode === "create") {
        const response = await axios.post(
          `${API_URLS.BASE_URL}${API_URLS.WAREHOUSE}`,
          warehouseData
        );
        console.log("Warehouse created successfully:", response.data);
        successMessage = t("Warehouse created successfully");
  
        const existingWarehouses = JSON.parse(
          localStorage.getItem("warehouseData") || "[]"
        );
        existingWarehouses.push(response.data);
        localStorage.setItem("warehouseData", JSON.stringify(existingWarehouses));
      } else if (mode === "edit" && id) {
        const response = await axios.put(
          `${API_URLS.BASE_URL}${API_URLS.WAREHOUSE}/${id}`,
          warehouseData
        );
        console.log("Warehouse updated successfully:", response.data);
        successMessage = t("Warehouse updated successfully");
  
        updateLocalStorage(response.data);
      }
  
      methods.reset();
      localStorage.removeItem("warehouseId");
      navigate(t("/warehouselistpath"));
  
      // Show toast after navigation
      setTimeout(() => {
        if (successMessage) {
          toast.success(successMessage);
        }
      }, 250);
  
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
  
        if (status === 403) {
          toast.error(t("You do not have permission to perform this action."));
        } else if (status === 500) {
          toast.error(t("A server error occurred. Please try again later."));
        } else if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorWarehouse.${err.errorMessage}`) ||
              t("errorWarehouse.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          const defaultMessage = t("An error occurred");
          toast.error(defaultMessage);
        }
      } else {
        const unexpectedMessage = t("An unexpected error occurred");
        toast.error(unexpectedMessage);
      }
      console.error("Error submitting Warehouse:", error);
    } 
    finally {
      setLoading(false); // Hide loading spinner
    }
  };
  
  

  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center">
      {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        <div className="w-full max-w-3xl p-6 rounded shadow-md">
          <div style={{ padding: "20px" }}>
            <h1>
              {mode === "create" ? t("createwarehouse") : t("editwarehouse")}
            </h1>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                }}
              >
                <FormItem>
                  <FormLabel>{t("shopId")}<span style={{ color: "red" }}>*</span></FormLabel>
                  <FormControl>
                    <Controller
                      name="shopId"
                      control={methods.control}
                      render={({ field }) => (
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder={t("selectShop")} />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectLabel>{t("shop")}</SelectLabel>
                              {shops.map((shop) => (
                                <SelectItem
                                  key={shop.id}
                                  value={shop.id.toString()}
                                >
                                  {shop.name}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      )}
                    />
                  </FormControl>
                  {methods.formState.errors.shopId && (
                    <FormMessage>
                      {methods.formState.errors.shopId.message}
                    </FormMessage>
                  )}
                </FormItem>
                <FormItem>
                  <FormLabel>{t("name")}<span style={{ color: "red" }}>*</span></FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("name")}
                      type="search"
                      placeholder={t("putname")}
                    />
                  </FormControl>
                  {methods.formState.errors.name && (
                    <FormMessage>
                      {methods.formState.errors.name.message}
                    </FormMessage>
                  )}
                </FormItem>
                <FormItem>
                  <FormLabel>{t("address")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("address")}
                      type="search"
                      placeholder={t("putaddress")}
                    />
                  </FormControl>
                  {methods.formState.errors.address && (
                    <FormMessage>
                      {methods.formState.errors.address.message}
                    </FormMessage>
                  )}
                </FormItem>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "50px",
                }}
              >
                <Button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                  {mode === "create" ? t("savelbtn") : t("savelbtn")}
                </Button>
                <Button
                  type="button"
                  onClick={() => navigate(t("/warehouselistpath"))}
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                  {t("canclelbtn")}
                </Button>
              </div>
            </form>
          </div>
          <ToastContainer />
        </div>
      </div>
    </FormProvider>
  );
};

export default WarehouseForm;
