import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import axios from "axios";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ArrowUpDown, ChevronDown, Filter, MoreHorizontal } from "lucide-react";
import { Square3Stack3DIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@/css/loading.css";
import API_URLS from "@/config";
import { CustomerCategoryType } from "@/types/CustomerCategory";
import { Customer } from "@/types/CustomerType";
import * as ExcelJS from "exceljs";
import CreateCustomerDto from "@/models/CreateCustomerDto";
import DataFetcher from "@/provider/DataFetcher";
import { EmployeeType } from "@/types/EmployeeType";

const CUSTOMER_STORAGE_KEY = "customerData";
const CUSTOMERCAT_STORAGE_KEY = "customerCatData";
const EMPLOYEE_STORAGE_KEY = "employeeData";
// const CUSTOMER_TIMESTAMP_KEY = "customerTimestamp";
// const EXPIRATION_TIME = 0 * 60 * 1000;

const CustomerList: React.FC = () => {
  const [data, setData] = useState<Customer[]>([]);
  const [loading, setLoading] = useState(true);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "id", desc: true },
  ]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    id: false,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  type RowSelection = Record<string, boolean>;

  const [rowSelection, setRowSelection] = useState<RowSelection>({});
  const [globalFilter, setGlobalFilter] = useState<string>("");

  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [customerResponse, categories] = await Promise.all([
          DataFetcher.getData<Customer[]>(
            `${API_URLS.BASE_URL}${API_URLS.CUSTOMER}`,
            CUSTOMER_STORAGE_KEY,
            true
          ),
          DataFetcher.getData<CustomerCategoryType[]>(
            `${API_URLS.BASE_URL}${API_URLS.CUSTOMER_CATEGORY}`,
            CUSTOMERCAT_STORAGE_KEY
          ),
        ]);
        const customers: Customer[] = customerResponse || [];
        localStorage.setItem(CUSTOMER_STORAGE_KEY, JSON.stringify(customers));
        if (!categories) {
          console.error("Failed to fetch categories data.");
          return;
        }

        const categoryMap = categories.reduce<Record<number, string>>(
          (acc, category) => {
            acc[category.id] = category.name;
            return acc;
          },
          {}
        );
        const fetchEmployees = async (): Promise<EmployeeType[]> => {
          try {
            return (
              (await DataFetcher.getData<EmployeeType[]>(
                `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}`,
                EMPLOYEE_STORAGE_KEY
              )) || []
            );
          } catch (error) {
            console.error("Error fetching employees:", error);
            return [];
          }
        };

        // Fetch employees for mapping createdBy and updatedBy
        const employees = await fetchEmployees();
        const employeeMap = employees.reduce<Record<number, string>>(
          (acc, employee) => {
            acc[employee.id] = employee.name;
            return acc;
          },
          {}
        );

        const updatedCustomers = customers.map((customer) => ({
          ...customer,
          categoryName: categoryMap[customer.customerCategoryId],
          createdByName: customer.createdBy
            ? employeeMap[customer.createdBy] || ""
            : "",
          updatedByName: customer.updatedBy
            ? employeeMap[customer.updatedBy] || ""
            : "",
        }));
        setData(updatedCustomers);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleEdit = (customerId: string) => {
    navigate(t("/editcutomerpath"), { state: { id: customerId } });
  };
  const handleDeleteSelected = async () => {
    // Get selected row IDs from rowSelection
    const selectedRowIds = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    // Extract employee IDs to delete based on selected rows
    const customerIdsToDelete = data
      .filter((_item, index) => selectedRowIds.includes(index.toString())) // Adjust this if rowSelection keys are different
      .map((item) => item.id); // Assuming `item.id` is the employee ID

    if (customerIdsToDelete.length === 0) {
      toast.error(t("Please select records to delete."));
      return;
    }

    try {
      // Delete selected employees from the API
      await Promise.all(
        customerIdsToDelete.map((id) =>
          axios.delete(`${API_URLS.BASE_URL}${API_URLS.CUSTOMER}/${id}`)
        )
      );

      // Remove deleted employees from the state
      const updatedData = data.filter(
        (item) => !customerIdsToDelete.includes(item.id)
      );
      setData(updatedData);
      // Clear the row selection to prevent automatic selection of nearby rows
      setRowSelection({});

      // Update local storage with the updated data
      localStorage.setItem(CUSTOMER_STORAGE_KEY, JSON.stringify(updatedData));

      toast.success(t("Selected records deleted successfully."));
    } catch (error) {
      console.error("Failed to delete records:", error);
      toast.error(t("Failed to delete selected records."));
    }
  };

  const handleDelete = async (customerId: string) => {
    try {
      await axios.delete(
        `${API_URLS.BASE_URL}${API_URLS.CUSTOMER}/${customerId}`
      );
      const updatedData = data.filter((customer) => customer.id !== customerId);
      setData(updatedData);
      localStorage.setItem(CUSTOMER_STORAGE_KEY, JSON.stringify(updatedData));
      toast.success(t("Customer deleted successfully"));
    } catch (error) {
      console.error("Error deleting customer:", error);
      toast.error(t("Error deleting customer"));
    }
  };

  const handleADD = () => {
    navigate(t("/createcustomerpath"));
  };
  const exportToExcel = async () => {
    // Get the selected rows or use the full data if no rows are selected
    const selectedRows = table
      .getFilteredSelectedRowModel()
      .rows.map((row) => row.original);
    const rowsToExport = selectedRows.length > 0 ? selectedRows : data;

    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Customers");

    // Define columns based on the data structure
    worksheet.columns = [
      // { header: 'ID', key: 'id', width: 20 },
      { header: "Name", key: "name", width: 30 },
      { header: "Phone Number", key: "phoneNumber", width: 20 },
      { header: "Email Address", key: "email", width: 30 },
      { header: "Date of Birth", key: "dateOfBirth", width: 15 },
      { header: "Username", key: "username", width: 20 },
      { header: "Password", key: "password", width: 20 },
      { header: "Category Name", key: "categoryName", width: 30 },

      // { header: 'Customer Category ID', key: 'customerCategoryId', width: 20 },
      { header: "Loyalty Card", key: "loyaltyCard", width: 20 },
      { header: "Discount", key: "discount", width: 15 },
      { header: "Loyalty Points", key: "loyaltyPoints", width: 20 },
      { header: "ID Number", key: "idNumber", width: 20 },
      { header: "ID Type", key: "idType", width: 15 },
      { header: "Address", key: "address", width: 40 },
      { header: "City", key: "city", width: 20 },
      { header: "Country", key: "country", width: 20 },
    ];

    // Map rowsToExport to match the worksheet columns
    const worksheetData = rowsToExport.map((customer) => ({
      //  id: customer.id,
      name: customer.name,
      phoneNumber: customer.phoneNumber,
      email: customer.email,
      dateOfBirth: customer.dateOfBirth,
      username: customer.username,
      password: customer.password,
      // customerCategoryId: customer.customerCategoryId,
      loyaltyCard: customer.loyaltyCard,
      discount: customer.discount,
      loyaltyPoints: customer.loyaltyPoints,
      idNumber: customer.idNumber,
      idType: customer.idType,
      address: customer.address,
      city: customer.city,
      country: customer.country,
      categoryName: customer.categoryName,
    }));

    // Add rows to worksheet
    worksheet.addRows(worksheetData);

    // Generate Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(
      new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "customers.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target?.result as ArrayBuffer);
          const workbook = new ExcelJS.Workbook();
          await workbook.xlsx.load(data);

          // Get the first worksheet
          const worksheet = workbook.worksheets[0];
          const customers: Customer[] = [];

          // Extract data from worksheet
          worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
            if (rowNumber > 1) {
              // Skip header row
              const id = row.getCell(19).text; // Assuming the nineteenth column is id

              const name = row.getCell(1).text; // Name
              const phoneNumber = row.getCell(2).text; // Phone Number
              const email = row.getCell(3).text; // Email Address
              const dateOfBirth = row.getCell(4).text; // Date of Birth (string)
              const username = row.getCell(5).text; // Username
              const passwordText = row.getCell(6).text; // Password (can be empty or null)
              const password = passwordText.length > 0 ? passwordText : ""; // Convert null to empty string
              const customerCategoryName = row.getCell(7).text.trim(); // Category Name
              const loyaltyCard = row.getCell(8).text; // Loyalty Card
              const discount = parseFloat(row.getCell(9).text) || 0; // Discount
              const loyaltyPoints = parseFloat(row.getCell(10).text) || 0; // Loyalty Points
              const idNumber = row.getCell(11).text; // ID Number
              const idType = row.getCell(12).text; // ID Type (string)

              // Convert idType to a number, use 0 or another default value if empty

              const address = row.getCell(13).text; // Address
              const city = row.getCell(14).text; // City
              const country = row.getCell(15).text; // Country

              // Create customer object
              const customer: Customer = {
                name,
                phoneNumber,
                email,
                dateOfBirth, // Convert to Date
                username,
                password,
                categoryName: customerCategoryName, // Set categoryName instead of customerCategoryId
                loyaltyCard,
                discount,
                loyaltyPoints,
                idNumber,
                idType,
                address,
                city,
                country,
                id,
                customerCategoryId: 0,
                createdBy: 0,
                updatedBy: 0,
                createdByName: "",
                updatedByName: ""
              };

              customers.push(customer);
            }
          });

          // Call importCustomers with the parsed customer data
          importCustomers(customers);
        };
        reader.readAsArrayBuffer(file);
      } catch (error) {
        console.error("Error reading Excel file:", error);
      }
    }
  };

  const createLookupMap = (
    categories: { name: string; id: number }[]
  ): Record<string, number> => {
    const map: Record<string, number> = {};
    categories.forEach((category) => {
      // Ensure names are trimmed and normalized
      const categoryName = category.name.trim().toLowerCase();
      if (categoryName) {
        map[categoryName] = category.id;
      }
    });
    return map;
  };

  const importCustomers = async (customers: Customer[]) => {
    try {
      // Fetch categories from local storage
      const categories = fetchCategoriesFromLocalStorage();

      // Create a lookup map for categories
      const categoryMap = createLookupMap(categories);
      console.log("Category Map:", categoryMap);

      // Create a set of unique category names from the customers
      const uniqueCategoryNames = new Set(
        customers.map(
          (customer) => customer.categoryName?.trim().toLowerCase() || ""
        )
      );

      // Initialize an array to store newly created categories
      const newCategories: { [key: string]: number } = {};

      // Create categories and update local storage
      for (const categoryName of uniqueCategoryNames) {
        if (!categoryMap[categoryName]) {
          try {
            // Create a new category via API if it doesn't exist
            const newCategoryDto = { name: categoryName }; // Customize as needed
            const response = await axios.post(
              `${API_URLS.BASE_URL}${API_URLS.CUSTOMER_CATEGORY}`,
              newCategoryDto
            );
            const newCategory = response.data;

            // Add the new category to local storage and update the lookup map
            categoryMap[categoryName] = newCategory.id;
            newCategories[categoryName] = newCategory.id;
            categories.push(newCategory);
          } catch (error) {
            console.error(`Error creating category '${categoryName}':`, error);
            // Optionally handle error or continue
          }
        }
      }

      // Update local storage with the newly added categories
      localStorage.setItem("customerCatData", JSON.stringify(categories));

      // Format each customer using CreateCustomerDto
      const formattedCustomers = customers.map((customer) => {
        // Ensure phone numbers and other fields are treated as strings
        customer.phoneNumber = customer.phoneNumber
          ? String(customer.phoneNumber)
          : "";
        customer.password = customer.password ? String(customer.password) : "";

        // Map category names to their IDs
        const categoryName = customer.categoryName?.trim().toLowerCase() || "";
        const categoryId = categoryMap[categoryName] ?? 0;

        return new CreateCustomerDto({
          ...customer,
          customerCategoryId: categoryId,
          dateOfBirth: customer.dateOfBirth
            ? new Date(customer.dateOfBirth).toISOString()
            : new Date().toISOString(),
          idType: customer.idType ? String(customer.idType) : "",
        });
      });

      // Initialize arrays to track successful and failed responses
      const successfulResponses = [];
      const failedResponses = [];

      // Upload customers to the API and retrieve the responses
      for (const customer of formattedCustomers) {
        try {
          const response = await axios.post(
            `${API_URLS.BASE_URL}${API_URLS.CUSTOMER}`,
            customer
          );
          successfulResponses.push({
            id: response.data.id,
            name: response.data.name,
            status: "Success",
          });
        } catch (error) {
          let formattedErrors = [];
          if (axios.isAxiosError(error)) {
            if (error.response) {
              const statusCode = error.response.status;

              // Handle 400 errors (validation errors)
              if (statusCode === 400 && Array.isArray(error.response.data)) {
                formattedErrors = error.response.data.map((err: any) => ({
                  propertyName: err.propertyName,
                  errorMessage: err.errorMessage,
                  attemptedValue: err.attemptedValue,
                }));
              }
              // Handle 500 errors (Internal Server Error) and others
              else if (statusCode === 500 || statusCode >= 500) {
                formattedErrors.push({
                  errorMessage: t(
                    "Internal Server Error. Please try again later."
                  ),
                });
              } else {
                // Handle other potential errors
                formattedErrors.push({
                  errorMessage: `An error occurred: ${statusCode}. Please try again.`,
                });
              }
            } else {
              // Handle cases where there is no response, possibly due to network issues
              formattedErrors.push({
                errorMessage:
                  "Network error. Please check your connection and try again.",
              });
            }
          } else {
            // Handle any unexpected errors
            formattedErrors.push({
              errorMessage: "An unknown error occurred. Please try again.",
            });
          }

          failedResponses.push({
            name: customer.name,
            status: "Failed",
            errors: formattedErrors,
          });

          console.error(`Error importing customer '${customer.name}':`, error);
        }
      }

      // Log responses to ensure the API calls are successful

      // Retrieve existing customers from local storage
      const existingCustomers = JSON.parse(
        localStorage.getItem("customerData") || "[]"
      );
      const lastResult = [...successfulResponses, ...failedResponses];
      localStorage.setItem("importCustomer", JSON.stringify(lastResult));

      // Add the newly created customers from the API responses to the existing list
      const updatedCustomers = [...existingCustomers, ...successfulResponses];

      // Save the updated customer list back to local storage
      localStorage.setItem("customerData", JSON.stringify(updatedCustomers));

      // Show success message
      toast.success(t("Customers imported successfully"));

      navigate(t("/importcustomers"));
    } catch (error) {
      // Handle errors that occur outside of the main loop, e.g., network or critical errors
      if (axios.isAxiosError(error) && error.response) {
        const { data } = error.response;
        if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorCustomer.${err.errorMessage}`) ||
              t("errorCustomer.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          const defaultMessage = t("An error occurred");
          toast.error(defaultMessage);
        }
      } else {
        const unexpectedMessage = t("An unexpected error occurred");
        toast.error(unexpectedMessage);
      }
      console.error("Error importing customers:", error);
    }
  };

  useEffect(() => {
    if (!loading && location.state?.showToast) {
      toast.success(location.state.message);
    }
  }, [loading, location.state]);
  const fetchCategoriesFromLocalStorage = (): CustomerCategoryType[] => {
    // Retrieve the category data from local storage
    const categories = JSON.parse(
      localStorage.getItem("customerCatData") || "[]"
    );

    // Return the categories array
    return categories.data; // Assume categories is an array of objects with { id, name }
  };

  const columns: ColumnDef<Customer>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "id",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("ID")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("id")}</div>,
    },
    {
      accessorKey: "name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("name")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("name")}</div>,
    },
    {
      accessorKey: "username",
      header: t("username"),
      cell: ({ row }) => <div>{row.getValue("username")}</div>,
    },
    {
      accessorKey: "email",
      header: "Email",
      cell: ({ row }) => (
        <div className="lowercase">{row.getValue("email")}</div>
      ),
    },
    {
      accessorKey: "phoneNumber",
      header: t("telnumber"),
      cell: ({ row }) => <div>{row.getValue("phoneNumber")}</div>,
    },
    {
      accessorKey: "categoryName",
      header: t("category"),
      cell: ({ row }) => <div>{row.getValue("categoryName")}</div>,
    },
    {
      accessorKey: "loyaltyPoints",
      header: t("loyaltypoints"),
      cell: ({ row }) => <div>{row.getValue("loyaltyPoints")}</div>,
    },
    {
      accessorKey: "idNumber",
      header: t("id"),
      cell: ({ row }) => <div>{row.getValue("idNumber")}</div>,
    },
    {
      accessorKey: "createdByName",
      header: t("createdBy"),
      cell: ({ row }) => <div>{row.getValue("createdByName")}</div>,
    },
    {
      accessorKey: "updatedByName",
      header: t("updatedBy"),
      cell: ({ row }) => <div>{row.getValue("updatedByName")}</div>,
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>{t("action")}</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => handleEdit(row.original.id)}>
              {t("edit")}
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => handleDelete(row.original.id)}>
              {t("delete")}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      globalFilter,
    },
    globalFilterFn: (row, filterValue) => {
      const searchValue = filterValue.toLowerCase();
      return (
        row.original.name.toLowerCase().includes(searchValue) ||
        row.original.idNumber.toLowerCase().includes(searchValue)
      );
    },
  });

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="flex flex-wrap items-center py-4">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
              <Button variant="outline" className="mr-10 flex items-center">
  <Square3Stack3DIcon className="h-5 w-5" />
  <span className="hidden sm:flex sm:items-center">
    {t("action")} <ChevronDown className="ml-2 h-4 w-4" />
  </span>
</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={() => document.getElementById("file-input")?.click()}
            >
              {t("Import")}
            </DropdownMenuItem>{" "}
            <DropdownMenuItem onClick={exportToExcel}>
              {t("export")}
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleDeleteSelected}>
              {t("delete")}
            </DropdownMenuItem>{" "}
          </DropdownMenuContent>
        </DropdownMenu>
        <Input
          placeholder={t("filternameandID")}
          value={globalFilter}
          onChange={(event) => setGlobalFilter(event.target.value)}
          style={{
             width: window.innerWidth <= 999 ? "45%" : "100%" ,
          }}
          className="max-w-sm"
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
             <Button variant="outline" className="ml-auto flex items-center justify-center">
  <span className="hidden md:block ">{t("columns")}</span>
  <Filter className="md:ml-2 h-4 w-4 mx-auto" />
</Button>

          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => (
                <DropdownMenuCheckboxItem
                  key={column.id}
                  className="capitalize"
                  checked={column.getIsVisible()}
                  onCheckedChange={(value) => column.toggleVisibility(!!value)}
                >
                  {column.id}
                </DropdownMenuCheckboxItem>
              ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onDoubleClick={() => handleEdit(row.original.id)}
                  style={{ cursor: "pointer" }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {t("noresult")}{" "}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{" "}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {t("previous")}
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {t("next")}
          </Button>
        </div>
      </div>
      <div className="fixed bottom-4 right-4 md:bottom-4 md:right-4">
        <Button
          type="button"
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={handleADD}
        >
          +
        </Button>
      </div>
      <ToastContainer />
      <Input
        id="file-input"
        type="file"
        accept=".xlsx"
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
    </div>
  );
};

export default CustomerList;
