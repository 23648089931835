import React from 'react';

type QuantityInputProps = {
  quantity: number;
  onIncrement: () => void;
  onDecrement: () => void;
  onChange: (value: number) => void;
  isDarkMode: boolean; // Add this prop to determine dark mode
};

const QuantityInput: React.FC<QuantityInputProps> = ({ quantity, onIncrement, onDecrement, onChange, isDarkMode }) => {
  // Define inline styles with correct TypeScript types
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
  };

  const buttonStyle: React.CSSProperties = {
    backgroundColor: isDarkMode ? '#333' : '#f0f0f0', // Dark background for buttons in dark mode
    color: 'black', // Text color should be black
    border: '1px solid #ccc',
    padding: '5px 10px',
    cursor: 'pointer',
    borderRadius: '4px',
  };

  const inputStyle: React.CSSProperties = {
    width: '50px',
    textAlign: 'center' as const, // Ensure TypeScript compatibility
    border: '1px solid #ccc',
    padding: '5px',
    color: 'black', // Text color should be black
    borderRadius: '4px',
  };

  return (
    <div style={containerStyle}>
      <button type="button" style={buttonStyle} onClick={onDecrement}>-</button>
      <input
        type="number"
        value={quantity}
        onChange={(e) => onChange(Number(e.target.value))}
        style={inputStyle}
      />
      <button type="button" style={buttonStyle} onClick={onIncrement}>+</button>
    </div>
  );
};

export default QuantityInput;
