import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import "@/css/MobileScreen.css";
import CreateShopDto from "@/models/CreateShopDto";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { Shop } from "@/types/ShopType";
import { ShopFormProps } from "@/interfaces/ShopInterfaces";
import "@/css/LoadingCreate.css";



const ShopForm: React.FC<ShopFormProps> = ({
  mode,
  inDialog = false,
  onClose,
}) => {
  const { t } = useTranslation();

  const formSchema = z.object({
    name: z.string().min(1, { message: t("Name is required") }),
    address: z.string().optional(),
  });
  
  type FormValues = z.infer<typeof formSchema>;
  const [loading, setLoading] = useState(false); // Initialize loading state




  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || { id: localStorage.getItem("shopId") };
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      address: "",
    },
  });


  useEffect(() => {
    if (id) {
      localStorage.setItem("shopId", id);
    }

    if (mode === "edit" && id) {
      const fetchShop = async () => {
        try {
          const response = await axios.get(
            `${API_URLS.BASE_URL}${API_URLS.SHOP}/${id}`
          );
          methods.reset(response.data);
        } catch (error) {
          console.error("Error fetching Shop:", error);
        }
      };

      fetchShop();
    }
  }, [id, mode, methods]);

  const updateLocalStorage = (updatedShop: Shop) => {
    const existingShops = JSON.parse(localStorage.getItem("shopData") || "[]");
    const updatedShops = existingShops.map((shop: Shop) =>
      shop.id === updatedShop.id ? updatedShop : shop
    );
    localStorage.setItem("shopData", JSON.stringify(updatedShops));
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true); // Show loading spinner

    const shopData = new CreateShopDto({
      name: data.name,
      address: data.address,
    });
  
    try {
      let successMessage = "";
  
      if (mode === "create") {
        const response = await axios.post(
          `${API_URLS.BASE_URL}${API_URLS.SHOP}`,
          shopData
        );
        successMessage = t("Shop created successfully");
  
        const existingShops = JSON.parse(
          localStorage.getItem("shopData") || "[]"
        );
        existingShops.push(response.data);
        localStorage.setItem("shopData", JSON.stringify(existingShops));
  
        if (inDialog && onClose) {
          onClose();
        }
      } else if (mode === "edit" && id) {
        const response = await axios.put(
          `${API_URLS.BASE_URL}${API_URLS.SHOP}/${id}`,
          shopData
        );
        successMessage = t("Shop updated successfully");
  
        updateLocalStorage(response.data);
        
        if (inDialog && onClose) {
          onClose();
        }
      }
  
      methods.reset();
      localStorage.removeItem("shopId");
  
      if (!inDialog) {
        navigate(t("/shoplistpath"));
      }
  
      // Show toast after navigation
      setTimeout(() => {
        if (successMessage) {
          toast.success(successMessage);
        }
      }, 250);
  
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
  
        if (status === 403) {
          toast.error(t("You do not have permission to perform this action."));
        } else if (status === 500) {
          toast.error(t("A server error occurred. Please try again later."));
        } else if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorShop.${err.errorMessage}`) ||
              t("errorShop.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          toast.error(t("An error occurred"));
        }
      } else {
        toast.error(t("An unexpected error occurred"));
      }
      console.error("Error submitting Shop:", error);
    } 
    finally {
      setLoading(false); // Hide loading spinner
    }
  };
  
  

  const handleDialogSubmit = () => {
    methods.handleSubmit(onSubmit)();
  };

  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center">
      {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        <div className="w-full max-w-3xl p-6 rounded shadow-md">
          <div style={{ padding: "20px" }}>
            <h1>{mode === "create" ? t("createshop") : t("editshop")}</h1>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleDialogSubmit();
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                }}
              >
                <FormItem>
                  <FormLabel>{t("name")}<span style={{ color: "red" }}>*</span></FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("name")}
                      type="search"
                      placeholder={t("putname")}
                    />
                  </FormControl>
                  {methods.formState.errors.name && (
                    <FormMessage>
                      {methods.formState.errors.name.message}
                    </FormMessage>
                  )}
                </FormItem>
                <FormItem>
                  <FormLabel>{t("address")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("address")}
                      type="search"
                      placeholder={t("putaddress")}
                    />
                  </FormControl>
                  {methods.formState.errors.address && (
                    <FormMessage>
                      {methods.formState.errors.address.message}
                    </FormMessage>
                  )}
                </FormItem>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "50px",
                }}
              >
                <Button
                  type="button"
                  onClick={handleDialogSubmit}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  {t("savelbtn")}
                </Button>
                <Button
                  type="button"
                  onClick={() =>
                    inDialog ? onClose?.() : navigate(t("/shoplistpath"))
                  }
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                  {t("canclelbtn")}
                </Button>
              </div>
            </form>
          </div>
          <ToastContainer />
        </div>
      </div>
    </FormProvider>
  );
};

export default ShopForm;
