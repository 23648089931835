import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import TableBarIcon from "@mui/icons-material/TableBar";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateTableDto from "@/models/CreateTableDto";
import DataFetcher from "@/provider/DataFetcher";
import API_URLS from "@/config";
import axios from "axios";
import "@/css/LoadingCreate.css";
import "@/css/loading.css";

type Table = {
  id: number;
  name: string;
  totalAmount: number; // Total amount of orders associated with this table
};

type Order = {
  tableId: number;
  totalAmount: number;
};
  interface BusinessConfigs {
  allowNegativeInventory: boolean;
  baseCurrencyId: number;
  secondCurrencyId: number; // Added second currency ID
  printerType: number;
}

// Define TypeScript interface for a Currency
interface Currency {
  id: number;
  name: string;
    symbol:string;

}

 const TABLE_STORAGE_KEY = "tableData";
   const Currency_STORAGE_KEY = "currencyData";

const PosBar: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [tables, setTables] = useState<Table[]>([]);
  const [selectedTable, setSelectedTable] = useState<number | null>(null);
  const [newTableName, setNewTableName] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editTableId, setEditTableId] = useState<number | null>(null);
  const [showOptions, setShowOptions] = useState<boolean>(true); // State to toggle options
  const [loading, setLoading] = useState(false); // Initialize loading state
  const [loading1, setLoading1] = useState(false); // Initialize loading state
  const [, setCurrencies] = useState<Currency[]>([]); // Holds list of currencies
const [, setBaseCurrencyName] = useState("Lekë"); // Default currency
const [baseCurrencySymbol, setBaseCurrencySymbol] = useState("L"); // Default currency symbol
  const fetchTablesAndOrders = async () => {
    setLoading1(true); // Show loading spinner

    try {
      const tables = await DataFetcher.getData<Table[]>(
        `${API_URLS.BASE_URL}${API_URLS.Tables}`,
        TABLE_STORAGE_KEY,
        true
      );
      const ordersResponse = await axios.get<Order[]>(
          `${API_URLS.BASE_URL}${API_URLS.SALESORDER}?pageNumber=1&take=50`,
      );

      if (tables && ordersResponse.data) {
        const orders = ordersResponse.data;
        const fetchedTables: Table[] = tables.map((table: Table) => ({
          ...table,
          totalAmount: orders
            .filter((order: Order) => order.tableId === table.id)
            .reduce(
              (total: number, order: Order) => total + order.totalAmount,
              0
            ),
        }));

        setTables(fetchedTables);
      }
    } catch (error) {
      console.error("Error fetching tables and orders:", error);
      toast.error(t("Error fetching tables and orders"));
    }
    finally {
      setLoading1(false); // Hide loading spinner
    }
  };

  
  useEffect(() => {
    fetchTablesAndOrders();
  }, []);

  const handleTableClick = (tableId: number) => {
    setSelectedTable(tableId);
    localStorage.setItem("selectedTableId", tableId.toString());
    navigate(t("/salesorder2path"), { state: { tableId } });
  };

  const SHOW_OPTIONS_KEY = 'showOptions';


useEffect(() => {
const cachedShowOptions = localStorage.getItem(SHOW_OPTIONS_KEY);

  if (cachedShowOptions !== null) {
    setShowOptions(cachedShowOptions === 'true');
  }

}, []);
useEffect(() => {
  // Fetch available currencies using DataFetcher with caching
  const fetchCurrencyData = async () => {
    try {
      const response = await DataFetcher.getData<Currency[]>(
        `${API_URLS.BASE_URL}${API_URLS.Currency}`,  // API endpoint
        Currency_STORAGE_KEY,                       // LocalStorage key
      );
      setCurrencies(response || []); // Set fetched currencies, default to an empty array if none

      // Find and set base currency name and symbol
      const businessConfigs: BusinessConfigs = JSON.parse(localStorage.getItem("businessConfigs") || "{}");
      const baseCurrencyId = businessConfigs.baseCurrencyId ?? 1; // Default to 1 if not set
      const baseCurrency = response?.find(currency => currency.id === baseCurrencyId);

      setBaseCurrencyName(baseCurrency?.name || "Leke"); // Set base currency name, default to "Leke"
      setBaseCurrencySymbol(baseCurrency?.symbol || "L"); // Set base currency symbol, default to "L"
    } catch (error) {
      console.error("Error fetching currencies:", error);
      toast.error(t("Failed to load currencies"));
    }
  };

  fetchCurrencyData();
}, [t]);


if (loading1) {
  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  );
}



const handleAddTable = async () => {
  setLoading(true); // Show loading spinner

  if (newTableName.trim() === "") return;

  const tableData: CreateTableDto = { name: newTableName };

  try {
    const response = await axios.post(
      `${API_URLS.BASE_URL}${API_URLS.Tables}`,
      tableData
    );
    console.log("Table created successfully", response.data);
    const createTableSuccess = t("Table created successfully");
    toast.success(createTableSuccess);

    // Retrieve existing tables from local storage, ensuring it's always an array
    const existingTables = JSON.parse(
      localStorage.getItem(TABLE_STORAGE_KEY) || "[]"
    );

    // Ensure the structure is an array even if the data property exists
    const tablesArray = Array.isArray(existingTables) ? existingTables : (existingTables.data || []);

    // Add the new table to the existing list
    tablesArray.push(response.data);

    // Save the updated list back to local storage
    localStorage.setItem(TABLE_STORAGE_KEY, JSON.stringify(tablesArray));

    // Update the state with the new list of tables
    setTables(tablesArray);

    // Clear the input field
    setNewTableName("");
}
 catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data } = error.response;

      // Handle different HTTP status codes and response data
      if (status === 403) {
        toast.error(t("You do not have permission to perform this action."));
      } else if (status === 500) {
        toast.error(t("A server error occurred. Please try again later."));
      } else if (data && Array.isArray(data)) {
        data.forEach((err: { errorMessage: string }) => {
          const translatedMessage =
            t(`errorTables.${err.errorMessage}`) ||
            t("errorTables.An error occurred");
          toast.error(translatedMessage);
        });
      } else {
        const defaultMessage = t("An error occurred");
        toast.error(defaultMessage);
      }
    } else {
      toast.error(t("An unexpected error occurred"));
    }
    console.error("Error submitting table:", error);
  }  finally {
    setLoading(false); // Hide loading spinner
  }
};


  const handleDeleteTable = async (id: number) => {
    try {
      await axios.delete(`${API_URLS.BASE_URL}${API_URLS.Tables}/${id}`);
      setTables(tables.filter((table) => table.id !== id));
      localStorage.setItem(
        TABLE_STORAGE_KEY,
        JSON.stringify(tables.filter((table) => table.id !== id))
      );
      toast.success(t("Table deleted successfully"));

      // Clear selected table from localStorage if deleted
      if (selectedTable === id) {
        localStorage.removeItem("selectedTableId");
        setSelectedTable(null);
      }
    } catch (error) {
      console.error("Error deleting table:", error);
      toast.error(t("Error deleting table"));
    }
  };

  const handleEditTable = async () => {
    if (!editTableId || newTableName.trim() === "") return;

    const updatedTable: Partial<Table> = {
      name: newTableName,
    };

    try {
      const response = await axios.put(
        `${API_URLS.BASE_URL}${API_URLS.Tables}/${editTableId}`,
        updatedTable
      );
      const updatedTableFromApi = response.data;

      setTables(
        tables.map((table) =>
          table.id === editTableId ? updatedTableFromApi : table
        )
      );
      localStorage.setItem(
        TABLE_STORAGE_KEY,
        JSON.stringify(
          tables.map((table) =>
            table.id === editTableId ? updatedTableFromApi : table
          )
        )
      );
      toast.success(t("Table updated successfully"));

      setNewTableName("");
      setIsEditing(false);
      setEditTableId(null);
    } catch (error) {
      console.error("Error updating table:", error);
      // Optionally, you can add error handling logic here (e.g., show a notification to the user)
    }
  };

  const handleStartEdit = (table: Table) => {
    setIsEditing(true);
    setEditTableId(table.id);
    setNewTableName(table.name);
  };

  const handleNavigateToAnotherPath = () => {
    navigate(t("/salesorder2path"));
  };
  if (loading1) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
       {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        
<Box 
  sx={{ 
    marginBottom: 2, 
    display: 'flex', // Use flex for both layouts
    flexDirection: { xs: 'column', sm: 'row' }, // Column on mobile, row on larger screens
    gap: { xs: 2, sm: 0 }, // Space between buttons on mobile, no gap on larger screens
    alignItems: { xs: 'flex-start', sm: 'center' }, // Align items differently based on screen size
        justifyContent: { xs: 'center', sm: 'flex-start' }, // Center items on mobile, start on larger screens

  }}
>
  <TextField
      label={t("New Table Name")}
      value={newTableName}
      onChange={(e) => setNewTableName(e.target.value)}
      className="new-table-name-input" // Add your custom class here
      sx={{ marginBottom: { xs: 2, sm: 0 }, marginRight: { sm: 2 }, width: { xs: '100%', sm: 'auto' } }} // Full width on mobile
    />

  {isEditing ? (
    <Button variant="contained" onClick={handleEditTable} sx={{ width: { xs: '100%', sm: 'auto' } }}>
      {t("Save Table")}
    </Button>
  ) : (
    <Button
  variant="contained"
  onClick={handleAddTable}
  sx={{
    width: { xs: '100%', sm: 'auto' },
    backgroundColor: '#1f2d47', // Set background color
    color: 'white', // Set text color (if needed)
    '&:hover': {
      backgroundColor: '#1a202c', // Optional: keep the same color on hover
    },
  }}
>
  {t("Add Table")}
</Button>

  )}

<Button
  variant="contained"
  onClick={handleNavigateToAnotherPath}
  sx={{
    width: { xs: '100%', sm: 'auto' },
    backgroundColor: '#1f2d47', // Set background color
    color: 'white', // Set text color (if needed)
    '&:hover': {
      backgroundColor: '#1a202c', // Keep the same color on hover (optional)
    },
  }}
>
  {t("Close")}
</Button>

  
<Button
  variant="contained"
  onClick={() => {
    const newShowOptions = !showOptions;
    setShowOptions(newShowOptions);
    localStorage.setItem("showOptions", newShowOptions.toString());
  }}
  sx={{
    width: { xs: '100%', sm: 'auto' }, // Make buttons full width on mobile only
    backgroundColor: '#1f2d47', // Set background color
    color: 'white', // Set text color (if needed)
    '&:hover': {
      backgroundColor: '#1a202c', // Keep the same color on hover (optional)
    },
  }}
>
  {showOptions ? t("Hide Options") : t("Show Options")}
</Button>

</Box>



   <Grid container spacing={2}>
  {tables.map((table) => {
    const storedTotalAmountIncVat = localStorage.getItem(
      `totalAmountIncVat_${table.id}`
    );
    const totalAmountIncVat = storedTotalAmountIncVat
      ? parseFloat(storedTotalAmountIncVat)
      : 0; // Default to 0 if no value is found in local storage

    return (
      <Grid item xs={12} sm={6} md={4} lg={3} key={table.id}>
        <Paper
          onClick={() => handleTableClick(table.id)}
          sx={{
            padding: 2,
            textAlign: "center",
            backgroundColor:
              selectedTable === table.id
                ? "lightgreen"
                : totalAmountIncVat > 0
                ? "red"
                : "green",
            color: "white",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: 100,
            position: "relative",
          }}
        >
          <TableBarIcon style={{ fontSize: 40 }} />
          <Typography variant="h6" sx={{ marginBottom: 0.5 }}>
            {table.name}
          </Typography>
          {showOptions && (
            <>
              <IconButton
                aria-label="edit"
                onClick={(e) => {
                  e.stopPropagation();
                  handleStartEdit(table);
                }}
                sx={{ position: "absolute", top: 0, right: 0 }}
              >
                <EditIcon style={{ color: "white" }} />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteTable(table.id);
                }}
                sx={{ position: "absolute", top: 0, left: 0 }}
              >
                <DeleteIcon style={{ color: "white" }} />
              </IconButton>
            </>
          )}
          {/* Display the total amount */}
         <Typography variant="body2" sx={{ fontSize: '20px' }}>
  {` ${totalAmountIncVat.toFixed(2)}`}{baseCurrencySymbol}
</Typography>

        </Paper>
      </Grid>
    );
  })}
</Grid>


      <ToastContainer />
    </Box>
  );
};

export default PosBar;
