import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { FormControl, FormItem, FormMessage } from "@/components/ui/form";
import "@/css/MobileScreen.css";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { ExchangeRate } from "@/types/ExchangeRateType";
import CreateExchangeRateDto from "@/models/CreateExchangeRateDto";
import { Currency } from "@/interfaces/CurrencyInterface";
import { ExchangeRateFormProps } from "@/interfaces/ExchangeRateInterfaces";
import DataFetcher from "@/provider/DataFetcher";
import "@/css/LoadingCreate.css";

const ExchangeRateForm: React.FC<ExchangeRateFormProps> = ({ mode }) => {
  const { t } = useTranslation();

  const formSchema = z.object({
    currency1Id: z.string().min(1, { message: "Currency 1 is required" }),
    rate: z.preprocess(
      (val) => parseFloat(val as string),
      z
        .number()
        .positive({ message: t("Rate must be a positive number") })
        .refine((val) => !isNaN(val), {
          message: t("Rate is required"),
        })
    ),
  });

  type FormValues = z.infer<typeof formSchema>;
  const [loading, setLoading] = useState(false); // Initialize loading state

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {
    id: localStorage.getItem("exchangerateId"),
  };
  const [currency, setCurrency] = useState<Currency[]>([]);
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      currency1Id: "",
      rate: 0.0,
    },
  });

  const Currency_STORAGE_KEY = "currencyData";

  useEffect(() => {
    const fetchCurrency = async () => {
      try {
        const response = await DataFetcher.getData<Currency[]>(
          `${API_URLS.BASE_URL}${API_URLS.Currency}`,
          Currency_STORAGE_KEY
        );
        if (response) {
          setCurrency(response);
        }
      } catch (error) {
        console.error("Error fetching currency:", error);
      }
    };

    fetchCurrency();

    if (id) {
      localStorage.setItem("exchangerateId", id);
    }

    if (mode === "edit" && id) {
      const fetchExchangeRate = async () => {
        try {
          const response = await axios.get(
            `${API_URLS.BASE_URL}${API_URLS.ExchangeRates}/${id}`
          );
          methods.reset(response.data);
        } catch (error) {
          console.error("Error fetching exchangerate:", error);
        }
      };

      fetchExchangeRate();
    }
  }, [id, mode, methods]);

  const updateLocalStorage = (updatedExchangeRate: ExchangeRate) => {
    const existingExchangeRates = JSON.parse(
      localStorage.getItem("exchangerateData") || "[]"
    );
    const updatedExchangeRates = existingExchangeRates.map(
      (exchangerate: ExchangeRate) =>
        exchangerate.id === updatedExchangeRate.id
          ? updatedExchangeRate
          : exchangerate
    );
    localStorage.setItem(
      "exchangerateData",
      JSON.stringify(updatedExchangeRates)
    );
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true); // Show loading spinner

    const exchangerateData = new CreateExchangeRateDto({
      currency1Id: parseInt(data.currency1Id),
      currency2Id: 1, // Assuming 1 is the ID for "Leke"
      rate: data.rate,
    });

    try {
      let successMessage = "";

      if (mode === "create") {
        const response = await axios.post(
          `${API_URLS.BASE_URL}${API_URLS.ExchangeRates}`,
          exchangerateData
        );
        console.log("Exchange rate created successfully:", response.data);
        successMessage = t("Exchange Rate created successfully");

        const existingExchangeRates = JSON.parse(
          localStorage.getItem("exchangerateData") || "[]"
        );
        existingExchangeRates.push(response.data);
        localStorage.setItem(
          "exchangerateData",
          JSON.stringify(existingExchangeRates)
        );
      } else if (mode === "edit" && id) {
        const response = await axios.put(
          `${API_URLS.BASE_URL}${API_URLS.ExchangeRates}/${id}`,
          exchangerateData
        );
        console.log("Exchange Rate updated successfully:", response.data);
        successMessage = t("Exchange Rate updated successfully");

        updateLocalStorage(response.data);
      }

      methods.reset();
      localStorage.removeItem("exchangerateId");
      navigate(t("/exchangeratelistpath"));

      setTimeout(() => {
        if (successMessage) {
          toast.success(successMessage);
        }
      }, 250);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
  
        if (status === 403) {
          toast.error(t("You do not have permission to perform this action."));
        } else if (status === 500) {
          toast.error(t("A server error occurred. Please try again later."));
        } else if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorExchangeRates.${err.errorMessage}`) ||
              t("errorExchangeRates.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          toast.error(t("An error occurred"));
        }
      } else {
        toast.error(t("An unexpected error occurred"));
      }
      console.error("Error submitting Exchange Rate:", error);
    }
    finally {
      setLoading(false); // Hide loading spinner
    }
  };
  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center">
      {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
      <div className="w-full max-w-3xl p-6 rounded shadow-md">
    <div style={{ padding: "20px" }}>
      <h1>
        {mode === "create"
          ? t("/createexchangerate")
          : t("/editexchangerate")}
      </h1>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-col lg:flex-row lg:items-center lg:gap-4">
          <div className="flex items-center gap-2 mb-4 lg:mb-0">
            <span>1</span>
            <FormItem className="flex-1" >
              <FormControl>
                <Controller
                  name="currency1Id"
                  control={methods.control}
                  render={({ field }) => (
                    <Select
                      onValueChange={field.onChange}
                      value={field.value}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder={t("selectCurrency")} />
                        <span style={{ color: "red" }}>*</span>
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectLabel>{t("currencyName")}</SelectLabel>
                          {currency.map((currency1Id) => (
                            <SelectItem
                              key={currency1Id.id}
                              value={currency1Id.id.toString()}
                            >
                              {currency1Id.name}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  )}
                />
              </FormControl>
              {methods.formState.errors.currency1Id && (
                <FormMessage>
                  {methods.formState.errors.currency1Id.message}
                </FormMessage>
              )}
            </FormItem>
            <span>=</span>
          <FormItem className="flex-1" style={{ minWidth: '70px' }}>
  <FormControl>
    <Input
      {...methods.register("rate", {
        valueAsNumber: true,
      })}
      
      type="number"
      placeholder={t("putRate")}
      step="any"
    />
    
    
  </FormControl>
  {methods.formState.errors.rate && (
    <FormMessage>
      {methods.formState.errors.rate.message}
    </FormMessage>
  )}
</FormItem>

          </div>
          <FormItem className="flex-1">
            <FormControl>
              <Input value="Leke" disabled className="w-full lg:w-auto" />
            </FormControl>
          </FormItem>
        </div>
        <div className="flex flex-col lg:flex-row gap-4 mt-12">
          <Button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded"  >
            {mode === "create" ? t("savelbtn") : t("savelbtn")}
          </Button>
          <Button
            type="button"
            onClick={() => navigate(t("/exchangeratelistpath"))}
            className="bg-gray-500 text-white px-4 py-2 rounded"
          >
            {t("canclelbtn")}
          </Button>
        </div>
      </form>
    </div>
    <ToastContainer />
  </div>
      </div>
    </FormProvider>
  );
};

export default ExchangeRateForm;
