class CreateCurrencyDto {
  name: string;
  symbol: string;

  constructor(currency: { name?: string; symbol?: string }) {
    this.name = currency.name || "";
    this.symbol = currency.symbol || "";
  }
}

export default CreateCurrencyDto;
