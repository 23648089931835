import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Input } from "@/components/ui/input";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import "@/css/salesorder2.css";
import "@/css/MobileScreen.css";

import API_URLS from "@/config";
import { CircleCheckBig, CircleDollarSign, Coins, Grid2X2 } from "lucide-react";
// import { ShoppingCart } from "lucide-react"; // Example icons for customer and payment method
import { useNavigate } from "react-router-dom";
import CreateSalesOrderDto2 from "@/models/CreateSalesOrderDto2";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PosBAR from "@/componentsPos/PrintBar";
import { useReactToPrint } from "react-to-print";
import LocalPrint from "@/componentsPos/localprint";
import { useAuth } from "@/provider/authProvider";
import DataFetcher from "@/provider/DataFetcher";
import "@/css/LoadingCreate.css";

type Product = {
  id: string;
  name: string;
  barcode: string;
  price: number;
  uoMId: number;
  vatRateId: number;
  categoryId: string;
  quantity: number;
  vatRate: number;
  isRawMaterial: boolean;
};

type ProductCategory = {
  id?: string;
  name: string;
};

type VatRate = {
  id: number;
  rate: number;
};

type Customer = {
  id: string;
  name: string;
};

type Table = {
  id: number;
  name: string;
};
 interface BusinessConfigs {
  allowNegativeInventory: boolean;
  baseCurrencyId: number;
  secondCurrencyId: number; // Added second currency ID
  printerType: number;
}

// Define TypeScript interface for a Currency
interface Currency {
  id: number;
  name: string;
  symbol:string;
}

const SalesOrder2: React.FC = () => {
  const PRODUCT_STORAGE_KEY = "productData";
  const CATEGORY_STORAGE_KEY = "categoryData";
  const VAT_STORAGE_KEY = "vatData";
  const CUSTOMER_STORAGE_KEY = "customerData";
  const TABLE_STORAGE_KEY = "tableData";
      const Currency_STORAGE_KEY = "currencyData";

  // const CashAccountOwner_STORAGE_KEY = "cashaccountownerData";
  const CashAccount_STORAGE_KEY = "cashaccountData";
  const [productList, setProductList] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [orderProducts, setOrderProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [totalAmountExVat, setTotalAmountExVat] = useState<number>(0);
  const [totalAmountExVat1, setTotalAmountExVat1] = useState<number>(0);
  // const [employeeID, setEmployeeID] = useState<number | null>(null);
  const { employeeId } = useAuth();
const [baseCurrencyName, setBaseCurrencyName] = useState("Lekë"); // Default currency
const [baseCurrencySymbol, setBaseCurrencySymbol] = useState("L"); // Default currency symbol
  const [, setCurrencies] = useState<Currency[]>([]); // Holds list of currencies

  const [vatAmount, setVatAmount] = useState<number>(0);
  const [, setCashAccountBalance] = useState<number>(0);
  const [, setTotalQuantity] = useState(0);

  const [totalAmountIncVat, setTotalAmountIncVat] = useState<number>(0);
  const [totalAmountIncVat1, setTotalAmountIncVat1] = useState<number>(0);

  const [showCategories] = useState<boolean>(true);
  const [, setVatRates] = useState<VatRate[]>([]);
  const [, setCustomers] = useState<Customer[]>([]); // State for customers
  const { t } = useTranslation();
  const navigate = useNavigate(); // Initialize useNavigate
  const [tables, setTables] = useState<Table[]>([]);
  const [loading, setLoading] = useState(false); // Initialize loading state
  const [orderNo, setOrderNo] = useState();

  const [selectedTable, setSelectedTable] = useState<string>("");
  const [localStorageProducts, setLocalStorageProducts] = useState<Product[]>(
    []
  );
  const [, setLocalStorageTotalQuantity] = useState<number>(0);
  const invoiceRef = useRef<HTMLDivElement>(null);
  const invoiceRef1 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchVatRatesAndProducts = async () => {
      // First, fetch VAT rates
      const vatRatesResponse = await DataFetcher.getData<VatRate[]>(
        `${API_URLS.BASE_URL}${API_URLS.VAT}`,
        VAT_STORAGE_KEY
      );
      if (vatRatesResponse) {
        setVatRates(vatRatesResponse);

        // After VAT rates are fetched, fetch products
        const productsResponse = await DataFetcher.getData<Product[]>(
          `${API_URLS.BASE_URL}${API_URLS.PRODUCT}`,
          PRODUCT_STORAGE_KEY
        );
        if (productsResponse) {
          const filteredProducts = productsResponse.filter(
            (product) => !product.isRawMaterial
          );

          if (filteredProducts) {
            setProductList(
              filteredProducts.map((product) => ({
                ...product,
                quantity: 0,
                vatRate:
                  vatRatesResponse.find((rate) => rate.id === product.vatRateId)
                    ?.rate || 0,
              }))
            );
          }
        }
      }
    };

    const fetchCategories = async () => {
      const response = await DataFetcher.getData<ProductCategory[]>(
        `${API_URLS.BASE_URL}${API_URLS.PRODUCT_CATEGORY}`,
        CATEGORY_STORAGE_KEY
      );
      const noCategory = {} as ProductCategory;
      noCategory.id = undefined;
      noCategory.name = t("Pa Kateogori");

      response?.push(noCategory);
      if (response) {
        setCategories(response);
      }
    };

    const fetchCustomers = async () => {
      const response = await DataFetcher.getData<Customer[]>(
        `${API_URLS.BASE_URL}${API_URLS.CUSTOMER}`,
        CUSTOMER_STORAGE_KEY
      );
      if (response) {
        setCustomers(response);
      }
    };

    const fetchTables = async () => {
      try {
        const response = await DataFetcher.getData<Table[]>(
          `${API_URLS.BASE_URL}${API_URLS.Tables}`,
          TABLE_STORAGE_KEY
        );

        // Log and assert the response
        console.log("Fetched tables:", response);

        // Ensure response is an array
        if (Array.isArray(response)) {
          setTables(response);
        } else {
          console.error("Unexpected data format:", response);
          setTables([]); // Fallback to an empty array if the response is not an array
        }
      } catch (error) {
        console.error("Error fetching tables:", error);
        setTables([]); // Set to empty array on error
      }
    };

    const storedTableId = localStorage.getItem("selectedTableId");
    if (storedTableId) {
      setSelectedTable(storedTableId);
    }

    // Call the fetching functions
    fetchVatRatesAndProducts();
    fetchCategories();
    fetchCustomers();
    fetchTables();
  }, []);
  const truncateString = (str: string, num: number): string => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    }
    return str;
  };

  const [containerHeight, setContainerHeight] = useState("150vh");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 900 && width <= 1000) {
        setContainerHeight("1000vh"); // Set height to 300vh for width between 900px and 1000px
      } else {
        setContainerHeight("150vh"); // Default height
      }
    };

    // Set height on initial load
    handleResize();

    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (employeeId !== null) {
      const parsedEmployeeId = parseInt(employeeId, 10);
      if (!isNaN(parsedEmployeeId)) {
        const fetchCashAccountBalance = async () => {
          try {
            const response = await DataFetcher.getData<any>(
              `${API_URLS.BASE_URL}${API_URLS.CashAccount}`,
              CashAccount_STORAGE_KEY,
              true
            );
            console.log(response);
            const cashAccounts = response;

            if (cashAccounts && cashAccounts.length > 0) {
              const cashAccount = cashAccounts.find(
                (account: { owners: { id: number }[] }) =>
                  account.owners.some((owner) => owner.id === parsedEmployeeId)
              );
              console.log(cashAccount);
              if (cashAccount) {
                setCashAccountBalance(cashAccount.balance);
                console.log(cashAccount.balance);
              } else {
                setCashAccountBalance(0);
                console.log("No cash account found for this employee");
              }
            } else {
              setCashAccountBalance(0);
              console.log("No cash accounts found");
            }
          } catch (error) {
            console.error("Error fetching cash account balance:", error);
          }
        };

        fetchCashAccountBalance();
      }
    }
  }, [employeeId]);
  
useEffect(() => {
  // Fetch available currencies using DataFetcher with caching
  const fetchCurrencyData = async () => {
    try {
      const response = await DataFetcher.getData<Currency[]>(
        `${API_URLS.BASE_URL}${API_URLS.Currency}`,  // API endpoint
        Currency_STORAGE_KEY,                       // LocalStorage key
      );
      setCurrencies(response || []); // Set fetched currencies, default to an empty array if none

      // Find and set base currency name and symbol
      const businessConfigs: BusinessConfigs = JSON.parse(localStorage.getItem("businessConfigs") || "{}");
      const baseCurrencyId = businessConfigs.baseCurrencyId ?? 1; // Default to 1 if not set
      const baseCurrency = response?.find(currency => currency.id === baseCurrencyId);

      setBaseCurrencyName(baseCurrency?.name || "Leke"); // Set base currency name, default to "Leke"
      setBaseCurrencySymbol(baseCurrency?.symbol || "L"); // Set base currency symbol, default to "L"
    } catch (error) {
      console.error("Error fetching currencies:", error);
      toast.error(t("Failed to load currencies"));
    }
  };

  fetchCurrencyData();
}, [t]);


  useEffect(() => {
    const storedProducts = localStorage.getItem(`table-${selectedTable}`);
    if (storedProducts && selectedTable !== "") {
      const parsedProducts: Product[] = JSON.parse(storedProducts);
      setLocalStorageProducts(parsedProducts);

      // Calculate total quantity of products in localStorage
      const totalQuantity = parsedProducts.reduce(
        (total, product) => total + product.quantity,
        0
      );
      setLocalStorageTotalQuantity(totalQuantity);
    } else {
      setLocalStorageProducts([]);
      setLocalStorageTotalQuantity(0);
    }
  }, [selectedTable]);

  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
  });

  const handlePrint1 = useReactToPrint({
    content: () => invoiceRef1.current,
  });

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredProducts([]);
    } else {
      const results = productList.filter(
        (product) =>
          product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          product.barcode.includes(searchQuery)
      );
      setFilteredProducts(results);
    }
  }, [searchQuery, productList]);
  useEffect(() => {
    const fetchTables = async () => {
      try {
        const response = await DataFetcher.getData<Table[]>(
          `${API_URLS.BASE_URL}${API_URLS.Tables}`,
          TABLE_STORAGE_KEY
        );
        if (Array.isArray(response)) {
          setTables(response);
        } else {
          console.error("Expected an array but got:", response);
          setTables([]);
        }
      } catch (error) {
        console.error("Error fetching tables:", error);
        setTables([]); // Handle error by setting to an empty array
      }
    };
    fetchTables();
  }, []);

  useEffect(() => {
    calculateTotalAmount1();
  }, [localStorageProducts]);

  const calculateTotalAmount1 = () => {
    let totalExVat1 = 0;
    let vat1 = 0;
    let totalIncVat1 = 0;
    let subtotal1 = 0;

    localStorageProducts.forEach((product) => {
      const priceExVat1 = product.price * product.quantity;
      const vatAmount1 = priceExVat1 * (product.vatRate / 100);
      const subtotalProduct1 = priceExVat1 - vatAmount1; // Calculating subtotal as priceExVat - vatAmount

      totalExVat1 += priceExVat1;
      vat1 += vatAmount1;
      totalIncVat1 = totalExVat1;
      subtotal1 += subtotalProduct1;
    });

    setTotalAmountExVat1(subtotal1);
    setVatAmount(vat1);
    setTotalAmountIncVat1(totalIncVat1);
  };

  useEffect(() => {
    calculateTotalAmount();
  }, [orderProducts]);

  const calculateTotalAmount = () => {
    let totalExVat = 0;
    let vat = 0;
    let totalIncVat = 0;
    let subtotal = 0;

    orderProducts.forEach((product) => {
      const priceExVat = product.price * product.quantity;
      const vatAmount = priceExVat * (product.vatRate / 100);
      const subtotalProduct = priceExVat - vatAmount; // Calculating subtotal as priceExVat - vatAmount

      totalExVat += priceExVat;
      vat += vatAmount;
      totalIncVat = totalExVat;
      subtotal += subtotalProduct;
    });

    setTotalAmountExVat(subtotal);
    setVatAmount(vat);
    setTotalAmountIncVat(totalIncVat);
  };

  const handleCategorySelect = (categoryId: string) => {
    if (selectedCategory === categoryId) {
      setSelectedCategory("");
      setFilteredProducts([]);
    } else {
      setSelectedCategory(categoryId);
      const filteredByCategory = productList.filter(
        (product) =>
          product.categoryId === (categoryId !== undefined ? categoryId : null)
      );
      setFilteredProducts(filteredByCategory);
    }
  };
  useEffect(() => {
    if (searchQuery === "") {
      if (selectedCategory) {
        const filteredByCategory = productList.filter(
          (product) => product.categoryId === selectedCategory
        );
        setFilteredProducts(filteredByCategory);
      }
    }
  }, [searchQuery, selectedCategory, productList]);

  const addToOrder = (productToAdd: Product) => {
    // Retrieve the current list of products from local storage
    // const storedOrder = localStorage.getItem(`table-${selectedTable}`);
    // let storedProducts: Product[] = storedOrder ? JSON.parse(storedOrder) : [];

    // // Check if the product already exists in the stored list
    // const existingStoredProductIndex = storedProducts.findIndex(product => product.id === productToAdd.id);

    // if (existingStoredProductIndex !== -1) {
    //   // Product already exists in the stored order, update the quantity
    //   storedProducts[existingStoredProductIndex].quantity += 1;
    // } else {
    //   // Product does not exist in the stored order, add it with quantity 1
    //   const productWithQuantity = { ...productToAdd, quantity: 1 };
    //   storedProducts.push(productWithQuantity);
    // }

    // Save the updated stored list back to local storage
    // localStorage.setItem(`table-${selectedTable}`, JSON.stringify(storedProducts));

    // Update the state with the new product list
    const existingStateProductIndex = orderProducts.findIndex(
      (product) => product.id === productToAdd.id
    );

    let updatedOrderProducts;
    if (existingStateProductIndex !== -1) {
      // Product already exists in the state, update the quantity
      updatedOrderProducts = orderProducts.map((product) =>
        product.id === productToAdd.id
          ? { ...product, quantity: product.quantity + 1 }
          : product
      );
    } else {
      // Product does not exist in the state, add it with quantity 1
      const productWithQuantity = { ...productToAdd, quantity: 1 };
      updatedOrderProducts = [...orderProducts, productWithQuantity];
    }

    setOrderProducts(updatedOrderProducts);

    // Calculate and update the total quantity of products
    const totalQuantity = updatedOrderProducts.reduce(
      (total, product) => total + product.quantity,
      0
    );
    setTotalQuantity(totalQuantity);
  };

  const handleRowClick = (product: Product) => {
    addToOrder(product);
  };
  //  useEffect(() => {
  //   if (searchQuery === '') {
  //     setSelectedCategory(null);

  //   }
  // }, [searchQuery]);

  //  const storedTableId = localStorage.getItem("selectedTableId");
  //     if (storedTableId) {
  //       setSelectedTable(storedTableId);
  //     }
  const removeFromOrder = (productId: string) => {
    setOrderProducts((prevProducts) =>
      prevProducts
        .map((product) =>
          product.id === productId
            ? { ...product, quantity: product.quantity - 1 }
            : product
        )
        .filter((product) => product.quantity > 0)
    );
  };

  // useEffect to load the total amount including VAT from local storage when the component mounts or selectedTable changes
  //mos te duhet per nje moment te 2
  // useEffect(() => {
  //     const storedTotalAmount = localStorage.getItem(`totalAmountIncVat_${selectedTable}`);
  //     if (storedTotalAmount) {
  //         setTotalAmountIncVat(parseFloat(storedTotalAmount));
  //     }
  // }, [selectedTable]);

  const clearTotalAmountLocalStorage = () => {
    if (selectedTable) {
      localStorage.removeItem(`totalAmountIncVat_${selectedTable}`);
      setTotalAmountIncVat(0); // Clear totalAmountIncVat
      localStorage.removeItem(`table-${selectedTable}`); // Clear the ordered products

      setSelectedTable(""); // Assuming setSelectedTable is your state update function
      localStorage.removeItem("selectedTableId");
      handlePrint1();

      navigate(t("/posbarpath"));
    } else {
      toast.error(t("Total amount is not paid"));
    }
  };

  // const clearTotalAmountLocalStorage2 = () => {
  //   if (selectedTable) {
  //     // toast.success(t("Table is closed"));

  //     handleCheckout1();
  //   } else {
  //     toast.error(t("Total amount is not paid"));
  //   }
  // };

  const editcashbalance = () => {
    navigate(t("/balance"));
  };
  const handleTableSelect = (tableId: string) => {
    setSelectedTable(tableId);
    localStorage.setItem("selectedTableId", tableId);
  };
  const selectedTableName =
    tables.find((table) => table.id.toString() === selectedTable)?.name ||
    "Unknown Table";
  let cashAccountId: number;
  const handleCheckout = async () => {
    setLoading(true); // Show loading spinner

    if (employeeId !== null) {
      const parsedEmployeeId = parseInt(employeeId, 10);
      // Fetch businessConfigs from localStorage and parse it
    const businessConfigs = JSON.parse(localStorage.getItem('businessConfigs') as string);

    // Directly access allowNegativeInventory since it's always present
    const allowNegativeInventory: boolean = businessConfigs.allowNegativeInventory;
const baseCurrencyId:number=businessConfigs.baseCurrencyId;

      const dto = new CreateSalesOrderDto2({
        orderNo: "ORD-" + Date.now(),
        orderDateUtc: new Date().toISOString(),
        customerId: 1,
        currencyId: baseCurrencyId, // Replace with actual currency ID logic
        totalAmount: totalAmountIncVat,
        quantity: orderProducts.reduce(
          (total, { quantity }) => total + quantity,
          0
        ),
        vatAmount: vatAmount,
        discountAmount: 0, // Replace with actual discount logic if any
        sameTaxes: 0, // Replace with actual sameTaxes logic if any
        shippingCharges: 0,
        adjustedCharges: 0,
        employeeId: parsedEmployeeId || 0,
        // shopId: 1,
        tableId: selectedTable ? parseInt(selectedTable) : null,
        warehouseId: 1,
        paymentTermsId: 1, // Replace with actual payment terms ID logic if any
        paymentMethodId: 0, // Replace with actual payment method ID logic if any
        orderType: 1,
        orderStatus: 0,
        notes: "",
        cashAccountId: cashAccountId,
        shiftId: 1,
        allowNegativeInvetory: allowNegativeInventory,
        salesOrderLines: orderProducts.map(({ id, uoMId, quantity }) => ({
          productId: parseInt(id),
          uoMId: uoMId,
          quantity: quantity,
          lineNote: "",
        })),
      });

      try {
        // Create the order
        const response = await axios.post(
          `${API_URLS.BASE_URL}${API_URLS.SALESORDER}`,
          dto
        );
        const orderNo = response.data.orderNo;
        setOrderNo(orderNo);

        console.log("Order created successfully:", response.data);
        await updateCashAccountBalance(
          parsedEmployeeId || 0,
          totalAmountIncVat
        );

        handlePrint();

        // Save the total amount to local storage with the selected table as the key
        if (selectedTable) {
          // Retrieve the existing total from localStorage for the selected table
          const storedTotal = localStorage.getItem(
            `totalAmountIncVat_${selectedTable}`
          );

          // Parse the stored value, defaulting to 0 if it doesn't exist
          const previousTotal = storedTotal ? parseFloat(storedTotal) : 0;

          // Add the new total to the previous total
          const updatedTotal = previousTotal + totalAmountIncVat;

          // Save the updated total back to localStorage
          localStorage.setItem(
            `totalAmountIncVat_${selectedTable}`,
            updatedTotal.toFixed(2)
          );

          // Optionally update your state or variable
          setTotalAmountIncVat1(updatedTotal);
        }

        // Retrieve existing products from local storage
        const existingStoredOrder = localStorage.getItem(
          `table-${selectedTable}`
        );
        const existingProducts: Product[] = existingStoredOrder
          ? JSON.parse(existingStoredOrder)
          : [];

        // Merge existing products with current order products
        const updatedProducts = [...existingProducts, ...orderProducts];

        // Remove duplicates based on product id and combine quantities
        const uniqueProducts = updatedProducts.reduce((acc, product) => {
          const existingProduct = acc.find((p) => p.id === product.id);
          if (existingProduct) {
            existingProduct.quantity += product.quantity;
          } else {
            acc.push({ ...product });
          }
          return acc;
        }, [] as Product[]);

        // Save the updated product list to local storage
        if (selectedTable) {
          localStorage.setItem(
            `table-${selectedTable}`,
            JSON.stringify(uniqueProducts)
          );
        }

        // Clear the table selection and remove item from local storage
        setSelectedTable("");
        localStorage.removeItem("selectedTableId");

        // Navigate to the next path
        navigate(t("/posbarpath"));
      } catch (error) {
        
        if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;

        if (status === 403) {
            toast.error(t("You do not have permission to perform this action."));
        } else if (status === 500) {
            toast.error(t("A server error occurred. Please try again later."));
        } else if (data && Array.isArray(data)) {
            data.forEach((err: { errorMessage: string }) => {
                const translatedMessage =
                    t(`errorSalesOrder.${err.errorMessage}`) ||
                    t("errorSalesOrder.An error occurred");
                toast.error(translatedMessage);
            });
        } else if (data && typeof data.message === 'string') {
            // Check for the specific error message
            if (data.message.includes("Insufficient total inventory")) {
                toast.error(t("Insufficient total inventory")); // Display the specific message
            } else {
                toast.error(t(data.message)); // Display other messages if necessary
            }
        } else {
            toast.error(t("An error occurred"));
        }
    } else {
        toast.error(t("An unexpected error occurred"));
    }
    console.error("Error creating order:", error);
}  finally {
        setLoading(false); // Hide loading spinner
      }
    }
  };

  // const handleCheckout1 = async () => {
  //   setLoading(true); // Show loading spinner

  //   if (employeeId !== null) {
  //     const parsedEmployeeId = parseInt(employeeId, 10);

  //     const dto = new CreateSalesOrderDto2({
  //       orderNo: "ORD-" + Date.now(),
  //       orderDateUtc: new Date().toISOString(),
  //       customerId: 1,
  //       currencyId: 1, // Replace with actual currency ID logic
  //       totalAmount: totalAmountIncVat,
  //       quantity: orderProducts.reduce(
  //         (total, { quantity }) => total + quantity,
  //         0
  //       ),
  //       vatAmount: vatAmount,
  //       discountAmount: 0, // Replace with actual discount logic if any
  //       sameTaxes: 0, // Replace with actual sameTaxes logic if any
  //       shippingCharges: 0,
  //       adjustedCharges: 0,
  //       employeeId: parsedEmployeeId || 0,
  //       // shopId: 1,
  //       tableId: selectedTable ? parseInt(selectedTable) : 0,
  //       warehouseId: 1,
  //       paymentTermsId: 1, // Replace with actual payment terms ID logic if any
  //       paymentMethodId: 0, // Replace with actual payment method ID logic if any
  //       orderType: 1,
  //       orderStatus: 0,
  //       notes: "",
  //       cashAccountId: cashAccountId,
  //       shiftId: 1,
  //       salesOrderLines: orderProducts.map(({ id, uoMId, quantity }) => ({
  //         productId: parseInt(id),
  //         uoMId: uoMId,
  //         quantity: quantity,
  //         lineNote: "",
  //       })),
  //     });

  //     try {
  //       const response = await axios.post(
  //         `${API_URLS.BASE_URL}${API_URLS.SALESORDER}`,
  //         dto
  //       );
  //       const orderNo = response.data.orderNo;
  //       setOrderNo(orderNo);

  //       console.log("Order created successfully:", response.data);
  //       await updateCashAccountBalance(
  //         parsedEmployeeId || 0,
  //         totalAmountIncVat
  //       );

  //       // Notify user of success
  //       toast.success(t("Order created successfully"));
  //       handlePrint();

  //       // Save the total amount to local storage with the selected table as the key
  //       if (selectedTable) {
  //         const updatedTotal = totalAmountIncVat;
  //         localStorage.setItem(
  //           `totalAmountIncVat_${selectedTable}`,
  //           updatedTotal.toFixed(2)
  //         );
  //         setTotalAmountIncVat1(updatedTotal); // Update state or variable holding totalAmountIncVat
  //       }

  //       // Clear the local storage and state
  //       localStorage.removeItem(`totalAmountIncVat_${selectedTable}`);
  //       localStorage.removeItem(`table-${selectedTable}`);
  //       setTotalAmountIncVat(0); // Clear totalAmountIncVat
  //       setSelectedTable(""); // Clear table selection
  //       localStorage.removeItem("selectedTableId");

  //       // Navigate to the next path
  //       navigate(t("/posbarpath"));
  //     } catch (error) {
  //       if (axios.isAxiosError(error) && error.response) {
  //         const { status, data } = error.response;

  //         if (status === 403) {
  //           toast.error(
  //             t("You do not have permission to perform this action.")
  //           );
  //         } else if (status === 500) {
  //           toast.error(t("A server error occurred. Please try again later."));
  //         } else if (data && Array.isArray(data)) {
  //           data.forEach((err: { errorMessage: string }) => {
  //             const translatedMessage =
  //               t(`errorSalesOrder.${err.errorMessage}`) ||
  //               t("errorSalesOrder.An error occurred");
  //             toast.error(translatedMessage);
  //           });
  //         } else {
  //           toast.error(t("An error occurred"));
  //         }
  //       } else {
  //         toast.error(t("An unexpected error occurred"));
  //       }
  //       console.error("Error creating order:", error);
  //     } finally {
  //       setLoading(false); // Hide loading spinner
  //     }
  //   }
  // };

  const updateCashAccountBalance = async (
    employeeId: number,
    salesAmount: number
  ) => {
    try {
      const parsedEmployeeId = parseInt(employeeId.toString(), 10);
      if (isNaN(parsedEmployeeId)) {
        console.error("Invalid employee ID");
        return;
      }
      const response = await DataFetcher.getData<any>(
        `${API_URLS.BASE_URL}${API_URLS.CashAccount}`,
        CashAccount_STORAGE_KEY
      );
      console.log(response);
      const cashAccounts = response;

      if (cashAccounts && cashAccounts.length > 0) {
        const cashAccount = cashAccounts.find(
          (account: { owners: { id: number }[] }) =>
            account.owners.some((owner) => owner.id === parsedEmployeeId)
        );

        console.log(cashAccount);

        if (cashAccount) {
          const cashAccountId = cashAccount.id;
          console.log(cashAccountId);
          const updatedCashAccount = cashAccount;

          const updatedBalance = updatedCashAccount.balance + salesAmount;

          const response = await axios.put(
            `${API_URLS.BASE_URL}${API_URLS.CashAccount}/${cashAccountId}`,
            {
              ...updatedCashAccount,
              balance: updatedBalance,
            }
          );
          console.log(response);

          setCashAccountBalance(updatedBalance);
        } else {
          console.log("No cash account found for this employee");
        }
      } else {
        console.log("No cash accounts found");
      }
    } catch (error) {
      console.error("Error updating cash account balance:", error);
    }
  };
  return (
    <div className="sales-order-container" style={{ height: containerHeight }}>
      {" "}
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <div className="select-container">
        <div className="select-with-icon">
          {/* <ShoppingCart className="icon" onClick={handleCheckout} /> */}

          <h3>
            {t("Order")}: {totalAmountIncVat.toFixed(2)}{baseCurrencySymbol}
          </h3>
        </div>
        <div className="select-icon">
          <div className="select-icon">
            <select
              value={selectedTable}
              onChange={(e) => handleTableSelect(e.target.value)}
            >
              <option value="">{t("Select Table")}</option>
              {tables.map((table) => (
                <option key={table.id} value={table.id}>
                  {table.name}
                </option>
              ))}
            </select>
          </div>

          <div
            className="select-icon"
            onClick={() => navigate(t("/posbarpath"))}
          >
            <Grid2X2 size={50} />
          </div>
        </div>
      </div>
      <div className="categories">
        <Input
          className="search-input"
          placeholder={t("Search products")}
          value={searchQuery}
          type="search"
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className="category-header">
          <h2>{t("category")}</h2>
        </div>
        {showCategories && (
          <div className="category-buttons">
            {categories.map((category) => (
              <Button
                key={category.id !== undefined ? category.id : category.name} // Use name if id is undefined for a unique key
                className={
                  selectedCategory === category.id ||
                  (selectedCategory === undefined && category.id === undefined)
                    ? "active"
                    : ""
                }
                onClick={() => handleCategorySelect(category.id as string)} // Non-null assertion here
                style={{
                  display:
                    (selectedCategory && selectedCategory !== category.id) ||
                    (selectedCategory === undefined &&
                      category.id !== undefined)
                      ? "none"
                      : "block",
                }}
              >
                {category.name}
              </Button>
            ))}
          </div>
        )}
      </div>
      <div className="products">
        <h2>{t("products")}</h2>
        {/* <h3>{t('Total')}: {totalAmountIncVat.toFixed(2)}L</h3> */}
        <div className="product-buttons">
          {filteredProducts.map((product) => (
            <Button key={product.id} onClick={() => handleRowClick(product)}>
              {truncateString(product.name, 15)}
            </Button>
          ))}
        </div>
      </div>
      <div className="order-products ">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t("Product Info")}</TableHead>
              <TableHead>{t("quantity")}</TableHead>
              <TableHead>{t("price")}</TableHead>
              {/* <TableHead>{t("vat")}</TableHead> */}

              <TableHead>{t("total")}</TableHead>
              <TableHead>{t("action")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {orderProducts.map((product) => (
              <TableRow key={product.id}>
  <>
    <TableCell>
      {truncateString(product.name, 15)}
      {/* Truncate to 15 characters */}
    </TableCell>
    <TableCell>{product.quantity}</TableCell>
    <TableCell>{product.price} {baseCurrencyName}</TableCell>
    {/* <TableCell>{product.vatRate}%</TableCell> */}
    <TableCell>{product.price * product.quantity} {baseCurrencyName}</TableCell>
    <TableCell>
      <Button onClick={() => removeFromOrder(product.id)}>
        {t("Remove")}
      </Button>
    </TableCell>
  </>
</TableRow>

            ))}
          </TableBody>
        </Table>
      </div>
      <div className="order-products" style={{ paddingBottom: "100px" }}>
        <h2>{t("Ordered Products")}</h2>

        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t("Product Info")}</TableHead>
              <TableHead>{t("quantity")}</TableHead>
              <TableHead>{t("price")}</TableHead>

              <TableHead>{t("total")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {localStorageProducts.map((product) => (
              <TableRow key={product.id}>
                <TableCell>
                  {truncateString(product.name, 15)}{" "}
                  {/* Truncate to 20 characters */}
                </TableCell>{" "}
                <TableCell>{product.quantity}</TableCell>
                <TableCell>{product.price} {baseCurrencyName}</TableCell>
                <TableCell>{product.price * product.quantity} {baseCurrencyName}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className="summary">
        <div className="amount">
          <h2>
            {t("Total Tabel")}: {totalAmountIncVat1.toFixed(2)}{baseCurrencySymbol}
          </h2>
        </div>
        <div className="icon-container">
          <div className="select-icon2">
            <CircleCheckBig
              color="blue"
              size={50}
              className="icon"
              onClick={handleCheckout}
            />
            <CircleDollarSign
              size={50}
              onClick={clearTotalAmountLocalStorage}
              color="#13178b"
            />
            {/* Adjust the size as needed */}
            {/* <CircleX size={50} onClick={clearTotalAmountLocalStorage2} />{" "} */}
            {/* Adjust the size as needed */}
            <Coins size={50} color="green" onClick={editcashbalance} />{" "}
            {/* Adjust the size as needed */}
          </div>
        </div>
      </div>
      {/* <div className="order-summary">
        <div className="total-amount">
          <h3>
            {t("SUBTOTAL")}: {totalAmountExVat1.toFixed(2)}L
          </h3>

          <h3>
            {t("Total Amount (Incl. VAT)")}: {totalAmountIncVat1.toFixed(2)}L
          </h3>
        </div>
        <div className="cash-account-balance">
          <h3>
            {t("CashAccountBalance")}: {cashAccountBalance.toFixed(2)}L
          </h3>
        </div>
      </div> */}
      <div style={{ display: "none" }}>
        <div className="order-summary">
          <div className="total-amount">
            <h3>
              {t("SUBTOTAL")}: {totalAmountExVat.toFixed(2)}{baseCurrencySymbol}
            </h3>
            {orderProducts.map((product) => (
              <h3 key={product.id}>
                {t("VAT")}: {product.vatRate}% -{" "}
                {(
                  product.price *
                  product.quantity *
                  (product.vatRate / 100)
                ).toFixed(2)}
                {baseCurrencySymbol}
              </h3>
            ))}{" "}
            {localStorageProducts.map((product) => (
              <h3 key={product.id}>
                {t("VAT")}: {product.vatRate}% -{" "}
                {(
                  product.price *
                  product.quantity *
                  (product.vatRate / 100)
                ).toFixed(2)}
                {baseCurrencySymbol}
              </h3>
            ))}
            <h3>
              {t("Total Amount (Incl. VAT)")}: {totalAmountIncVat.toFixed(2)}{baseCurrencySymbol}
            </h3>
          </div>
        </div>

        <PosBAR
          ref={invoiceRef}
          tablename={selectedTableName}
          tableId={selectedTable}
          // employeeID='3'
          employeeId={employeeId || ""}
          orderProducts={orderProducts}
          totalAmountExVat={totalAmountExVat}
          vatAmount={vatAmount}
          totalAmountIncVat={totalAmountIncVat}
          orderNo={orderNo}
        />
        <LocalPrint
          ref={invoiceRef1}
          tablename={selectedTableName}
          tableId={selectedTable}
          employeeId={employeeId || ""}
          // employeeID='3'
          orderProducts={localStorageProducts}
          totalAmountExVat={totalAmountExVat1}
          vatAmount={vatAmount}
          totalAmountIncVat={totalAmountIncVat1}
          orderNo={orderNo}
        />
      </div>
              <ToastContainer />

    </div>
  );
};

export default SalesOrder2;
