import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import API_URLS from "@/config";
import DataFetcher from "@/provider/DataFetcher";

const Currency_STORAGE_KEY = "currencyData";

// Define TypeScript interface for the configuration object
interface BusinessConfigs {
  allowNegativeInventory: boolean;
  baseCurrencyId: number;
  secondCurrencyId: number; // Added second currency ID
  printerType: number;
}

// Define TypeScript interface for a Currency
interface Currency {
  id: number;
  name: string;
}

const CompanyConfig: React.FC = () => {
  const { t } = useTranslation();

  // State to hold configuration settings
  const [allowNegativeInventory, setAllowNegativeInventory] = useState<boolean>(false);
  const [baseCurrencyId, setBaseCurrencyId] = useState<number>(1); // Default base currency
  const [secondCurrencyId, setSecondCurrencyId] = useState<number>(2); // Default second currency
  const [printerType, setPrinterType] = useState<number>(1); // Default printer type
  const [currencies, setCurrencies] = useState<Currency[]>([]); // Holds list of currencies

  useEffect(() => {
    // Fetch available currencies using DataFetcher with caching
    const fetchCurrencyData = async () => {
      try {
        const response = await DataFetcher.getData<Currency[]>(
          `${API_URLS.BASE_URL}${API_URLS.Currency}`,  // API endpoint
          Currency_STORAGE_KEY,                       // LocalStorage key
                                                  // Use caching
        );
        setCurrencies(response || []); // Set fetched currencies, default to an empty array if none
      } catch (error) {
        console.error("Error fetching currencies:", error);
        toast.error(t("Failed to load currencies"));
      }
    };

    fetchCurrencyData();

    // Load saved configurations from localStorage
    const businessConfigs: BusinessConfigs = JSON.parse(localStorage.getItem("businessConfigs") || "{}");

    setAllowNegativeInventory(businessConfigs.allowNegativeInventory ?? false);
    setBaseCurrencyId(businessConfigs.baseCurrencyId ?? 1);
    setSecondCurrencyId(businessConfigs.secondCurrencyId ?? 2); // Load second currency
    setPrinterType(businessConfigs.printerType ?? 1);
  }, [t]);

  // Helper function to map currencyId to currency name
  const getCurrencyName = (currencyId: number): string => {
    const currency = currencies.find((currency) => currency.id === currencyId);
    return currency ? currency.name : t("Unknown Currency");
  };

  // Helper function to map printerType to printer name
  const getPrinterTypeName = (printerId: number): string => {
    switch (printerId) {
      case 1:
        return "A4";
      case 2:
        return "80MM";
      default:
        return t("Unknown Printer Type");
    }
  };

  // Handler for saving settings using API (PUT request) and localStorage
  const handleSave = async () => {
    const configs: BusinessConfigs = {
      allowNegativeInventory,
      baseCurrencyId,
      secondCurrencyId, // Include second currency
      printerType,
    };

    try {
      // Send PUT request to update the configuration via API
      await axios.put(`${API_URLS.BASE_URL}${API_URLS.BusinessConfigs}`, configs); // Replace with your actual API endpoint

      // Save the updated configuration to localStorage
      localStorage.setItem("businessConfigs", JSON.stringify(configs));

      toast.success(t("Business Configuration saved successfully!"));
    } catch (error) {
      console.error("Error saving settings:", error);
      toast.error(t("Failed to save settings"));
    }
  };

  return (
    <div className="company-config-container p-6 rounded shadow-md">
      <h2 className="text-xl font-semibold mb-4">{t("Company Config")}</h2>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        {/* Allow Negative Inventory */}
        <div className="mb-4">
          <label className="block font-medium mb-1">{t("Allow Negative Inventory")}:</label>
          <select
            value={allowNegativeInventory ? "yes" : "no"}
            onChange={(e) => setAllowNegativeInventory(e.target.value === "yes")}
            className="block p-2 border rounded w-full"
          >
            <option value="yes">{t("Yes")}</option>
            <option value="no">{t("No")}</option>
          </select>
        </div>

        {/* Base Currency */}
        <div className="mb-4">
          <label className="block font-medium mb-1">{t("Base Currency")}:</label>
          <select
            value={baseCurrencyId}
            onChange={(e) => setBaseCurrencyId(Number(e.target.value))}
            className="block p-2 border rounded w-full"
          >
            {currencies.map((currency) => (
              <option key={currency.id} value={currency.id}>
                {getCurrencyName(currency.id)}
              </option>
            ))}
          </select>
        </div>

        {/* Second Currency */}
        <div className="mb-4">
          <label className="block font-medium mb-1">{t("Second Currency")}:</label>
          <select
            value={secondCurrencyId}
            onChange={(e) => setSecondCurrencyId(Number(e.target.value))}
            className="block p-2 border rounded w-full"
          >
            {currencies.map((currency) => (
              <option key={currency.id} value={currency.id}>
                {getCurrencyName(currency.id)}
              </option>
            ))}
          </select>
        </div>

        {/* Printer Type */}
        <div className="mb-4">
          <label className="block font-medium mb-1">{t("Printer Type")}:</label>
          <select
            value={printerType}
            onChange={(e) => setPrinterType(Number(e.target.value))}
            className="block p-2 border rounded w-full"
          >
            <option value={1}>{getPrinterTypeName(1)}</option>
            <option value={2}>{getPrinterTypeName(2)}</option>
          </select>
        </div>
      </div>

      <div className="mt-6 flex space-x-2">
        <Button onClick={handleSave}>{t("edit")}</Button>
      </div>

      <ToastContainer />
    </div>
  );
};

export default CompanyConfig;
